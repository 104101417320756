import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import headerBP from "../../assets/images/headerBP3.webp";
import cosmonautscta from "../../assets/images/cosmonauts-cta.webp";
import MKBox from "../../components/MKBox";

function Privacy() {
  const { t } = useTranslation("app");
  return (
    <>
      <span id="header">
        <DefaultNavbar
          routes={[]}
          action={{
            type: "external",
            route: "https://steamcommunity.com/profiles/76561198158138204",
            label: "@OXOMUS",
            color: "info",
          }}
          sticky
        />
      </span>
      <MKBox
        id="video-inner"
        minHeight="65vh"
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          backgroundImage: `url(${headerBP})`,
        }}
      >
        <span className="offuscator">
          <img src={cosmonautscta} alt="cosmonautscta" id="image-se" />
          <Container>
            <Grid id="override" container item xs={12} lg={12} justifyContent="center" mx="auto">
              <h1 className="title">
                Terms and Conditions
                <br />
                for Se Calculator
              </h1>
            </Grid>
          </Container>
        </span>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <div>
            <Grid
              id="main-container"
              container
              item
              xs={12}
              lg={12}
              spacing={2}
              justifyContent="center"
              mx="auto"
            >
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    These Website Standard Terms and Conditions written on this webpage shall manage
                    your use of our website, Se Calculator accessible at www.se-calculator.com.
                  </p>

                  <p>
                    These Terms will be applied fully and affect to your use of this Website. By
                    using this Website, you agreed to accept all terms and conditions written in
                    here. You must not use this Website if you disagree with any of these Website
                    Standard Terms and Conditions.
                  </p>

                  <p>Minors or people below 18 years old are not allowed to use this Website.</p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Governing Law & Jurisdiction
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <section>
                    <p>
                      These Terms will be governed by and interpreted in accordance with the laws of
                      the State of ch, and you submit to the non-exclusive jurisdiction of the state
                      and federal courts located in ch for the resolution of any disputes.
                    </p>
                  </section>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Intellectual Property Rights
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    Other than the content you own, under these Terms, Se Calculator and/or its
                    licensors own all the intellectual property rights and materials contained in
                    this Website.
                  </p>

                  <p>
                    You are granted limited license only for purposes of viewing the material
                    contained on this Website.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Restrictions
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>You are specifically restricted from all of the following:</p>

                  <ul>
                    <li>publishing any Website material in any other media;</li>
                    <li>
                      selling, sublicensing and/or otherwise commercializing any Website material;
                    </li>
                    <li>publicly performing and/or showing any Website material;</li>
                    <li>
                      using this Website in any way that is or may be damaging to this Website;
                    </li>
                    <li>using this Website in any way that impacts user access to this Website;</li>
                    <li>
                      using this Website contrary to applicable laws and regulations, or in any way
                      may cause harm to the Website, or to any person or business entity;
                    </li>
                    <li>
                      engaging in any data mining, data harvesting, data extracting or any other
                      similar activity in relation to this Website;
                    </li>
                    <li>using this Website to engage in any advertising or marketing.</li>
                  </ul>

                  <p>
                    Certain areas of this Website are restricted from being access by you and Se
                    Calculator may further restrict access by you to any areas of this Website, at
                    any time, in absolute discretion. Any user ID and password you may have for this
                    Website are confidential and you must maintain confidentiality as well.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Your Content
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    In these Website Standard Terms and Conditions, &quot;Your Content&quot; shall
                    mean any audio, video text, images or other material you choose to display on
                    this Website. By displaying Your Content, you grant Se Calculator a
                    non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce,
                    adapt, publish, translate and distribute it in any and all media.
                  </p>

                  <p>
                    Your Content must be your own and must not be invading any third-party’s rights.
                    Se Calculator reserves the right to remove any of Your Content from this Website
                    at any time without notice.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Your Privacy
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>Please read Privacy Policy.</p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    No warranties
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    This Website is provided &quot;as is,&quot; with all faults, and Se Calculator
                    express no representations or warranties, of any kind related to this Website or
                    the materials contained on this Website. Also, nothing contained on this Website
                    shall be interpreted as advising you.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Limitation of liability
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    In no event shall Se Calculator, nor any of its officers, directors and
                    employees, shall be held liable for anything arising out of or in any way
                    connected with your use of this Website whether such liability is under
                    contract. Se Calculator, including its officers, directors and employees shall
                    not be held liable for any indirect, consequential or special liability arising
                    out of or in any way related to your use of this Website.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Indemnification
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    You hereby indemnify to the fullest extent Se Calculator from and against any
                    and/or all liabilities, costs, demands, causes of action, damages and expenses
                    arising in any way related to your breach of any of the provisions of these
                    Terms.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Severability
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    If any provision of these Terms is found to be invalid under any applicable law,
                    such provisions shall be deleted without affecting the remaining provisions
                    herein.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Variation of Terms
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    Se Calculator is permitted to revise these Terms at any time as it sees fit, and
                    by using this Website you are expected to review these Terms on a regular basis.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Assignment
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    The Se Calculator is allowed to assign, transfer, and subcontract its rights
                    and/or obligations under these Terms without any notification. However, you are
                    not allowed to assign, transfer, or subcontract any of your rights and/or
                    obligations under these Terms.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Entire Agreement
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    These Terms constitute the entire agreement between Se Calculator and you in
                    relation to your use of this Website, and supersede all prior agreements and
                    understandings.
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Container className="footer-container">
          <MKBox
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            Copyright © 2022 Oxomus. Space Engineers and associated Space Engineers images are
            copyright of KEEN SOFTWARE HOUSE. Se-calculator.com is not affiliated with Space
            Engineers and KEEN SOFTWARE HOUSE.
          </MKBox>
        </Container>
        <Container className="footer-link-wrapper">
          <MKBox
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="center"
            alignItems="center"
          >
            <NavLink className="footer-link" to="/privacy">
              {t("privacy policy")}
            </NavLink>
            <NavLink className="footer-link" to="/conditions">
              {t("terms & conditions")}
            </NavLink>
          </MKBox>
        </Container>
      </Card>
    </>
  );
}

export default Privacy;
