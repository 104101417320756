const en = {
  app: {
    Privacy: "Privacy Policy",
    cookie:
      'We use cookies to improve the user experience and analyze traffic on our website. By clicking "Accept", you consent to the use of cookies on our website as described in our cookie policy.',
    block: "Blocks",
    Size: "Size",
    shipMaxWeight: "Your ship can weigh up to",
    uploadLabel: "Upload or drop a file right here",
    Edit: "Edit",
    Remove: "Remove",
    step1: "Rename the .sbc file, by changing the extension to .zip",
    step2: "Unzip the .zip file with a file archiver.",
    step3: "You will get a new file extension-less.",
    step4: "Delete the .zip archive",
    step5: "Rename the new file extension-less, by adding the extension .sbc",
    step6: "Finally, you can re-upload your file",
    clickHere: "Click here to download your new file",
    compressedFile:
      "Your file is probably compressed. Follow the instructions below to fix it. And try to re-upload the new file.",
    unknownBlocks: "unknown blocks have been found in your blueprint.",
    Calculating: "Calculating...",
    velocity1: "Terminal Velocity calculation is not possible with these parameters.",
    velocity2: "Please try to modify one of them",
    invalid: "You enter invalid parameters. One at least is equal to zero or negative.",
    chute1: "You will need",
    chute2: "parachutes to descend at",
    title: "No more shitty ships",
    subTitleA: "Blueprint Parser & Analyzer",
    parserA1: "To create a blueprint, target the grid and press",
    parserA2: "Ctrl-B.",
    parserA3: "Creating blueprints works both in survival mode and creative mode.",
    bpA1: "Name :",
    bpA2: "Owner :",
    bpA3: "Size :",
    bpA4: "Remove",
    bpA5: "Components",
    bpA6: "Ingots",
    bpA7: "No blueprint uploaded yet",
    bpA8: "The blueprints are saved locally in your",
    bpA9: "%appdata%/SpaceEngineers/Blueprints",
    bpA10: "folder by default",
    bpA11: "Or you can download them from steam",
    bpA12:
      "Valve lets you download your Steam cloud save files via a web browser, too. You can download them by clicking below and signing in with your Steam account. The blueprints are the SBC files.",
    subTitleB: "Thruster Calculator",
    gridSize: "Grid Size:",
    small: "Small",
    large: "Large",
    gridMass: "Ship Mass (Kg):",
    gravity: "Gravity",
    fillCargo: "Fill my cargo containers (with any Ore)",
    cargoMultiplier: "Cargo Space Multiplier",
    cargoMultiplier2: "Cargo multiplier",
    realistic: "Realistic",
    cargoCount: "Cargo Container Count",
    smallCargo: "Small Cargo Container",
    mediumCargo: "Medium Cargo Container",
    largeCargo: "Large Cargo Container",
    shipThrusters: "Ship Thrusters Requirements",
    shipNeeds: "Your ship needs",
    shipNeedsB:
      "of thrust to fight gravity. To maintain this weight, you will need any of the following:",
    shipNeedsC: "with filled containers",
    shipNeedsD: "empty",
    Thruster: "Thruster",
    Count: "Count",
    ThrusterEfficiencies: "Thruster efficiencies",
    Earthlike: "Earthlike",
    Alien: "Alien",
    Mars: "Mars",
    Pertam: "Pertam",
    Triton: "Triton",
    Moon: "Moon",
    Europa: "Europa",
    Titan: "Titan",
    SelectPlanet: "Select Planet or Moon:",
    LargeIonCount: "Large Ion Count:",
    NumberOfThrusters: "Number of thrusters",
    LargeHydrogenCount: "Large Hydrogen Count:",
    LargeAtmosphericCount: "Large Atmospheric Count:",
    LargeFlatAtmosphericCount: "Large Flat Atmospheric Count:",
    SmallIonCount: "Small Ion Count:",
    SmallHydrogenCount: "Small Hydrogen Count:",
    SmallAtmosphericCount: "Small Atmospheric Count:",
    SmallFlatAtmosphericCount: "Small Flat Atmospheric Count:",
    AscentAngle: "Ascent angle:",
    ascentB:
      "is used when you want to use bottom and rear thrusters together. The calculator will reduce thrust to 70.7%.",
    subTitleC: "Parachute Calculator",
    Reminder: "Reminder:",
    reminderText: "Always bring extra parachutes just in case the drop location is a mountain.",
    LargeGrid: "Large Grid",
    SmallGrid: "Small Grid",
    Hint: "Hint:",
    EarthIs: "Earth is",
    MarsIs: "Mars is",
    AlienIs: "Alien is",
    PertamIs: "Pertam is",
    TritonIs: "Triton is",
    Atmospheres: "Atmospheres (atm): ",
    DesiredVelocity: "Desired Terminal Velocity",
    ComputationTimeout: "Computation Timeout (sec):",
    ShowComputation: "Show Computation Process (Slower Computation)",
    Calculate: "Calculate",
    subTitleD: "Jump Drive Calculator",
    MassInKg: "Mass in Kg",
    NumberOfJumpRive: "Number of Jump drive",
    JumpDriveCount: "Jump Drive Count:",
    MaximumJumpDistance: "Maximum Jump distance (Km) :",
    Graph: "Graph :",
    subTitleE: "Thrusters damage range",
    BlockSize: "Block size",
    Dimensions: "Dimensions",
    Volume: "Volume",
    MaximumThrust: "Maximum Thrust",
    Mass: "Mass",
    MaxPowerConsumption: "Max Power Consumption",
    MaxHydrogenConsumption: "Max Hydrogen Consumption",
    ThrustToMass: "Thrust to Mass",
    ThrustToSize: "Thrust to Size",
    ThrustToPower: "Thrust to Power",
    FlameLength: "Flame Length",
    Large: "Large",
    LargeAtmosphericThruster: "Large Atmospheric Thruster",
    LargeFlatAtmosphericThruster: "Large Flat Atmospheric Thruster",
    AtmosphericThruster: "Atmospheric Thruster",
    FlatAtmosphericThruster: "Flat Atmospheric Thruster",
    LargeHydrogenThruster: "Large Hydrogen Thruster",
    HydrogenThruster: "Hydrogen Thruster ",
    LargeIonThruster: "Large Ion Thruster",
    IonThruster: "Ion Thruster",
    Small: "Small",
    AlienCamouflage: "Alien Camouflage",
    Info: "Info",
    ClickToClipboard: "Click to copy to clipboard.",
    Clouds: "Clouds",
    Sky: "Sky",
    Landscapes: "Landscapes",
    Trees: "Trees",
    Bushes: "Bushes",
  },
};

export default en;
