import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import damageSmall from "assets/images/small.gif";
import damageLarge from "assets/images/large.gif";
import blueprintBackground from "assets/images/blueprint.gif";
import iron from "assets/images/iron_ingot.png";
import magnesium from "assets/images/magnesium_ingot.png";
import cobalt from "assets/images/cobalt_ingot.png";
import stone from "assets/images/gravel_ingot.png";
import platinum from "assets/images/platinum_ingot.png";
import nickel from "assets/images/nickel_ingot.png";
import gold from "assets/images/gold_ingot.png";
import uranium from "assets/images/uranium_ingot.png";
import silicon from "assets/images/silicon_ingot.png";
import silver from "assets/images/silver_ingot.png";
import steam from "assets/images/steam.png";
import zonechip from "assets/images/ZoneChip_Item.png";
import thrust1 from "assets/images/thruts_1.png";
import thrust2 from "assets/images/thruts_2.png";
import thrust3 from "assets/images/thruts_3.png";
import thrust4 from "assets/images/thruts_4.png";
import thrust5 from "assets/images/thruts_5.png";
import thrust6 from "assets/images/thruts_6.png";
import thrust7 from "assets/images/thruts_7.webp";
import thrust8 from "assets/images/thruts_8.webp";
import Plushie from "assets/images/Plushie.png";
import SabiroidPlushie from "assets/images/SabiroidePlushie.png";
import headerBP from "assets/images/headerBP4.webp";
import cosmonautscta from "assets/images/cosmonaut.webp";
import { NavLink } from "react-router-dom";
import mk1 from "assets/images/mk1.jpg";
import InfoIcon from "@mui/icons-material/Info";
import FolderOpenIcon from "@mui/icons-material/Folder";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import TvIcon from "@mui/icons-material/Tv";
import YoutubeBackground from "react-youtube-background";
import Stack from "@mui/material/Stack";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { parseString } from "xml2js";
import { FileUploader } from "react-drag-drop-files";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { useTranslation } from "react-i18next";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import ParaglidingIcon from "@mui/icons-material/Paragliding";
import BoltIcon from "@mui/icons-material/Bolt";
import AirIcon from "@mui/icons-material/Air";
import HardwareIcon from "@mui/icons-material/Hardware";
import CircularProgress from "@mui/material/CircularProgress";
import HeavyIndustry from "assets/images/HeavyIndustry.webp";
import SparksOfTheFuture from "assets/images/SparksOfTheFuture.webp";
import Warfare1 from "assets/images/Warfare1.webp";
import DecorativeBlocks from "assets/images/DecorativeBlocks.webp";
import DecorativeBlocks2 from "assets/images/DecorativeBlocks2.webp";
import DecorativeBlocks3 from "assets/images/DecorativeBlocks3.png";
import ScrapRace from "assets/images/ScrapRace.webp";
import Warfare2 from "assets/images/Warfare2.webp";
import Automatons from "assets/images/automaton.webp";
import Frostbite from "assets/images/Frostbite.webp";
import Economy from "assets/images/Economy.webp";
import stepEn from "assets/images/compressed_en.gif";
import stepFr from "assets/images/compressed_fr.gif";
import ErrorIcon from "@mui/icons-material/Error";
import i18n from "i18next";
import FitnessCenterIcon from "@mui/icons-material/Scale";
import PhotoIcon from "@mui/icons-material/Photo";
import SnackbarContent from "@mui/material/SnackbarContent";
// import DecompressZip from "decompress-zip";

import { blocksData, componentsData, planetData } from "../../utils";

const fileTypes = ["sbc"];
const ingotImages = {
  iron,
  magnesium,
  cobalt,
  stone,
  platinum,
  nickel,
  gold,
  uranium,
  silicon,
  silver,
  zonechip,
  bismuth: mk1,
  opale: mk1,
  composantnpct1: mk1,
  composantnpcmk1: mk1,
  pucevote: mk1,
  pucecontrefacon: mk1,
  pucebronze: mk1,
  puceargent: mk1,
  puceor: mk1,
  engineerplushie: Plushie,
  sabiroidplushie: SabiroidPlushie,
};
const stepImages = {
  fr: stepFr,
  en: stepEn,
};
const dlcImages = {
  HeavyIndustry,
  SparksOfTheFuture,
  Warfare1,
  DecorativeBlocks,
  DecorativeBlocks2,
  ScrapRace,
  Warfare2,
  Frostbite,
  Economy,
  DecorativeBlocks3,
  Automatons,
};
// todo add new dlc image

function Presentation(props) {
  // eslint-disable-next-line react/prop-types
  const { app } = props;
  const [isVideoStopped, setIsVideoStopped] = useState(false);
  const [gridMass, setGridMass] = useState(1500000);
  const [gravity1, setGravity] = useState(9.80665);
  const [atm1, setAtm] = useState(1);
  const [terminalVelocity1, setTerminalVelocity] = useState(10);
  const [gridSize, setGridSize] = useState("large");
  const [largeIons, setLargeIons] = useState(0);
  const [largeHydrogen, setLargeHydrogen] = useState(0);
  const [largeAtm, setLargeAtm] = useState(0);
  const [largeFatm, setLargeFatm] = useState(0);
  const [smallIons, setSmallIons] = useState(0);
  const [smallHydrogen, setSmallHydrogen] = useState(0);
  const [smallAtm, setSmallAtm] = useState(0);
  const [smallFatm, setSmallFatm] = useState(0);
  const [jdrive, setJdrive] = useState(0);
  const [maxJump, setMaxJump] = useState(0);
  const [datag, setDatag] = useState([]);
  const [fillCargo, setFillCargo] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [cargoMultiplier, setCargoMultiplier] = useState(1);
  const [ascentAngle, setAscentAngle] = useState("90");
  const [selectedPlanet, setSelectedPlanet] = useState("Earthlike");
  let thrustWeight = gridMass * gravity1;
  const [smallCargoCount, setSmallCargoCount] = useState(0);
  const [mediumCargoCount, setMediumCargoCount] = useState(0);
  const [largeCargoCount, setLargeCargoCount] = useState(0);
  const [blueprint, setBlueprint] = useState(null);
  const [file, setFile] = useState(null);
  const [pending, setPending] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [fileEncrypt, setFileEncrypt] = useState(false);
  const [parachuteError, setParachuteError] = useState(false);
  const [bpUnknownBlocks, setbpUnknownBlocks] = useState(0);
  // const [open, setOpen] = React.useState(true);
  const [isCookie, setIsCookie] = React.useState(false);
  const cargoData = {
    small: {
      small: 125,
      medium: 3375,
      large: 15625,
    },
    large: {
      small: 15625,
      large: 421000,
    },
  };
  const { t } = useTranslation("app");
  const kFormatter = (num) =>
    Math.abs(num) > 999
      ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
      : Math.sign(num) * Math.abs(num);

  const combine = (obj) =>
    Object.keys(obj).reduce((res, cur) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(obj[cur])) {
        // eslint-disable-next-line no-prototype-builtins
        if (res.hasOwnProperty(key)) {
          res[key] += obj[cur][key];
        } else {
          res[key] = obj[cur][key];
        }
      }
      return res;
    }, {});

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCookie("se-calculator", "d190b976-5c66-4549-b401-9ccf6db9c2bd", 300);
    setIsCookie(false);
    // setOpen(false);
  };

  let shipThrust = parseFloat(parseFloat(`${thrustWeight / 1000}`).toFixed(2));

  if (fillCargo) {
    let additionalWeight = 0;

    if (smallCargoCount) {
      additionalWeight += cargoData[gridSize].small * smallCargoCount * 2.7081 * gravity1;
    }

    if (mediumCargoCount) {
      additionalWeight += cargoData[gridSize].medium * mediumCargoCount * 2.7081 * gravity1;
    }

    if (largeCargoCount) {
      additionalWeight += cargoData[gridSize].large * largeCargoCount * 2.7081 * gravity1;
    }

    thrustWeight += additionalWeight;
    shipThrust = parseFloat(parseFloat(`${thrustWeight / 1000}`).toFixed(2));
  }

  const thrusterData = {
    Large: {
      Large: {
        Ion: 4320000,
        Hyd: 7200000,
        Atm: 6480000,
        Fatm: 2600000,
      },
      Small: {
        Ion: 345600,
        Hyd: 1080000,
        Atm: 648000,
        Fatm: 200000,
      },
    },
    Small: {
      Large: {
        Ion: 172800,
        Hyd: 480000,
        Atm: 576000,
        Fatm: 230000,
      },
      Small: {
        Ion: 14400,
        Hyd: 98400,
        Atm: 96000,
        Fatm: 32000,
      },
    },
  };

  function createData(thruster, count, color, image) {
    return { thruster, count, color, image };
  }

  function createDataDamage(thruster, size, count, color, image) {
    return { thruster, size, count, color, image };
  }

  const dataSize = gridSize === "large" ? thrusterData.Large : thrusterData.Small;

  const tryToDecompress = (compressedFile) => {
    console.log("// file //", compressedFile);

    /* const unzipper = new DecompressZip(compressedFile);

    unzipper.on("error", (err) => {
      console.log("Caught an error", err);
    });

    unzipper.on("extract", (log) => {
      console.log("Finished extracting", log);
    });

    unzipper.on("progress", (fileIndex, fileCount) => {
      console.log(`Extracted file ${fileIndex + 1} of ${fileCount}`);
    });

    unzipper.extract({
      path: "some/path",
      filter(decompressFile) {
        return decompressFile.type !== "SymbolicLink";
      },
    }); */
  };
  // const changeExtension = () => {};

  const parseBlueprintSbc = (xml) =>
    new Promise((resolve, reject) => {
      parseString(xml, (parseError, bp) => {
        if (parseError) {
          tryToDecompress(xml);
          setPending(false);
          setFileError(true);
          reject(parseError);
        }

        try {
          // console.log("-- bp --", JSON.stringify(bp));
          // console.log("-- bp --", bp);
          const costArray = [];
          const ingotArray = [];
          const knowBlocks = [];
          const unknowBlocks = [];
          let largeIonsCount = 0;
          let largeHydrogenCount = 0;
          let largeAtmCount = 0;
          let smallIonsCount = 0;
          let smallHydrogenCount = 0;
          let smallAtmCount = 0;
          let bpWeight = 0;
          let jumpCount = 0;
          let largeFatmCount = 0;
          let smallFatmCount = 0;

          console.log("/// test ///", largeFatmCount);
          console.log("/// test ///", smallFatmCount);

          /* const cubeBlocks =
            bp.Definitions.ShipBlueprints[0].ShipBlueprint[0].CubeGrids[0].CubeGrid[0].CubeBlocks[0]
              .MyObjectBuilder_CubeBlock; */

          const cubeBlocks =
            bp.Definitions.ShipBlueprints[0].ShipBlueprint[0].CubeGrids[0].CubeGrid.map(
              (cubeGrid) => cubeGrid.CubeBlocks[0].MyObjectBuilder_CubeBlock
            ).flat();

          cubeBlocks.forEach((block) => {
            if (blocksData[block.SubtypeName[0]]) {
              costArray.push({
                ...blocksData[block.SubtypeName[0]].Cost,
              });

              if (
                block &&
                block.BlockOrientation &&
                block.BlockOrientation[0].$.Forward &&
                block.BlockOrientation[0].$.Forward === "Down"
              ) {
                // console.log("// test blocks //", JSON.stringify(block));
                // todo add new thruster count
                switch (block.SubtypeName[0]) {
                  case "Gpetition":
                    smallIonsCount += 1;
                    break;
                  case "Ggrosion":
                    largeIonsCount += 1;
                    break;
                  case "LargeBlockSmallThrustSciFiop":
                    smallIonsCount += 1;
                    break;
                  case "LargeBlockLargeThrustSciFiop":
                    largeIonsCount += 1;
                    break;
                  case "Ggrosatmo":
                    largeAtmCount += 1;
                    break;
                  case "Gpetitatmo":
                    smallAtmCount += 1;
                    break;
                  case "LargeBlockLargeAtmosphericThrustSciFiop":
                    largeAtmCount += 1;
                    break;
                  case "LargeBlockSmallAtmosphericThrustSciFiop":
                    smallAtmCount += 1;
                    break;
                  case "Spetitgroshydro":
                    largeHydrogenCount += 1;
                    break;
                  case "Ggroshydro":
                    largeHydrogenCount += 1;
                    break;
                  case "LargeBlockLargeHydrogenThrustIndustrialop":
                    largeHydrogenCount += 1;
                    break;
                  case "LargeBlockSmallHydrogenThrustIndustrialop":
                    smallHydrogenCount += 1;
                    break;
                  case "SmallBlockSmallThrustSciFiop":
                    smallAtmCount += 1;
                    break;
                  case "SmallBlockLargeThrustSciFiop":
                    largeAtmCount += 1;
                    break;
                  case "Sgrosatmo":
                    largeAtmCount += 1;
                    break;
                  case "Spetitatmo":
                    smallAtmCount += 1;
                    break;
                  case "SmallBlockLargeAtmosphericThrustSciFiop":
                    largeAtmCount += 1;
                    break;
                  case "SmallBlockSmallAtmosphericThrustSciFiop":
                    smallAtmCount += 1;
                    break;
                  case "SmallBlockLargeHydrogenThrustIndustrialop":
                    largeHydrogenCount += 1;
                    break;
                  case "SmallBlockSmallHydrogenThrustIndustrialop":
                    smallHydrogenCount += 1;
                    break;
                  case "LargeBlockLargeHydrogenThrust":
                    largeHydrogenCount += 1;
                    break;
                  case "LargeBlockSmallHydrogenThrust":
                    smallHydrogenCount += 1;
                    break;
                  case "LargeBlockLargeAtmosphericThrust":
                    largeAtmCount += 1;
                    break;
                  case "LargeBlockSmallAtmosphericThrust":
                    smallAtmCount += 1;
                    break;
                  case "LargeBlockLargeThrust":
                    largeIonsCount += 1;
                    break;
                  case "LargeBlockSmallThrust":
                    smallIonsCount += 1;
                    break;
                  case "LargeBlockLargeAtmosphericThrustSciFi":
                    largeAtmCount += 1;
                    break;
                  case "LargeBlockSmallAtmosphericThrustSciFi":
                    smallAtmCount += 1;
                    break;
                  case "LargeBlockLargeThrustSciFi":
                    largeIonsCount += 1;
                    break;
                  case "LargeBlockSmallThrustSciFi":
                    smallAtmCount += 1;
                    break;
                  case "LargeBlockLargeModularThruster":
                    largeIonsCount += 1;
                    break;
                  case "LargeBlockSmallModularThruster":
                    smallIonsCount += 1;
                    break;
                  case "LargeBlockLargeHydrogenThrustIndustrial":
                    largeHydrogenCount += 1;
                    break;
                  case "LargeBlockSmallHydrogenThrustIndustrial":
                    smallHydrogenCount += 1;
                    break;
                  case "LargeBlockLargeFlatAtmosphericThrust":
                    largeFatmCount += 1;
                    break;
                  case "SmallBlockLargeFlatAtmosphericThrust":
                    largeFatmCount += 1;
                    break;
                  case "LargeBlockSmallFlatAtmosphericThrust":
                    smallFatmCount += 1;
                    break;
                  case "SmallBlockLargeFlatAtmosphericThrustDShape":
                    largeFatmCount += 1;
                    break;
                  case "LargeBlockLargeFlatAtmosphericThrustDShape":
                    largeFatmCount += 1;
                    break;
                  case "SmallBlockSmallFlatAtmosphericThrust":
                    smallFatmCount += 1;
                    break;
                  case "LargeBlockSmallFlatAtmosphericThrustDShape":
                    smallFatmCount += 1;
                    break;
                  case "SmallBlockSmallFlatAtmosphericThrustDShape":
                    smallFatmCount += 1;
                    break;
                  default:
                }
              }

              switch (block.SubtypeName[0]) {
                case "JumpDriveopale":
                  jumpCount += 1;
                  break;
                case "AQD_SG_Jumpdrive":
                  jumpCount += 1;
                  break;
                case "LargeJumpDrive":
                  jumpCount += 1;
                  break;
                default:
              }
            } else if (block.SubtypeName[0]) {
              knowBlocks.push(block.SubtypeName[0]);
              unknowBlocks.push(block);
            } else {
              const searchableName =
                (block.$["xsi:type"] && block.$["xsi:type"].split("MyObjectBuilder_")[1]) || "";
              if (blocksData[searchableName]) {
                costArray.push({
                  ...blocksData[searchableName].Cost,
                });
                switch (searchableName) {
                  case "Gpetition":
                    smallIonsCount += 1;
                    break;
                  case "Ggrosion":
                    largeIonsCount += 1;
                    break;
                  case "LargeBlockSmallThrustSciFiop":
                    smallIonsCount += 1;
                    break;
                  case "LargeBlockLargeThrustSciFiop":
                    largeIonsCount += 1;
                    break;
                  case "Ggrosatmo":
                    largeAtmCount += 1;
                    break;
                  case "Gpetitatmo":
                    smallAtmCount += 1;
                    break;
                  case "LargeBlockLargeAtmosphericThrustSciFiop":
                    largeAtmCount += 1;
                    break;
                  case "LargeBlockSmallAtmosphericThrustSciFiop":
                    smallAtmCount += 1;
                    break;
                  case "Spetitgroshydro":
                    largeHydrogenCount += 1;
                    break;
                  case "Ggroshydro":
                    largeHydrogenCount += 1;
                    break;
                  case "LargeBlockLargeHydrogenThrustIndustrialop":
                    largeHydrogenCount += 1;
                    break;
                  case "LargeBlockSmallHydrogenThrustIndustrialop":
                    smallHydrogenCount += 1;
                    break;
                  case "SmallBlockSmallThrustSciFiop":
                    smallAtmCount += 1;
                    break;
                  case "SmallBlockLargeThrustSciFiop":
                    largeAtmCount += 1;
                    break;
                  case "Sgrosatmo":
                    largeAtmCount += 1;
                    break;
                  case "Spetitatmo":
                    smallAtmCount += 1;
                    break;
                  case "SmallBlockLargeAtmosphericThrustSciFiop":
                    largeAtmCount += 1;
                    break;
                  case "SmallBlockSmallAtmosphericThrustSciFiop":
                    smallAtmCount += 1;
                    break;
                  case "SmallBlockLargeHydrogenThrustIndustrialop":
                    largeHydrogenCount += 1;
                    break;
                  case "SmallBlockSmallHydrogenThrustIndustrialop":
                    smallHydrogenCount += 1;
                    break;
                  case "LargeBlockLargeHydrogenThrust":
                    largeHydrogenCount += 1;
                    break;
                  case "LargeBlockSmallHydrogenThrust":
                    smallHydrogenCount += 1;
                    break;
                  case "LargeBlockLargeAtmosphericThrust":
                    largeAtmCount += 1;
                    break;
                  case "LargeBlockSmallAtmosphericThrust":
                    smallAtmCount += 1;
                    break;
                  case "LargeBlockLargeThrust":
                    largeIonsCount += 1;
                    break;
                  case "LargeBlockSmallThrust":
                    smallIonsCount += 1;
                    break;
                  case "LargeBlockLargeAtmosphericThrustSciFi":
                    largeAtmCount += 1;
                    break;
                  case "LargeBlockSmallAtmosphericThrustSciFi":
                    smallAtmCount += 1;
                    break;
                  case "LargeBlockLargeThrustSciFi":
                    largeIonsCount += 1;
                    break;
                  case "LargeBlockSmallThrustSciFi":
                    smallAtmCount += 1;
                    break;
                  case "LargeBlockLargeModularThruster":
                    largeIonsCount += 1;
                    break;
                  case "LargeBlockSmallModularThruster":
                    smallIonsCount += 1;
                    break;
                  case "LargeBlockLargeHydrogenThrustIndustrial":
                    largeHydrogenCount += 1;
                    break;
                  case "LargeBlockSmallHydrogenThrustIndustrial":
                    smallHydrogenCount += 1;
                    break;
                  case "LargeBlockLargeFlatAtmosphericThrust":
                    largeFatmCount += 1;
                    break;
                  case "SmallBlockLargeFlatAtmosphericThrust":
                    largeFatmCount += 1;
                    break;
                  case "LargeBlockSmallFlatAtmosphericThrust":
                    smallFatmCount += 1;
                    break;
                  case "SmallBlockLargeFlatAtmosphericThrustDShape":
                    largeFatmCount += 1;
                    break;
                  case "LargeBlockLargeFlatAtmosphericThrustDShape":
                    largeFatmCount += 1;
                    break;
                  case "SmallBlockSmallFlatAtmosphericThrust":
                    smallFatmCount += 1;
                    break;
                  case "LargeBlockSmallFlatAtmosphericThrustDShape":
                    smallFatmCount += 1;
                    break;
                  case "SmallBlockSmallFlatAtmosphericThrustDShape":
                    smallFatmCount += 1;
                    break;
                  default:
                }
                switch (searchableName) {
                  case "JumpDriveopale":
                    jumpCount += 1;
                    break;
                  case "AQD_SG_Jumpdrive":
                    jumpCount += 1;
                    break;
                  case "LargeJumpDrive":
                    jumpCount += 1;
                    break;
                  default:
                }
              } else {
                unknowBlocks.push(block);
              }
            }
          });

          // console.log("/// unknowBlocks ///", unknowBlocks);
          console.log("/// unknowBlocks ///", [
            ...new Set(unknowBlocks.map((item) => item.SubtypeName[0])),
          ]);
          // console.log("/// knowBlocks ///", knowBlocks);

          const cost = combine(costArray);
          Object.keys(cost).forEach((key) => {
            ingotArray.push(
              Object.keys(componentsData[key].Cost)
                .map((item) => ({
                  [item]: Math.ceil(
                    parseFloat(componentsData[key].Cost[item]) * parseFloat(cost[key])
                  ),
                }))
                .flat()
            );
          });

          Object.keys(cost).forEach((item) => {
            bpWeight += cost[item] * componentsData[item].Weight;
          });
          const ingots = combine(ingotArray.flat());
          const currentBp = {
            bpName: bp.Definitions.ShipBlueprints[0].ShipBlueprint[0].Id[0].$.Subtype || "",
            ownerName: bp.Definitions.ShipBlueprints[0].ShipBlueprint[0].DisplayName[0] || "",
            bpGridSize:
              bp.Definitions.ShipBlueprints[0].ShipBlueprint[0].CubeGrids[0].CubeGrid[0]
                .GridSizeEnum[0] || "",
            cost,
            ingots,
            dlc: bp.Definitions.ShipBlueprints[0].ShipBlueprint[0].DLC,
            bpWeight: Math.ceil(bpWeight),
          };
          setGridSize(
            bp.Definitions.ShipBlueprints[0].ShipBlueprint[0].CubeGrids[0].CubeGrid[0].GridSizeEnum[0].toLowerCase() ||
              "large"
          );

          // console.log("/// largeIonsCount ///", largeIonsCount);
          // console.log("/// largeHydrogenCount ///", largeHydrogenCount);
          // console.log("/// largeAtmCount ///", largeAtmCount);
          // console.log("/// smallIonsCount ///", smallIonsCount);
          // console.log("/// smallHydrogenCount ///", smallHydrogenCount);
          // console.log("/// smallAtmCount ///", smallAtmCount);
          // console.log("/// jumpmCount ///", jumpCount);

          setbpUnknownBlocks(unknowBlocks.length);
          setGridMass(Math.ceil(bpWeight));
          setLargeIons(largeIonsCount);
          setLargeHydrogen(largeHydrogenCount);
          setLargeAtm(largeAtmCount);
          setSmallIons(smallIonsCount);
          setSmallHydrogen(smallHydrogenCount);
          setSmallAtm(smallAtmCount);
          setJdrive(jumpCount);
          // todo update

          if (unknowBlocks.length) {
            const db = getFirestore(app);
            try {
              addDoc(collection(db, "test"), {
                items: unknowBlocks,
              });
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error("Error adding document: ", e);
            }
          }
          // setPending(false);
          resolve(currentBp);
        } catch (transformError) {
          // console.error(transformError, bp);
          // setPending(false);
          reject(transformError);
        }
      });
    });

  const handleChange = (fileItem) => {
    setPending(true);
    setFile(fileItem);
    // console.log("/// test ///", fileItem);

    const reader = new FileReader();
    reader.onload = async (event) => {
      const bpFile = event.target.result;
      const parser = new DOMParser();
      const doc = parser.parseFromString(bpFile, "application/xml");
      const errorNode = doc.querySelector("parsererror");
      // console.log("-- File content: ---", bpFile);

      if (!errorNode) {
        // console.log("-- File content: ---", bpFile);
        const blueprintItem = await parseBlueprintSbc(bpFile);
        setBlueprint(blueprintItem);
        setPending(false);
        setFileError(false);
        setFileEncrypt(false);
      } else {
        // const output = pako.inflate(event.target.result);
        // const zipFile = changeExtension(event.target.result);
        // console.log("/// zipFile ///", zipFile);
        // setFileError(true);
        // compressed = Array.<number> or Uint8Array
        // const inflated = inflateSync(bpFile).toString();
        // console.log("-- File corrupt: ---", bpFile);
        setPending(false);
        setFileEncrypt(true);
      }
    };
    reader.readAsText(fileItem);
  };

  const removeBlueprint = () => {
    setFile(null);
    setBlueprint(null);
    setbpUnknownBlocks(0);
  };

  const getMaxShipWeight = () => {
    let maxShipWeightOutput = gridMass;
    const hydroThrustSm = dataSize.Small.Hyd * smallHydrogen;
    const hydroThrustLg = dataSize.Large.Hyd * largeHydrogen;
    const atmoThrustSm = dataSize.Small.Atm * smallAtm;
    const fatmoThrustSm = dataSize.Small.Fatm * smallFatm;
    const atmoThrustLg = dataSize.Large.Atm * largeAtm;
    const fatmoThrustLg = dataSize.Large.Fatm * largeFatm;
    const totalThrust =
      hydroThrustSm + hydroThrustLg + atmoThrustSm + atmoThrustLg + fatmoThrustSm + fatmoThrustLg;

    maxShipWeightOutput = totalThrust ? totalThrust / gravity1 : maxShipWeightOutput;

    return Math.floor(maxShipWeightOutput);
  };

  const rows = [
    createData(
      t("LargeHydrogenThruster"),
      Math.ceil(thrustWeight / dataSize.Large.Hyd),
      "#f57f17",
      thrust4
    ),
    createData(
      t("HydrogenThruster"),
      Math.ceil(thrustWeight / dataSize.Small.Hyd),
      "#f57f17",
      thrust3
    ),
    createData(
      t("LargeAtmosphericThruster"),
      Math.ceil(thrustWeight / dataSize.Large.Atm),
      "#26a69a",
      thrust2
    ),
    createData(
      t("AtmosphericThruster"),
      Math.ceil(thrustWeight / dataSize.Small.Atm),
      "#26a69a",
      thrust1
    ),
    createData(
      t("LargeFlatAtmosphericThruster"),
      Math.ceil(thrustWeight / dataSize.Large.Fatm),
      "#f50057",
      thrust7
    ),
    createData(
      t("FlatAtmosphericThruster"),
      Math.ceil(thrustWeight / dataSize.Small.Fatm),
      "#f50057",
      thrust8
    ),
  ];

  const damageRows = [
    createDataDamage(t("LargeIonThruster"), "Small", 4, "#2196f3", thrust5),
    createDataDamage(t("IonThruster"), "Small", 2, "#2196f3", thrust6),
    createDataDamage(t("LargeHydrogenThruster"), "Small", 7, "#2196f3", thrust4),
    createDataDamage(t("HydrogenThruster"), "Small", 2, "#2196f3", thrust3),
    createDataDamage(t("LargeAtmosphericThruster"), "Small", 5, "#2196f3", thrust2),
    createDataDamage(t("AtmosphericThruster"), "Small", 2, "#2196f3", thrust1),
    createDataDamage(t("LargeFlatAtmosphericThruster"), "Small", 3, "#2196f3", thrust7),
    createDataDamage(t("FlatAtmosphericThruster"), "Small", 2, "#2196f3", thrust8),
    createDataDamage(t("LargeIonThruster"), "Large", 5, "#c4001d", thrust5),
    createDataDamage(t("IonThruster"), "Large", 3, "#c4001d", thrust6),
    createDataDamage(t("LargeHydrogenThruster"), "Large", 7, "#c4001d", thrust4),
    createDataDamage(t("HydrogenThruster"), "Large", 2, "#c4001d", thrust3),
    createDataDamage(t("LargeAtmosphericThruster"), "Large", 5, "#c4001d", thrust2),
    createDataDamage(t("AtmosphericThruster"), "Large", 2, "#c4001d", thrust1),
    createDataDamage(t("LargeFlatAtmosphericThruster"), "Large", 3, "#c4001d", thrust7),
    createDataDamage(t("FlatAtmosphericThruster"), "Large", 2, "#c4001d", thrust8),
  ];

  /* const styles = (theme) => ({
    [theme.breakpoints.down("md")]: {},
  }); */

  const computeJumpDistance = (mass, item) => {
    const currentJump = parseFloat(`${(2500 * item) / (mass / 1000000)}`).toFixed(2);
    setMaxJump(currentJump > item * 2000 ? item * 2000 : currentJump);
  };

  /* const copyToClipBoard = (copyText) => {
    navigator.clipboard.writeText(copyText);
    toast.info(`${copyText} has been copied to clipboard`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }; */

  const calculate = () => {
    const form = document.querySelector("#calculator");
    const output = document.querySelector("#output");
    const data = new FormData(form);
    const vars = {
      r: 8, // Radius Multiplier
      f: 0.6, // Reefing Level
      d: 1, // Drag Coefficient
      gridSize: 0, // Grid Type
      m: 0, // Grid Mass kq
      g: 0, // Gravity m/s2
      atm: 0, // Atmospheres ATM
      t: 0, // Desired Terminal Velocity m/s
      timeout: 5, // Computation timeout.
      showProcess: 0, // Show output while working.
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const entry of data) {
      vars[entry[0]] = Number(entry[1]);
      if (vars[entry[0]] <= 0) {
        output.innerText = t("invalid");
        setParachuteError(true);
        return;
      }
      setParachuteError(false);
    }

    let parachutes;
    parachutes = 0;
    const showProcess = vars.showProcess === 1;
    const diameter = (Math.log10(10 * (vars.atm - vars.f) - 0.99) + 5) * vars.r * vars.gridSize;

    // eslint-disable-next-line no-restricted-properties
    const area = Math.PI * Math.pow(diameter / 2, 2);
    const weight = vars.m * vars.g;
    let start;
    const work = () => {
      const terminalVelocity = Math.sqrt(
        // eslint-disable-next-line no-plusplus
        weight / (area * vars.d * ++parachutes * vars.atm * 1.225 * 2.5)
      );
      // Interperation of https://spaceengineerswiki.com/Parachute_Hatch#Terminal_Velocity
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(terminalVelocity)) {
        output.innerText = `${t("velocity1")}\n`;
        output.innerText += `${t("velocity2")}`;
        setParachuteError(true);
        return;
      }
      setParachuteError(false);
      const timeSpent = (new Date().getTime() - start) / 1000;

      if (showProcess) {
        output.innerText = `${terminalVelocity.toFixed(2)} m/s @ ${parachutes} parachutes with ${(
          vars.timeout - timeSpent
        ).toFixed(1)} seconds remaining to timeout.`;
      }
      if (terminalVelocity <= vars.t) {
        output.innerText = `${t("chute1")} ${parachutes} ${t("chute2")} ${terminalVelocity.toFixed(
          2
        )} m/s`;
        return;
      }
      if (timeSpent >= vars.timeout) {
        output.innerText = `Computation timeout at ${parachutes} parachutes.`;
        return;
      }
      if (showProcess) {
        window.requestAnimationFrame(work);
      } else {
        setTimeout(work, 0);
      }
    };
    output.innerText = `${t("Calculating")}`;
    start = new Date().getTime();
    window.requestAnimationFrame(work);
  };

  const update = () => {
    const radius = planetData[selectedPlanet].Radius;
    const gravity = planetData[selectedPlanet].Gravity * 9.81;
    const gravBegin = planetData[selectedPlanet].GravBegin;
    const radiusEdge = planetData[selectedPlanet].RadiusEdge;
    const ionFullPower = planetData[selectedPlanet].IonFullPower;
    const atmosNoPower = planetData[selectedPlanet].AtmosNoPower;
    const isBig = gridSize === "large";
    const isAngled = ascentAngle === "45";
    const thrustMod = isAngled ? 0.707 : 1;
    const ionThrust =
      (smallIons * (isBig ? thrusterData.Large.Small.Ion : thrusterData.Small.Small.Ion) +
        largeIons * (isBig ? thrusterData.Large.Large.Ion : thrusterData.Small.Large.Ion)) *
      thrustMod;
    const atmThrust =
      (smallAtm * (isBig ? thrusterData.Large.Small.Atm : thrusterData.Small.Small.Atm) +
        largeAtm * (isBig ? thrusterData.Large.Large.Atm : thrusterData.Small.Large.Atm)) *
      thrustMod;
    const flatAtmThrust =
      (smallFatm * (isBig ? thrusterData.Large.Small.Fatm : thrusterData.Small.Small.Fatm) +
        largeFatm * (isBig ? thrusterData.Large.Large.Fatm : thrusterData.Small.Large.Fatm)) *
      thrustMod;
    const hydThrust =
      (smallHydrogen * (isBig ? thrusterData.Large.Small.Hyd : thrusterData.Small.Small.Hyd) +
        largeHydrogen * (isBig ? thrusterData.Large.Large.Hyd : thrusterData.Small.Large.Hyd)) *
      thrustMod;
    let mass = gridMass;

    if (fillCargo) {
      let additionalWeight = 0;

      if (smallCargoCount) {
        additionalWeight += cargoData[gridSize].small * smallCargoCount * 2.7081 * gravity1;
      }

      if (mediumCargoCount) {
        additionalWeight += cargoData[gridSize].medium * mediumCargoCount * 2.7081 * gravity1;
      }

      if (largeCargoCount) {
        additionalWeight += cargoData[gridSize].large * largeCargoCount * 2.7081 * gravity1;
      }

      setIsFilled(Boolean(additionalWeight));

      mass += additionalWeight;
    }

    const interval = radiusEdge / 100 - ((radiusEdge / 100) % 10);
    const dataGraph = [];

    for (let i = 0; i < radiusEdge; i += interval) {
      const ie = i < ionFullPower ? (0.8 * i) / ionFullPower + 0.2 : 1;
      const ae = i < atmosNoPower ? 1 - i / atmosNoPower : 0;
      const ip = (ie * ionThrust) / mass;
      const ap = (ae * (atmThrust + flatAtmThrust)) / mass;
      const hp = hydThrust / mass;

      dataGraph.push({
        name: Math.round(i / 1000) * 1000,
        ion: Math.round((ip + ap + hp) * 100) / 100,
        atm: Math.round((ap + hp) * 100) / 100,
        hyd: hp,
        grav:
          i + radius < gravBegin
            ? parseFloat(gravity.toFixed(2))
            : parseFloat((gravity * (gravBegin / (radius + i)) ** 7).toFixed(2)),
      });
    }
    setDatag(dataGraph);
  };

  const action = (
    <div>
      <NavLink to="/privacy">
        <Button id="snackbar-button-privacy" onClick={handleClose} color="secondary" size="small">
          {t("Privacy")}
        </Button>
      </NavLink>
      <Button id="snackbar-button" onClick={handleClose} color="secondary" size="small">
        OK
      </Button>
    </div>
  );

  const getCookie = (name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  /* const eraseCookie = (name) => {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }; */

  useEffect(() => {
    const isAlreadyAccept = getCookie("se-calculator");

    if (!isAlreadyAccept) {
      // setCookie("se-calculator", "cookie-accept", 30);
      setIsCookie(true);
    }
  }, []);

  useEffect(() => {
    update();
  }, [
    selectedPlanet,
    gridMass,
    gridSize,
    largeIons,
    largeHydrogen,
    largeAtm,
    largeFatm,
    smallIons,
    smallHydrogen,
    smallAtm,
    smallFatm,
    ascentAngle,
    gravity1,
    smallCargoCount,
    mediumCargoCount,
    largeCargoCount,
    fillCargo,
  ]);

  useEffect(() => {
    calculate();
  }, [gridSize, gridMass, gravity1, atm1, terminalVelocity1]);

  useEffect(() => {
    computeJumpDistance(gridMass, jdrive);
  }, [gridMass, jdrive]);

  /*  if (blueprint && blueprint.dlc) {
    //console.log("/// bp ///", blueprint.dlc);
  }  */

  return (
    <>
      <span id="header">
        <DefaultNavbar
          routes={[]}
          action={{
            type: "external",
            route: "https://steamcommunity.com/profiles/76561198158138204",
            label: "@OXOMUS",
            color: "info",
          }}
          sticky
        />
      </span>
      {isVideoStopped ? (
        <MKBox
          id="video-inner"
          minHeight="65vh"
          width="100%"
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
            backgroundImage: `url(${headerBP})`,
          }}
        >
          <span className="offuscator">
            <img src={cosmonautscta} alt="cosmonautscta" id="image-se" />
            <Container>
              <Grid id="override" container item xs={12} lg={12} justifyContent="center" mx="auto">
                <h1 className="title">SE Calculator</h1>
                <div className="subtitle">{t("title")}</div>
              </Grid>
            </Container>
          </span>
        </MKBox>
      ) : (
        <YoutubeBackground
          videoId="0KnEi3JKCIc"
          overlay={null}
          className="background-video"
          nocookie={false}
          playerOptions={{
            start: 1,
            end: 217,
          }}
          onEnd={() => {
            setIsVideoStopped(true);
          }}
          onPause={() => {
            setIsVideoStopped(true);
          }}
          onError={() => {
            setIsVideoStopped(true);
          }}
        >
          {/* <img className={`cosmonaut ${isVideoStopped ? 'show' : ''}`} src={cosmonautscta} alt="cosmonautscta" id="image-vid" /> */}
          <MKBox
            id="video-inner"
            minHeight="65vh"
            width="100%"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Container>
              <Grid id="override" container item xs={12} lg={12} justifyContent="center" mx="auto">
                <h1 className="title">SE Calculator</h1>
                <div className="subtitle">{t("title")}</div>
              </Grid>
            </Container>
          </MKBox>
        </YoutubeBackground>
      )}
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
            <Grid className="fix-padding-lef" item xs={12} lg={12}>
              <h5
                style={{
                  backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                  color: "#FFFFFF",
                  padding: "0.8em 0.8em 0.8em 1em",
                  marginTop: "3em",
                  borderRadius: "5px",
                }}
              >
                <HardwareIcon className="title-icon" /> {t("subTitleA")}
              </h5>
            </Grid>
            <Grid
              className="fix-padding-lef no-padding-left"
              container
              item
              xs={12}
              lg={12}
              spacing={2}
              justifyContent="center"
              mx="auto"
              style={{ margin: "0 0 1em" }}
            >
              {!file ? (
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <div
                    style={{
                      backgroundColor: "#e3f2fd",
                      padding: "0.5em",
                      borderRadius: "8px",
                      marginTop: "0.5em",
                      marginBottom: "0",
                    }}
                  >
                    <InfoIcon /> {t("parserA1")}{" "}
                    <b>
                      <em>{t("parserA2")}</em>
                    </b>{" "}
                    {t("parserA3")}
                  </div>
                </Grid>
              ) : null}
              {fileEncrypt ? (
                <Grid item style={{ textAlign: "center", width: "100%" }} xs={12} lg={12}>
                  <div className="file-encrypt-container">
                    <div className="message">
                      <InfoIcon /> {t("compressedFile")}
                    </div>
                    <div>
                      <ol className="list-container">
                        <li>{t("Renommez le fichier .sbc, en changeant l'extension en .zip")}</li>
                        <li>{t("Décompressez le fichier .zip avec un utilitaire d'archives.")}</li>
                        <li>{t("Vous obtiendrez un nouveau fichier sans extension.")}</li>
                        <li>{t("Supprimez l'archive .zip")}</li>
                        <li>
                          {t(
                            "Renommez le nouveau fichier sans extension obtenue, en ajoutant l'extension .sbc"
                          )}
                        </li>
                        <li>{t("Enfin vous pouvez uploader votre fichier.")}</li>
                      </ol>
                    </div>
                    <div className="steps-container">
                      <img src={stepImages[i18n.language]} alt="step" />
                    </div>
                  </div>
                </Grid>
              ) : null}
              {pending ? (
                <Grid
                  className="fix-padding-lef"
                  item
                  style={{ textAlign: "center", width: "100%" }}
                  xs={12}
                  lg={12}
                >
                  <Box
                    id="progess-container"
                    sx={{
                      display: "flex",
                    }}
                  >
                    <CircularProgress color="secondary" />
                  </Box>
                </Grid>
              ) : (
                <div id="upload-container">
                  {file && blueprint && !fileError ? (
                    <Grid
                      container
                      item
                      xs={12}
                      lg={12}
                      spacing={2}
                      justifyContent="center"
                      mx="auto"
                    >
                      <Grid className="fix-bp-padding" container item xs={12} lg={4}>
                        <Card
                          sx={{
                            maxWidth: "100%",
                            height: "fit-content",
                            position: "relative",
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="auto"
                            image={blueprintBackground}
                            alt="green iguana"
                          />
                          <CardContent>
                            <Typography variant="body2" component="div" color="text.secondary">
                              <div className="card-label">
                                <b>{t("bpA1")}</b>
                                <b style={{ color: "#2196f3" }}>{blueprint.bpName}</b>
                              </div>
                              <div className="card-label">
                                <b>{t("bpA2")}</b>
                                <b style={{ color: "#2196f3" }}>{blueprint.ownerName}</b>
                              </div>
                              <div className="card-label">
                                <b>{t("bpA3")}</b>
                                <b style={{ color: "#2196f3" }}>{blueprint.bpGridSize}</b>
                              </div>
                              <div className="dlc">
                                <span>
                                  <b>DLC :</b>
                                </span>
                                {(blueprint &&
                                  blueprint.dlc &&
                                  blueprint.dlc.map((dlcItem) => (
                                    <div key={`${dlcItem}`}>
                                      <img
                                        src={dlcImages[dlcItem]}
                                        alt={dlcItem}
                                        height={40}
                                        width={40}
                                      />
                                    </div>
                                  ))) ||
                                  null}
                              </div>
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              style={{
                                backgroundColor: "#c4001d",
                                color: "#FFFFFF",
                              }}
                              onClick={() => {
                                removeBlueprint();
                              }}
                              variant="contained"
                              size="small"
                            >
                              {t("Remove")}
                            </Button>
                            <Button disabled>{t("Edit")}</Button>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid className="fix-bp-padding" container item xs={12} lg={8} spacing={2}>
                        <Grid item xs={12} lg={6}>
                          <Card sx={{ maxWidth: "100%", height: "100%" }}>
                            <CardContent>
                              <Typography variant="body2" component="div" color="text.secondary">
                                <div className="card-label">
                                  <h5>{t("bpA5")}</h5>
                                  <div>
                                    {Object.keys(blueprint.cost).map((key) => (
                                      <div key={key} className="card-label">
                                        <b>
                                          <img
                                            src={componentsData[key].IconPath}
                                            width={30}
                                            height={30}
                                            alt={key}
                                            style={{
                                              marginRight: "0.25em",
                                            }}
                                          />
                                          {key}
                                        </b>{" "}
                                        : <span>{kFormatter(blueprint.cost[key])}</span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </Typography>
                            </CardContent>
                            <CardActions />
                          </Card>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Card sx={{ maxWidth: "100%", height: "100%" }}>
                            <CardContent>
                              <Typography variant="body2" component="div" color="text.secondary">
                                <div className="card-label">
                                  <h5>{t("bpA6")}</h5>
                                  <div>
                                    {Object.keys(blueprint.ingots).map((key) => (
                                      <div key={key} className="card-label">
                                        <b>
                                          <img
                                            src={ingotImages[key.toLowerCase()]}
                                            width={30}
                                            height={30}
                                            alt={key}
                                            style={{
                                              marginRight: "0.25em",
                                            }}
                                          />
                                          {key}
                                        </b>{" "}
                                        <span>{kFormatter(blueprint.ingots[key])}</span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </Typography>
                            </CardContent>
                            <CardActions />
                          </Card>
                        </Grid>
                      </Grid>
                      <Grid className="fix-bp-padding" container item xs={12} lg={12} spacing={2}>
                        <Grid className="fix-padding-lef" item xs={12} lg={12}>
                          <h5
                            style={{
                              backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                              color: "#FFFFFF",
                              padding: "0.8em 0.8em 0.8em 1em",
                              marginTop: "3em",
                              borderRadius: "5px",
                            }}
                          >
                            <TvIcon className="title-icon" /> Automatic LCD2
                          </h5>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Card sx={{ maxWidth: "100%", height: "100%" }}>
                            <CardContent>
                              <Typography variant="body2" component="div" color="text.secondary">
                                <div className="card-label">
                                  <h5>{t("bpA5")} </h5>
                                  <div>
                                    <span className="label-inline">LCD name :</span>
                                    <div className="inline">Missing Components [LCD]</div>
                                  </div>
                                  <code className="code-container">
                                    Missing *{" "}
                                    {`${Object.keys(blueprint.cost)
                                      .map((key) => `+${key.toLowerCase()}:${blueprint.cost[key]}/`)
                                      .join(" ")}`}
                                  </code>
                                </div>
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Card sx={{ maxWidth: "100%", height: "100%" }}>
                            <CardContent>
                              <Typography variant="body2" component="div" color="text.secondary">
                                <div className="card-label">
                                  <h5>{t("bpA6")}</h5>
                                  <div>
                                    <span className="label-inline">LCD name :</span>
                                    <div className="inline">Missing Ingots [LCD]</div>
                                  </div>
                                  <div className="code-container">
                                    Missing *{" "}
                                    {`${Object.keys(blueprint.ingots)
                                      .map(
                                        (key) => `+${key.toLowerCase()}:${blueprint.ingots[key]}/`
                                      )
                                      .join(" ")}`}
                                  </div>
                                </div>
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <div id="dropzone">
                      <FileUploader
                        label={t("uploadLabel")}
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                        onTypeError={() => {
                          // console.warn(" error ", error);
                        }}
                        onSizeError={() => {
                          // console.warn(" error ", fileSizeError);
                        }}
                      />
                      <div className="upload-message">{t("bpA7")}</div>
                    </div>
                  )}
                </div>
              )}
              {!file ? (
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <div className="upload-text">
                    <FolderOpenIcon /> {t("bpA8")}
                    <b>
                      {" "}
                      <em>&quot;{t("bpA9")}&quot;</em>
                    </b>{" "}
                    {t("bpA10")}
                  </div>
                </Grid>
              ) : null}
            </Grid>
            {!file ? (
              <Grid className="fix-padding-lef" item xs={12} lg={12}>
                <div id="plan-hint">
                  <h5>{t("bpA11")}</h5>
                  <div id="plan-container">
                    <CloudDownloadIcon className="title-icon" /> {t("bpA12")}
                  </div>
                  <a
                    href="https://store.steampowered.com/account/remotestorageapp/?appid=244850"
                    target="popup"
                    rel="noreferrer"
                    onClick={() => {
                      window.open(
                        "https://store.steampowered.com/account/remotestorageapp/?appid=244850",
                        "name",
                        "width=950,height=500"
                      );
                    }}
                  >
                    <img src={steam} id="steam-screen" alt="Steam Account" />
                    <em>{t("clickHere")}</em>
                  </a>
                </div>
              </Grid>
            ) : null}
          </Grid>
          {file && bpUnknownBlocks ? (
            <Grid item xs={12} lg={12} justifyContent="center" mx="auto">
              <Card sx={{ maxWidth: "100%", height: "100%" }}>
                <CardContent>
                  <Typography variant="body2" component="div" color="text.secondary">
                    {bpUnknownBlocks ? (
                      <div className="unknown-blocks">
                        <ErrorIcon className="title-icon" /> <b>{bpUnknownBlocks}</b>{" "}
                        {t("unknownBlocks")}
                      </div>
                    ) : null}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
          <Grid
            id="main-container"
            container
            item
            xs={12}
            lg={12}
            spacing={2}
            justifyContent="center"
            mx="auto"
          >
            <Grid
              className="fix-padding-lef fix-title"
              item
              xs={12}
              lg={12}
              justifyContent="center"
              mx="auto"
            >
              <h5
                style={{
                  backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                  color: "#FFFFFF",
                  padding: "0.8em 0.8em 0.8em 1em",
                  borderRadius: "3px",
                }}
              >
                <RocketLaunchIcon className="title-icon" /> {t("subTitleB")}
              </h5>
            </Grid>
            <Grid item xs={12} md={2}>
              <div className="col mobile-radio">
                <h6>{t("gridSize")}</h6>
                <div className="form-check form-check-inline">
                  <input
                    style={{
                      backgroundColor: gridSize === "small" ? "#375a7f" : "#DEDEDE",
                    }}
                    type="radio"
                    className="form-check-input"
                    id="smallGrid0"
                    name="gridSize"
                    value="small"
                    checked={gridSize === "small"}
                    onChange={() => {
                      setGridSize("small");
                    }}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="form-check-label" htmlFor="smallGrid0">
                    {t("small")}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    style={{
                      backgroundColor: gridSize === "large" ? "#375a7f" : "#DEDEDE",
                    }}
                    type="radio"
                    className="form-check-input"
                    id="largeGrid0"
                    name="gridSize"
                    value="large"
                    checked={gridSize === "large"}
                    onChange={() => {
                      setGridSize("large");
                    }}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="form-check-label" htmlFor="largeGrid0">
                    {t("large")}
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className="col">
                <div className="form-floating mb-3 mt-3">
                  <input
                    type="number"
                    className="form-control"
                    id="gridMass"
                    placeholder="Mass of your ship"
                    name="gridMass"
                    value={gridMass}
                    min="0"
                    onChange={(e) => {
                      setGridMass(e.target.value);
                    }}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="satm">{t("gridMass")}</label>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className="col">
                <div className="form-floating mb-3 mt-3">
                  <input
                    type="number"
                    className="form-control"
                    id="gravity"
                    placeholder="Gravity"
                    name="gravity"
                    min="1"
                    value={gravity1}
                    onChange={(e) => setGravity(e.target.value)}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="mass">
                    {t("gravity")} (m/s<sup>2</sup>):
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormControlLabel
                  id="checkbox-label"
                  control={<Checkbox checked={fillCargo} />}
                  onChange={(event) => {
                    setFillCargo(event.target.checked);
                  }}
                  label={t("fillCargo")}
                />
              </FormGroup>
              {fillCargo ? (
                <div>
                  <Box
                    style={{
                      display: "inline-flex",
                    }}
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <TextField
                        required
                        type="number"
                        id="outlined-required"
                        label={t("cargoMultiplier")}
                        value={cargoMultiplier}
                        onChange={(event) => {
                          const nextValue = event.target.value >= 1 ? event.target.value : 1;
                          setCargoMultiplier(nextValue);
                        }}
                      />
                    </div>
                  </Box>
                  <Box
                    id="cargo-multiplier"
                    style={{
                      display: "inline-flex",
                      position: "relative",
                      top: "0.5em",
                    }}
                    sx={{ minWidth: 120 }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">{t("cargoMultiplier2")}</InputLabel>
                      <Select
                        style={{
                          padding: "1em",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={cargoMultiplier}
                        label={t("cargoMultiplier2")}
                        onChange={(event) => {
                          setCargoMultiplier(event.target.value);
                        }}
                      >
                        <MenuItem value={1}>{t("realistic")}</MenuItem>
                        <MenuItem value={3}>x3</MenuItem>
                        <MenuItem value={5}>x5</MenuItem>
                        <MenuItem value={10}>x10</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <h6>{t("cargoCount")}</h6>
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        id="outlined-basic"
                        label={t("smallCargo")}
                        variant="outlined"
                        value={smallCargoCount}
                        onChange={(event) => {
                          const nextValue = event.target.value >= 0 ? event.target.value : 0;
                          setSmallCargoCount(nextValue);
                        }}
                      />
                    </FormControl>
                    {gridSize === "small" ? (
                      <FormControl fullWidth>
                        <TextField
                          type="number"
                          id="filled-basic"
                          label={t("mediumCargo")}
                          variant="outlined"
                          value={mediumCargoCount}
                          onChange={(event) => {
                            const nextValue = event.target.value >= 0 ? event.target.value : 0;
                            setMediumCargoCount(nextValue);
                          }}
                        />
                      </FormControl>
                    ) : null}
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        id="standard-basic"
                        label={t("largeCargo")}
                        variant="outlined"
                        value={largeCargoCount}
                        onChange={(event) => {
                          const nextValue = event.target.value >= 0 ? event.target.value : 0;
                          setLargeCargoCount(nextValue);
                        }}
                      />
                    </FormControl>
                  </Box>
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12} lg={12}>
              <div
                className="hint-box"
                style={{ position: "relative", width: "100%", display: "inline-block" }}
              >
                <div
                  style={{
                    backgroundColor: "rgba(0,0,0,0.03)",
                    padding: "0.5em",
                    borderRadius: "8px",
                    marginTop: "0",
                    marginBottom: "1em",
                  }}
                >
                  <h5>{t("shipThrusters")}</h5>
                  <p>
                    {t("shipNeeds")}{" "}
                    <b style={{ color: "#7f0000" }}>
                      {shipThrust.toLocaleString(i18n.language === "fr" ? "fr-FR" : "en-US")} kN
                    </b>{" "}
                    {t("shipNeedsB")}
                  </p>
                  <div>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead
                          style={{
                            display: "table-header-group !important",
                          }}
                        >
                          <TableRow
                            style={{ position: "relative", width: "100%" }}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell className="table-icon">
                              <PhotoIcon />
                            </TableCell>
                            <TableCell className="no-display-mobile">{t("Thruster")}</TableCell>
                            <TableCell align="right">{t("Count")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow
                              key={row.thruster}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                <img
                                  className="color-filter"
                                  src={row.image}
                                  alt="thruster"
                                  height={30}
                                  width={30}
                                  style={{
                                    filter: "grayscale(80%)",
                                  }}
                                />
                              </TableCell>
                              <TableCell className="no-display-mobile" component="th" scope="row">
                                {row.thruster}
                              </TableCell>
                              <TableCell align="right">
                                <b
                                  style={{
                                    color: row.color,
                                    fontSize: "1.2em",
                                  }}
                                >
                                  {row.count}
                                </b>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </Grid>
            {/* <iframe
              style={{
                height: "100%",
                width: "100%",
                minHeight: "80vh",
                position: "relative",
                border: "none",
              }}
              src="https://343n.github.io/spaceengineers-thrust-calc/"
              title="Space Engineers Thruster Requirement Calculator"
            /> */}
          </Grid>
          <Grid
            className="thrusters-graph-info fix-title"
            item
            xs={12}
            lg={12}
            justifyContent="center"
            mx="auto"
          >
            <h5
              style={{
                backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                color: "#FFFFFF",
                padding: "0.8em 0.8em 0.8em 1em",
                marginTop: "3em",
                borderRadius: "5px",
              }}
            >
              <AutoGraphIcon className="title-icon" /> {t("ThrusterEfficiencies")}
            </h5>
            <div className="container mt-3 collapse show" id="infoDiv">
              <form id="info">
                <div className="row inline-mobile">
                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <select
                        className="form-select"
                        id="planet"
                        name="planet"
                        onChange={(e) => {
                          setSelectedPlanet(e.target.value);
                          setGravity((planetData[e.target.value].Gravity * 9.81).toFixed(2));
                        }}
                      >
                        <option value="Earthlike">{t("Earthlike")}</option>
                        <option value="Alien">{t("Alien")}</option>
                        <option value="Mars">{t("Mars")}</option>
                        <option value="Pertam">{t("Pertam")}</option>
                        <option value="Triton">{t("Triton")}</option>
                        <option value="Moon">{t("Moon")}</option>
                        <option value="Europa">{t("Europa")}</option>
                        <option value="Titan">{t("Titan")}</option>
                      </select>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="planet" className="form-label">
                        {t("SelectPlanet")}
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <input
                        type="number"
                        className="form-control"
                        id="mass"
                        placeholder="Mass in Kg"
                        name="mass"
                        min="1"
                        value={gridMass}
                        onChange={(e) => {
                          setGridMass(e.target.value);
                        }}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="mass">{t("gridMass")}</label>
                    </div>
                  </div>
                  <div className="col mobile-radio">
                    <h6>{t("gridSize")}</h6>
                    <div className="form-check form-check-inline">
                      <input
                        style={{
                          backgroundColor: gridSize === "small" ? "#375a7f" : "#DEDEDE",
                        }}
                        type="radio"
                        className="form-check-input"
                        id="smallGrid2"
                        name="gridSize"
                        value="small"
                        checked={gridSize === "small"}
                        onChange={() => {
                          setGridSize("small");
                        }}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="form-check-label" htmlFor="smallGrid2">
                        {t("small")}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        style={{
                          backgroundColor: gridSize === "large" ? "#375a7f" : "#DEDEDE",
                        }}
                        type="radio"
                        className="form-check-input"
                        id="largeGrid2"
                        name="gridSize"
                        value="large"
                        checked={gridSize === "large"}
                        onChange={() => {
                          setGridSize("large");
                        }}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="form-check-label" htmlFor="largeGrid2">
                        {t("large")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row inline-mobile">
                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <input
                        type="number"
                        className="form-control"
                        id="lion"
                        placeholder={t("NumberOfThrusters")}
                        name="lion"
                        value={largeIons}
                        min="0"
                        onChange={(e) => {
                          setLargeIons(e.target.value);
                        }}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="lion">{t("LargeIonCount")}</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <input
                        type="number"
                        className="form-control"
                        id="lhyd"
                        placeholder={t("NumberOfThrusters")}
                        name="lhyd"
                        value={largeHydrogen}
                        min="0"
                        onChange={(e) => {
                          setLargeHydrogen(e.target.value);
                        }}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="lhyd">{t("LargeHydrogenCount")}</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <input
                        type="number"
                        className="form-control"
                        id="latm"
                        placeholder={t("NumberOfThrusters")}
                        name="latm"
                        value={largeAtm}
                        min="0"
                        onChange={(e) => {
                          setLargeAtm(e.target.value);
                        }}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="latm">{t("LargeAtmosphericCount")}</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <input
                        type="number"
                        className="form-control"
                        id="latm"
                        placeholder={t("NumberOfThrusters")}
                        name="lfatm"
                        value={largeFatm}
                        min="0"
                        onChange={(e) => {
                          setLargeFatm(e.target.value);
                        }}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="latm">{t("LargeFlatAtmosphericCount")}</label>
                    </div>
                  </div>
                </div>
                <div className="row inline-mobile">
                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <input
                        type="number"
                        className="form-control"
                        id="sion"
                        placeholder={t("NumberOfThrusters")}
                        name="sion"
                        value={smallIons}
                        min="0"
                        onChange={(e) => {
                          setSmallIons(e.target.value);
                        }}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="sion">{t("SmallIonCount")}</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <input
                        type="number"
                        className="form-control"
                        id="shyd"
                        placeholder={t("NumberOfThrusters")}
                        name="shyd"
                        value={smallHydrogen}
                        min="0"
                        onChange={(e) => {
                          setSmallHydrogen(e.target.value);
                        }}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="shyd">{t("SmallHydrogenCount")}</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <input
                        type="number"
                        className="form-control"
                        id="satm"
                        placeholder={t("NumberOfThrusters")}
                        name="satm"
                        value={smallAtm}
                        min="0"
                        onChange={(e) => {
                          setSmallAtm(e.target.value);
                        }}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="satm">{t("SmallAtmosphericCount")}</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3 mt-3">
                      <input
                        type="number"
                        className="form-control"
                        id="sfatm"
                        placeholder={t("NumberOfThrusters")}
                        name="satm"
                        value={smallFatm}
                        min="0"
                        onChange={(e) => {
                          setSmallFatm(e.target.value);
                        }}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="satm">{t("SmallFlatAtmosphericCount")}</label>
                    </div>
                  </div>
                  {getMaxShipWeight() !== gridMass ? (
                    <div className="max-ship-weight">
                      <FitnessCenterIcon className="title-icon" /> {t("shipMaxWeight")}{" "}
                      <b>
                        {getMaxShipWeight().toLocaleString(
                          i18n.language === "fr" ? "fr-FR" : "en-US"
                        )}{" "}
                        Kg
                      </b>
                      &nbsp;
                      {fillCargo && isFilled ? t("shipNeedsC") : t("shipNeedsD")}
                    </div>
                  ) : null}
                </div>
                <div className="row inline-mobile">
                  <div className="col">
                    <h6 className="mt-4">{t("AscentAngle")}</h6>
                    <div className="form-check form-check-inline">
                      <input
                        style={{
                          backgroundColor: ascentAngle === "90" ? "#375a7f" : "#DEDEDE",
                        }}
                        type="radio"
                        className="form-check-input"
                        id="thrust90"
                        name="ascentAngle"
                        value="90"
                        checked={ascentAngle === "90"}
                        onChange={() => {
                          setAscentAngle("90");
                        }}
                      />
                      90°
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="form-check-label" htmlFor="thrust90" />
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        style={{
                          backgroundColor: ascentAngle === "45" ? "#375a7f" : "#DEDEDE",
                        }}
                        type="radio"
                        className="form-check-input"
                        id="thrust45"
                        name="ascentAngle"
                        value="45"
                        checked={ascentAngle === "45"}
                        onChange={() => {
                          setAscentAngle("45");
                        }}
                      />
                      45°
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="form-check-label" htmlFor="thrust45" />
                    </div>
                    <div
                      style={{
                        backgroundColor: "#e3f2fd",
                        padding: "0.5em",
                        borderRadius: "8px",
                        marginTop: "0.5em",
                        marginBottom: "0",
                      }}
                    >
                      <span>
                        <b>{t("AscentAngle")}</b> {t("ascentB")}
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="container mt-3 mb-5 thrusters-graph">
              <div className="mobile-graph">
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    width={500}
                    height={400}
                    data={datag}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Area type="monotone" dataKey="ion" fill="#8884d8" stroke="#8884d8" />
                    <Area type="monotone" dataKey="atm" fill="#26a69a" stroke="#26a69a" />
                    <Area type="monotone" dataKey="hyd" fill="#ffeb3b" stroke="#ffeb3b" />
                    <Area
                      type="monotone"
                      dataKey="grav"
                      stroke="#e53935"
                      strokeWidth={2}
                      fill="none"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={12}
            spacing={2}
            justifyContent="center"
            mx="auto"
            className="xs-padding-none"
          >
            <Grid
              className="fix-padding-lef"
              item
              xs={12}
              lg={12}
              justifyContent="center"
              mx="auto"
            >
              <h5
                style={{
                  backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                  color: "#FFFFFF",
                  padding: "0.8em 0.8em 0.8em 1em",
                  borderRadius: "3px",
                }}
              >
                <ParaglidingIcon className="title-icon" /> {t("subTitleC")}
              </h5>
              <div
                style={{
                  backgroundColor: "#eeeeee",
                  padding: "0.5em",
                  borderRadius: "8px",
                  marginTop: "0.5em",
                  marginBottom: "0",
                }}
              >
                <b>
                  <LightbulbIcon /> {t("Reminder")}
                </b>{" "}
                {t("reminderText")}
              </div>
            </Grid>
            <Grid item xs={12} lg={12}>
              <form id="calculator">
                <div style={{ position: "relative" }}>
                  <Grid container spacing={2} justifyContent="left" mx="auto">
                    <Grid className="fix-padding-lef" item xs={12} lg={6}>
                      <div
                        id="grid-Size"
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginBottom: "0",
                          marginTop: "0.5em",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "50%",
                            display: "inline-block",
                          }}
                        >
                          <h6>{t("gridSize")}</h6>
                          <input
                            style={{
                              marginRight: "0.25em",
                              "&:checked": {
                                backgroundColor: "#2196F3",
                              },
                            }}
                            id="largeGrid1"
                            name="gridSize"
                            type="radio"
                            value="2.5"
                            onChange={() => {
                              setGridSize("large");
                            }}
                            checked={gridSize === "large"}
                            required
                          />
                          <label
                            style={{
                              marginRight: "0.5em",
                              marginBottom: "0.5em",
                            }}
                            htmlFor="largeGrid1"
                          >
                            {t("Large")}
                          </label>
                        </div>
                        <div
                          style={{
                            position: "relative",
                            width: "50%",
                            display: "inline-block",
                            marginBottom: "1em",
                            marginTop: "1em",
                          }}
                        >
                          <input
                            style={{
                              marginRight: "0.25em",
                            }}
                            id="smallGrid1"
                            name="gridSize"
                            type="radio"
                            value="0.5"
                            onChange={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              setGridSize("small");
                            }}
                            checked={gridSize === "small"}
                            required
                          />
                          <label htmlFor="smallGrid1">{t("small")}</label>
                        </div>
                        <br />
                      </div>
                    </Grid>
                    <Grid className="fix-padding-lef" item xs={12} lg={6} />
                    <Grid className="fix-padding-lef" item xs={12} lg={6}>
                      <div style={{ position: "relative", width: "100%", display: "inline-block" }}>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="m">{t("gridMass")}</label>
                        <input
                          style={{
                            width: "100%",
                            fontSize: "1em",
                            padding: "5px",
                            color: "#1565c0",
                            marginBottom: "1em",
                            maxWidth: "580px",
                          }}
                          id="m"
                          name="m"
                          type="number"
                          step="any"
                          min="0"
                          value={gridMass}
                          onChange={(e) => setGridMass(e.target.value)}
                          required
                        />
                        <br />
                        <label htmlFor="g">
                          {t("gravity")} (m/s<sup>2</sup>):{" "}
                        </label>
                        <input
                          style={{
                            width: "100%",
                            fontSize: "1em",
                            padding: "5px",
                            color: "#1565c0",
                            maxWidth: "580px",
                          }}
                          id="g"
                          name="g"
                          type="number"
                          step="any"
                          min="0"
                          value={gravity1}
                          onChange={(e) => setGravity(e.target.value)}
                          required
                        />
                        <br />
                      </div>
                    </Grid>
                    <Grid className="fix-padding-lef" item xs={12} lg={6}>
                      <div
                        className="hint-box"
                        style={{ position: "relative", width: "100%", display: "inline-block" }}
                      >
                        <div
                          style={{
                            backgroundColor: "rgba(0,0,0,0.03)",
                            padding: "0.5em",
                            borderRadius: "8px",
                            marginTop: "0",
                            marginBottom: "1em",
                          }}
                        >
                          <i>{t("Hint")}</i>
                          <br />
                          {t("EarthIs")} <b>9.81</b> m/s<sup>2</sup>
                          <br />
                          {t("MarsIs")} <b>8.83</b> m/s<sup>2</sup>
                          <br />
                          {t("AlienIs")} <b>10.79</b> m/s<sup>2</sup>
                          <br />
                          {t("PertamIs")} <b>11.77</b> m/s<sup>2</sup>
                          <br />
                          {t("TritonIs")} <b>9.81</b> m/s<sup>2</sup>
                        </div>
                      </div>
                    </Grid>
                    <Grid className="fix-padding-lef" item xs={12} lg={6}>
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          display: "inline-block",
                          marginBottom: "0",
                        }}
                      >
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="atm">{t("Atmospheres")}</label>
                        <input
                          style={{
                            width: "100%",
                            fontSize: "1em",
                            padding: "5px",
                            color: "#1565c0",
                            maxWidth: "580px",
                          }}
                          id="atm"
                          name="atm"
                          type="number"
                          step="any"
                          value={atm1}
                          onChange={(e) => setAtm(e.target.value)}
                          required
                        />
                        <br />
                      </div>
                    </Grid>
                    <Grid className="fix-padding-lef" item xs={12} lg={6}>
                      <div
                        className="hint-box"
                        style={{
                          position: "relative",
                          width: "100%",
                          display: "inline-block",
                          marginBottom: "1em",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "rgba(0,0,0,0.03)",
                            padding: "0.5em",
                            borderRadius: "8px",
                            marginTop: "0",
                            marginBottom: "1em",
                          }}
                        >
                          <i>{t("Hint")}</i>
                          <br />
                          {t("EarthIs")} <b>1</b> atm
                          <br />
                          {t("MarsIs")} <b>0.8</b> atm
                          <br />
                          {t("AlienIs")} <b>1</b> atm
                          <br />
                          {t("TritonIs")} <b>0.8</b> atm
                        </div>
                      </div>
                    </Grid>
                    <Grid className="fix-padding-lef" item xs={12} lg={12}>
                      <label style={{ display: "block" }} htmlFor="t">
                        {t("DesiredVelocity")} (m/s<sup>2</sup>):{" "}
                      </label>
                      <input
                        style={{
                          width: "100%",
                          fontSize: "1em",
                          padding: "5px",
                          color: "#1565c0",
                          maxWidth: "580px",
                        }}
                        id="t"
                        name="t"
                        type="number"
                        step="any"
                        value={terminalVelocity1}
                        onChange={(e) => setTerminalVelocity(e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid className="fix-padding-lef" item xs={12} lg={12}>
                      <div
                        style={{
                          display: "none",
                        }}
                      >
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="timeout">{t("ComputationTimeout")}</label>
                        <input
                          id="timeout"
                          name="timeout"
                          type="number"
                          step="any"
                          value="5"
                          onChange={() => {}}
                          required
                        />
                        <br />
                        <input
                          id="showProcess"
                          name="showProcess"
                          type="checkbox"
                          value="1"
                          onChange={() => {}}
                          checked
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="showProcess">{t("ShowComputation")}</label>
                      </div>
                      <button
                        className="calculate-button"
                        style={{
                          display: "none",
                          padding: "0.4em",
                          fontSize: "1em",
                          border: "none",
                          backgroundColor: "#1565c0",
                          color: "#FFFFFF",
                          borderRadius: "4px",
                          marginTop: "0.5em",
                          marginBottom: "0.5em",
                        }}
                        type="submit"
                      >
                        {t("Calculate")}
                      </button>
                    </Grid>
                  </Grid>
                </div>
              </form>
              <p
                style={{
                  color: "#001970",
                  padding: "1em",
                  backgroundColor: parachuteError ? "#FFCDD2" : "rgba(204,255,144,0.3)",
                  borderRadius: "8px",
                }}
                id="output"
              />
            </Grid>
            <Grid className="fix-padding-lef" item xs={12} lg={12}>
              <h5
                style={{
                  backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                  color: "#FFFFFF",
                  padding: "0.8em 0.8em 0.8em 1em",
                  marginTop: "3em",
                  borderRadius: "5px",
                }}
              >
                <BoltIcon className="title-icon" /> {t("subTitleD")}
              </h5>
              <div className="container mt-12 collapse show" id="infoDiv">
                <form id="jump">
                  <div className="row mobile-radio">
                    <div className="col">
                      <div className="form-floating mb-3 mt-3">
                        <input
                          type="number"
                          className="form-control"
                          id="mass"
                          placeholder={t("MassInKg")}
                          name="mass"
                          min="1"
                          value={gridMass}
                          onChange={(e) => {
                            setGridMass(e.target.value);
                            // computeJumpDistance(e.target.value, jdrive);
                          }}
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="mass">{t("gridMass")}</label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3 mt-3">
                        <input
                          type="number"
                          className="form-control"
                          id="jdrive"
                          placeholder={t("NumberOfJumpive")}
                          name="jdrive"
                          value={jdrive}
                          min="0"
                          onChange={(e) => {
                            setJdrive(e.target.value);
                            // computeJumpDistance(gridMass, e.target.value);
                          }}
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="satm">{t("JumpDriveCount")}</label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-3 mt-3">
                        <div className="max-jump-distance">
                          <div className="label">{t("MaximumJumpDistance")}</div>
                          <div className="value">{maxJump}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <br />
              </div>
              <div>
                <h6>{t("Graph")}</h6>
                <p className="jump-image">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <span className="image">
                    <img
                      className="graph-image"
                      alt="Space Engineers Jump Drive Chart.png"
                      src="https://www.spaceengineerswiki.com/images/2/2a/Space_Engineers_Jump_Drive_Chart.png"
                      width="100%"
                      height="auto"
                    />
                  </span>{" "}
                </p>
              </div>
            </Grid>
            <Grid
              className="fix-padding-left"
              container
              item
              xs={12}
              lg={12}
              spacing={2}
              justifyContent="center"
              mx="auto"
            >
              <Grid className="fix-padding-lef fix-padding-lef" item xs={12} lg={12}>
                <h5
                  style={{
                    backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                    color: "#FFFFFF",
                    padding: "0.8em 0.8em 0.8em 1em",
                    marginTop: "3em",
                    borderRadius: "5px",
                  }}
                >
                  <AirIcon className="title-icon" /> {t("subTitleE")}
                </h5>
              </Grid>
              <Grid className="fix-padding-lef" item xs={12} lg={6}>
                <h6>{t("SmallGrid")}</h6>
                <img
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                  src={damageSmall}
                  alt="Small grid thruster damage"
                />
              </Grid>
              <Grid className="fix-padding-lef" item xs={12} lg={6}>
                <h6>{t("LargeGrid")}</h6>
                <img
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                  src={damageLarge}
                  alt="Large grid thruster damage"
                />
              </Grid>
              <Grid className="fix-padding-left" item xs={12} lg={12}>
                <div className="damage-tabe-container">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead
                        style={{
                          display: "table-header-group !important",
                        }}
                      >
                        <TableRow
                          style={{ position: "relative", width: "100%" }}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell className="table-icon">
                            <PhotoIcon />
                          </TableCell>
                          <TableCell className="no-display-mobile">{t("Thruster")}</TableCell>
                          <TableCell>{t("Size")}</TableCell>
                          <TableCell align="right">{t("block")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {damageRows.map((row) => (
                          <TableRow
                            key={`${row.thruster}-${row.size}`}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              <img
                                className="color-filter"
                                style={{
                                  filter: "grayscale(80%)",
                                }}
                                src={row.image}
                                alt="thruster"
                                height={30}
                                width={30}
                              />
                            </TableCell>
                            <TableCell className="no-display-mobile" component="th" scope="row">
                              {row.thruster}
                            </TableCell>
                            <TableCell>{t(row.size)}</TableCell>
                            <TableCell align="right">
                              <b
                                style={{
                                  color: row.color,
                                  fontSize: "1.2em",
                                }}
                              >
                                {row.count}
                              </b>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container className="footer-container">
          <MKBox
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            Copyright © 2022 Oxomus. Space Engineers and associated Space Engineers images are
            copyright of KEEN SOFTWARE HOUSE. Se-calculator.com is not affiliated with Space
            Engineers and KEEN SOFTWARE HOUSE.
          </MKBox>
        </Container>
        <Container className="footer-link-wrapper">
          <MKBox
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="center"
            alignItems="center"
          >
            <NavLink className="footer-link" to="/privacy">
              {t("privacy policy")}
            </NavLink>
            <NavLink className="footer-link" to="/conditions">
              {t("terms & conditions")}
            </NavLink>
          </MKBox>
        </Container>
      </Card>
      {isCookie ? (
        <Stack id="snackbar" spacing={2} sx={{ width: "100%" }}>
          <SnackbarContent id="snackbar-content" message={t("cookie")} action={action} />
        </Stack>
      ) : null}
    </>
  );
}

export default Presentation;
