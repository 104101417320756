import construction from "./assets/images/construction_components_component.png";
import girder from "./assets/images/girder_component.png";
import metalGrid from "./assets/images/metal_grid_component.png";
import interiorPlate from "./assets/images/interior_plate_component.png";
import steelPlate from "./assets/images/steel_plate_component.png";
import smallTube from "./assets/images/small_tube_component.png";
import largeTube from "./assets/images/large_tube_component.png";
import motor from "./assets/images/motor_component.png";
import display from "./assets/images/display_component.png";
import bulletproofGlass from "./assets/images/bulletproof_glass_component.png";
import computer from "./assets/images/computer_component.png";
import reactor from "./assets/images/reactor_components_component.png";
import thrust from "./assets/images/thrust_components_component.png";
import gravityGenerator from "./assets/images/gravity_generator_components_component.png";
import medical from "./assets/images/medical_components_component.png";
import radioCommunication from "./assets/images/radio_communication_components_component.png";
import detector from "./assets/images/detector_components_component.png";
import canvas from "./assets/images/Cartridge_Icon.png";
import explosives from "./assets/images/ExplosivesComponent.png";
import solarCell from "./assets/images/SolarCellComponent.png";
import powerCell from "./assets/images/BatteryComponent.png";
import superconductor from "./assets/images/superconductor_conducts_component.png";
import zoneChip from "./assets/images/ZoneChip_Item.png";
import mk1 from "./assets/images/mk1.jpg";
import Plushie from "./assets/images/Plushie.png";
import SabiroidPlushie from "./assets/images/SabiroidePlushie.png";

/* BROWSER LANGUAGE */
export const detectBrowserLanguage = () =>
  (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

export const planetData = {
  Earthlike: {
    Radius: 60000.0,
    Gravity: 1.0,
    GravBegin: 67200,
    RadiusEdge: 44657,
    IonFullPower: 14400,
    AtmosNoPower: 10080,
  },
  Alien: {
    Radius: 60000.0,
    Gravity: 1.1,
    GravBegin: 67200,
    RadiusEdge: 46092,
    IonFullPower: 14400,
    AtmosNoPower: 10080,
  },
  Mars: {
    Radius: 60000.0,
    Gravity: 0.9,
    GravBegin: 67200,
    RadiusEdge: 43093,
    IonFullPower: 14400,
    AtmosNoPower: 10080,
  },
  Pertam: {
    Radius: 30000.0,
    Gravity: 1.2,
    GravBegin: 30818,
    RadiusEdge: 19262,
    IonFullPower: 1500,
    AtmosNoPower: 1050,
  },
  Triton: {
    Radius: 40126.5,
    Gravity: 1.0,
    GravBegin: 48151,
    RadiusEdge: 34990,
    IonFullPower: 3760,
    AtmosNoPower: 2632,
  },
  Moon: {
    Radius: 9500.0,
    Gravity: 0.25,
    GravBegin: 9785,
    RadiusEdge: 3001,
    IonFullPower: 0,
    AtmosNoPower: 399,
  },
  Europa: {
    Radius: 9500.0,
    Gravity: 0.25,
    GravBegin: 10070,
    RadiusEdge: 3365,
    IonFullPower: 1140,
    AtmosNoPower: 798,
  },
  Titan: {
    Radius: 9500.0,
    Gravity: 0.25,
    GravBegin: 9785,
    RadiusEdge: 3001,
    IonFullPower: 570,
    AtmosNoPower: 399,
  },
  Yui: {
    Radius: 9500.0,
    Gravity: 3.4,
    GravBegin: 10500,
    RadiusEdge: 15340,
    IonFullPower: 1140,
    AtmosNoPower: 399,
  },
  Taravi: {
    Radius: 120000.0,
    Gravity: 1.0,
    GravBegin: 122220,
    RadiusEdge: 30340,
    IonFullPower: 14400,
    AtmosNoPower: 10080,
  },
};

export const blocksData = {
  LargeRailStraight: {
    IconPath: "Textures\\GUI\\Icons\\Fake.dds",
    Cost: {
      SteelPlate: 12,
      Construction: 8,
      LargeTube: 4,
    },
  },
  DebugSphereLarge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SpaceBall.dds",
    Cost: {
      SteelPlate: 10,
      Computer: 20,
    },
  },
  LargeBlockArmorBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\light_armor_cube.dds",
    Cost: {
      SteelPlate: 25,
    },
  },
  LargeBlockArmorSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\light_armor_slope.dds",
    Cost: {
      SteelPlate: 13,
    },
  },
  LargeBlockArmorCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\light_armor_corner.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeBlockArmorCornerInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\light_armor_inv_corner.dds",
    Cost: {
      SteelPlate: 21,
    },
  },
  LargeRoundArmor_Slope: {
    IconPath: "Textures\\GUI\\Icons\\Fake.dds",
    Cost: {
      SteelPlate: 13,
    },
  },
  LargeRoundArmor_Corner: {
    IconPath: "Textures\\GUI\\Icons\\Fake.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeRoundArmor_CornerInv: {
    IconPath: "Textures\\GUI\\Icons\\Fake.dds",
    Cost: {
      SteelPlate: 21,
    },
  },
  LargeHeavyBlockArmorBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\heavy_armor_cube.dds",
    Cost: {
      SteelPlate: 150,
      MetalGrid: 50,
    },
  },
  LargeHeavyBlockArmorSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\heavy_armor_slope.dds",
    Cost: {
      SteelPlate: 75,
      MetalGrid: 25,
    },
  },
  LargeHeavyBlockArmorCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\heavy_armor_corner.dds",
    Cost: {
      SteelPlate: 25,
      MetalGrid: 10,
    },
  },
  LargeHeavyBlockArmorCornerInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\heavy_armor_inv_corner.dds",
    Cost: {
      SteelPlate: 125,
      MetalGrid: 50,
    },
  },
  SmallBlockArmorBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\light_armor_cube.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\light_armor_slope.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\light_armor_corner.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorCornerInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\light_armor_inv_corner.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallHeavyBlockArmorBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\heavy_armor_cube.dds",
    Cost: {
      SteelPlate: 5,
      MetalGrid: 2,
    },
  },
  SmallHeavyBlockArmorSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\heavy_armor_slope.dds",
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  SmallHeavyBlockArmorCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\heavy_armor_corner.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallHeavyBlockArmorCornerInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\heavy_armor_inv_corner.dds",
    Cost: {
      SteelPlate: 4,
      MetalGrid: 1,
    },
  },
  LargeHalfArmorBlock: {
    IconPath: "Textures/GUI/Icons/Cubes/LightArmorSquareSlab.dds",
    Cost: {
      SteelPlate: 12,
    },
  },
  LargeHeavyHalfArmorBlock: {
    IconPath: "Textures/GUI/Icons/Cubes/HeavyArmorSquareSlab.dds",
    Cost: {
      SteelPlate: 75,
      MetalGrid: 25,
    },
  },
  LargeHalfSlopeArmorBlock: {
    IconPath: "Textures/GUI/Icons/Cubes/LightArmorSlopeSlab.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeHeavyHalfSlopeArmorBlock: {
    IconPath: "Textures/GUI/Icons/Cubes/HeavyArmorSlopeSlab.dds",
    Cost: {
      SteelPlate: 19,
      MetalGrid: 6,
    },
  },
  HalfArmorBlock: {
    IconPath: "Textures/GUI/Icons/Cubes/LightArmorSquareSlab.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  HeavyHalfArmorBlock: {
    IconPath: "Textures/GUI/Icons/Cubes/HeavyArmorSquareSlab.dds",
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  HalfSlopeArmorBlock: {
    IconPath: "Textures/GUI/Icons/Cubes/LightArmorSlopeSlab.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  HeavyHalfSlopeArmorBlock: {
    IconPath: "Textures/GUI/Icons/Cubes/HeavyArmorSlopeSlab.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorRoundSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RoundArmorSlope.dds",
    Cost: {
      SteelPlate: 13,
    },
  },
  LargeBlockArmorRoundCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RoundArmorCorner.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeBlockArmorRoundCornerInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RoundArmorInvCorner.dds",
    Cost: {
      SteelPlate: 21,
    },
  },
  LargeHeavyBlockArmorRoundSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyRoundArmorSlope.dds",
    Cost: {
      SteelPlate: 130,
      MetalGrid: 50,
    },
  },
  LargeHeavyBlockArmorRoundCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyRoundArmorCorner.dds",
    Cost: {
      SteelPlate: 125,
      MetalGrid: 40,
    },
  },
  LargeHeavyBlockArmorRoundCornerInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyRoundArmorInvCorner.dds",
    Cost: {
      SteelPlate: 140,
      MetalGrid: 50,
    },
  },
  SmallBlockArmorRoundSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RoundArmorSlope.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorRoundCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RoundArmorCorner.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorRoundCornerInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RoundArmorInvCorner.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallHeavyBlockArmorRoundSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyRoundArmorSlope.dds",
    Cost: {
      SteelPlate: 4,
      MetalGrid: 1,
    },
  },
  SmallHeavyBlockArmorRoundCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyRoundArmorCorner.dds",
    Cost: {
      SteelPlate: 4,
      MetalGrid: 1,
    },
  },
  SmallHeavyBlockArmorRoundCornerInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyRoundArmorInvCorner.dds",
    Cost: {
      SteelPlate: 5,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorSlope2Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Slope2x1x1Base.dds",
    Cost: {
      SteelPlate: 19,
    },
  },
  LargeBlockArmorSlope2Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Slope2x1x1Tip.dds",
    Cost: {
      SteelPlate: 6,
    },
  },
  LargeBlockArmorCorner2Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Corner2x1x1Base.dds",
    Cost: {
      SteelPlate: 10,
    },
  },
  LargeBlockArmorCorner2Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Corner2x1x1Tip.dds",
    Cost: {
      SteelPlate: 3,
    },
  },
  LargeBlockArmorInvCorner2Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\InvCorner2x1x1Base.dds",
    Cost: {
      SteelPlate: 22,
    },
  },
  LargeBlockArmorInvCorner2Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\InvCorner2x1x1Tip.dds",
    Cost: {
      SteelPlate: 16,
    },
  },
  LargeHeavyBlockArmorSlope2Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavySlope2x1x1Base.dds",
    Cost: {
      SteelPlate: 112,
      MetalGrid: 40,
    },
  },
  LargeHeavyBlockArmorSlope2Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavySlope2x1x1Tip.dds",
    Cost: {
      SteelPlate: 40,
      MetalGrid: 12,
    },
  },
  LargeHeavyBlockArmorCorner2Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyCorner2x1x1Base.dds",
    Cost: {
      SteelPlate: 55,
      MetalGrid: 15,
    },
  },
  LargeHeavyBlockArmorCorner2Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyCorner2x1x1Tip.dds",
    Cost: {
      SteelPlate: 19,
      MetalGrid: 6,
    },
  },
  LargeHeavyBlockArmorInvCorner2Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyInvCorner2x1x1Base.dds",
    Cost: {
      SteelPlate: 133,
      MetalGrid: 45,
    },
  },
  LargeHeavyBlockArmorInvCorner2Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyInvCorner2x1x1Tip.dds",
    Cost: {
      SteelPlate: 94,
      MetalGrid: 25,
    },
  },
  SmallBlockArmorSlope2Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Slope2x1x1Base.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorSlope2Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Slope2x1x1Tip.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorCorner2Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Corner2x1x1Base.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorCorner2Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Corner2x1x1Tip.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorInvCorner2Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\InvCorner2x1x1Base.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorInvCorner2Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\InvCorner2x1x1Tip.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallHeavyBlockArmorSlope2Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavySlope2x1x1Base.dds",
    Cost: {
      SteelPlate: 4,
      MetalGrid: 2,
    },
  },
  SmallHeavyBlockArmorSlope2Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavySlope2x1x1Tip.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallHeavyBlockArmorCorner2Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyCorner2x1x1Base.dds",
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  SmallHeavyBlockArmorCorner2Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyCorner2x1x1Tip.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallHeavyBlockArmorInvCorner2Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyInvCorner2x1x1Base.dds",
    Cost: {
      SteelPlate: 5,
      MetalGrid: 1,
    },
  },
  SmallHeavyBlockArmorInvCorner2Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyInvCorner2x1x1Tip.dds",
    Cost: {
      SteelPlate: 5,
      MetalGrid: 1,
    },
  },
  LargeArmorPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmorPanel.dds",
    Cost: {
      SteelPlate: 5,
    },
  },
  LargeArmorSlopedSidePanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmorSlopedSidePanel.dds",
    Cost: {
      SteelPlate: 3,
    },
  },
  LargeArmorSlopedPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmorSlopedPanel.dds",
    Cost: {
      SteelPlate: 6,
    },
  },
  LargeArmorHalfPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmorHalfPanel.dds",
    Cost: {
      SteelPlate: 3,
    },
  },
  LargeArmorQuarterPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightQuarterPanel.dds",
    Cost: {
      SteelPlate: 2,
    },
  },
  LargeArmor2x1SlopedPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1SlopedPanel.dds",
    Cost: {
      SteelPlate: 5,
    },
  },
  LargeArmor2x1SlopedPanelTipLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1SlopedPanelTip.dds",
    Cost: {
      SteelPlate: 5,
    },
  },
  LargeArmor2x1SlopedSideBasePanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1SlopedSideBasePanel.dds",
    Cost: {
      SteelPlate: 5,
    },
  },
  LargeArmor2x1SlopedSideTipPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1SlopeSideTipPanel.dds",
    Cost: {
      SteelPlate: 3,
    },
  },
  LargeArmor2x1SlopedSideBasePanelLightInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1SlopeSideBasePanelInv.dds",
    Cost: {
      SteelPlate: 5,
    },
  },
  LargeArmor2x1SlopedSideTipPanelLightInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1SlopeSideTipPanelInv.dds",
    Cost: {
      SteelPlate: 3,
    },
  },
  LargeArmorHalfSlopedPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmorHalfSlopedPanel.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeArmor2x1HalfSlopedPanelLightRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1HalfSlopedPanel.dds",
    Cost: {
      SteelPlate: 3,
    },
  },
  LargeArmor2x1HalfSlopedTipPanelLightRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1HalfSlopedTipPanel.dds",
    Cost: {
      SteelPlate: 3,
    },
  },
  LargeArmor2x1HalfSlopedPanelLightLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1HalfSlopedPanelInv.dds",
    Cost: {
      SteelPlate: 3,
    },
  },
  LargeArmor2x1HalfSlopedTipPanelLightLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1HalfSlopedTipPanelInv.dds",
    Cost: {
      SteelPlate: 3,
    },
  },
  LargeArmorPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmorPanel.dds",
    Cost: {
      SteelPlate: 15,
      MetalGrid: 5,
    },
  },
  LargeArmorSlopedSidePanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmorSlopedSidePanel.dds",
    Cost: {
      SteelPlate: 8,
      MetalGrid: 3,
    },
  },
  LargeArmorSlopedPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmorSlopedPanel.dds",
    Cost: {
      SteelPlate: 21,
      MetalGrid: 7,
    },
  },
  LargeArmorHalfPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmorHalfPanel.dds",
    Cost: {
      SteelPlate: 8,
      MetalGrid: 3,
    },
  },
  LargeArmorQuarterPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyQuarterPanel.dds",
    Cost: {
      SteelPlate: 5,
      MetalGrid: 2,
    },
  },
  LargeArmor2x1SlopedPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1SlopedPanel.dds",
    Cost: {
      SteelPlate: 18,
      MetalGrid: 6,
    },
  },
  LargeArmor2x1SlopedPanelTipHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1SlopedPanelTip.dds",
    Cost: {
      SteelPlate: 18,
      MetalGrid: 6,
    },
  },
  LargeArmor2x1SlopedSideBasePanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1SlopedSideBasePanel.dds",
    Cost: {
      SteelPlate: 12,
      MetalGrid: 4,
    },
  },
  LargeArmor2x1SlopedSideTipPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1SlopeSideTipPanel.dds",
    Cost: {
      SteelPlate: 6,
      MetalGrid: 2,
    },
  },
  LargeArmor2x1SlopedSideBasePanelHeavyInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1SlopeSideBasePanelInv.dds",
    Cost: {
      SteelPlate: 12,
      MetalGrid: 4,
    },
  },
  LargeArmor2x1SlopedSideTipPanelHeavyInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1SlopeSideTipPanelInv.dds",
    Cost: {
      SteelPlate: 6,
      MetalGrid: 2,
    },
  },
  LargeArmorHalfSlopedPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmorHalfSlopedPanel.dds",
    Cost: {
      SteelPlate: 9,
      MetalGrid: 3,
    },
  },
  LargeArmor2x1HalfSlopedPanelHeavyRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1HalfSlopedPanel.dds",
    Cost: {
      SteelPlate: 9,
      MetalGrid: 3,
    },
  },
  LargeArmor2x1HalfSlopedTipPanelHeavyRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1HalfSlopedTipPanel.dds",
    Cost: {
      SteelPlate: 9,
      MetalGrid: 3,
    },
  },
  LargeArmor2x1HalfSlopedPanelHeavyLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1HalfSlopedPanelInv.dds",
    Cost: {
      SteelPlate: 9,
      MetalGrid: 3,
    },
  },
  LargeArmor2x1HalfSlopedTipPanelHeavyLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1HalfSlopedTipPanelInv.dds",
    Cost: {
      SteelPlate: 9,
      MetalGrid: 3,
    },
  },
  SmallArmorPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmorPanel.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmorSlopedSidePanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmorSlopedSidePanel.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmorSlopedPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmorSlopedPanel.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmorHalfPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmorHalfPanel.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmorQuarterPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightQuarterPanel.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmor2x1SlopedPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1SlopedPanel.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmor2x1SlopedPanelTipLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1SlopedPanelTip.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmor2x1SlopedSideBasePanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1SlopedSideBasePanel.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmor2x1SlopedSideTipPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1SlopeSideTipPanel.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmor2x1SlopedSideBasePanelLightInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1SlopeSideBasePanelInv.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmor2x1SlopedSideTipPanelLightInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1SlopeSideTipPanelInv.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmorHalfSlopedPanelLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmorHalfSlopedPanel.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmor2x1HalfSlopedPanelLightRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1HalfSlopedPanel.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmor2x1HalfSlopedTipPanelLightRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1HalfSlopedTipPanel.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmor2x1HalfSlopedPanelLightLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1HalfSlopedPanelInv.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmor2x1HalfSlopedTipPanelLightLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightArmor2x1HalfSlopedTipPanelInv.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallArmorPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmorPanel.dds",
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  SmallArmorSlopedSidePanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmorSlopedSidePanel.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallArmorSlopedPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmorSlopedPanel.dds",
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  SmallArmorHalfPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmorHalfPanel.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallArmorQuarterPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyQuarterPanel.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallArmor2x1SlopedPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1SlopedPanel.dds",
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  SmallArmor2x1SlopedPanelTipHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1SlopedPanelTip.dds",
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  SmallArmor2x1SlopedSideBasePanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1SlopedSideBasePanel.dds",
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  SmallArmor2x1SlopedSideTipPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1SlopeSideTipPanel.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallArmor2x1SlopedSideBasePanelHeavyInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1SlopeSideBasePanelInv.dds",
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  SmallArmor2x1SlopedSideTipPanelHeavyInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1SlopeSideTipPanelInv.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallArmorHalfSlopedPanelHeavy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmorHalfSlopedPanel.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallArmor2x1HalfSlopedPanelHeavyRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1HalfSlopedPanel.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallArmor2x1HalfSlopedTipPanelHeavyRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1HalfSlopedTipPanel.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallArmor2x1HalfSlopedPanelHeavyLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1HalfSlopedPanelInv.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallArmor2x1HalfSlopedTipPanelHeavyLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyArmor2x1HalfSlopedTipPanelInv.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorCornerSquare: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CornerSquare.dds",
    Cost: {
      SteelPlate: 7,
    },
  },
  SmallBlockArmorCornerSquare: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CornerSquare.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeBlockHeavyArmorCornerSquare: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyCornerSquare.dds",
    Cost: {
      SteelPlate: 40,
      MetalGrid: 15,
    },
  },
  SmallBlockHeavyArmorCornerSquare: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyCornerSquare.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorCornerSquareInverted: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CornerSquareInverted.dds",
    Cost: {
      SteelPlate: 19,
    },
  },
  SmallBlockArmorCornerSquareInverted: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CornerSquareInverted.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeBlockHeavyArmorCornerSquareInverted: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyCornerSquareInverted.dds",
    Cost: {
      SteelPlate: 112,
      MetalGrid: 40,
    },
  },
  SmallBlockHeavyArmorCornerSquareInverted: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyCornerSquareInverted.dds",
    Cost: {
      SteelPlate: 4,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorHalfCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfCorner.dds",
    Cost: {
      SteelPlate: 6,
    },
  },
  SmallBlockArmorHalfCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfCorner.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeBlockHeavyArmorHalfCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyHalfCorner.dds",
    Cost: {
      SteelPlate: 40,
      MetalGrid: 12,
    },
  },
  SmallBlockHeavyArmorHalfCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyHalfCorner.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorHalfSlopeCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfSlopeCorner.dds",
    Cost: {
      SteelPlate: 2,
    },
  },
  SmallBlockArmorHalfSlopeCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfSlopeCorner.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeBlockHeavyArmorHalfSlopeCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyHalfSlopeCorner.dds",
    Cost: {
      SteelPlate: 12,
      MetalGrid: 4,
    },
  },
  SmallBlockHeavyArmorHalfSlopeCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyHalfSlopeCorner.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorHalfSlopeCornerInverted: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfSlopeCornerInverted.dds",
    Cost: {
      SteelPlate: 23,
    },
  },
  SmallBlockArmorHalfSlopeCornerInverted: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfSlopeCornerInverted.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeBlockHeavyArmorHalfSlopeCornerInverted: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyHalfSlopeCornerInverted.dds",
    Cost: {
      SteelPlate: 139,
      MetalGrid: 45,
    },
  },
  SmallBlockHeavyArmorHalfSlopeCornerInverted: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyHalfSlopeCornerInverted.dds",
    Cost: {
      SteelPlate: 5,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorHalfSlopedCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfSlopedCorner.dds",
    Cost: {
      SteelPlate: 11,
    },
  },
  SmallBlockArmorHalfSlopedCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfSlopedCorner.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeBlockHeavyArmorHalfSlopedCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyHalfSlopedCorner.dds",
    Cost: {
      SteelPlate: 45,
      MetalGrid: 5,
    },
  },
  SmallBlockHeavyArmorHalfSlopedCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyHalfSlopedCorner.dds",
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorHalfSlopedCornerBase: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfSlopedCornerBase.dds",
    Cost: {
      SteelPlate: 11,
    },
  },
  SmallBlockArmorHalfSlopedCornerBase: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfSlopedCornerBase.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeBlockHeavyArmorHalfSlopedCornerBase: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyHalfSlopedCornerBase.dds",
    Cost: {
      SteelPlate: 45,
      MetalGrid: 15,
    },
  },
  SmallBlockHeavyArmorHalfSlopedCornerBase: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyHalfSlopedCornerBase.dds",
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorHalfSlopeInverted: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfSlopeInverted.dds",
    Cost: {
      SteelPlate: 22,
    },
  },
  SmallBlockArmorHalfSlopeInverted: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfSlopeInverted.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeBlockHeavyArmorHalfSlopeInverted: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyHalfSlopeInverted.dds",
    Cost: {
      SteelPlate: 133,
      MetalGrid: 45,
    },
  },
  SmallBlockHeavyArmorHalfSlopeInverted: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyHalfSlopeInverted.dds",
    Cost: {
      SteelPlate: 5,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorSlopedCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SlopedCorner.dds",
    Cost: {
      SteelPlate: 13,
    },
  },
  SmallBlockArmorSlopedCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SlopedCorner.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeBlockHeavyArmorSlopedCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavySlopedCorner.dds",
    Cost: {
      SteelPlate: 75,
      MetalGrid: 25,
    },
  },
  SmallBlockHeavyArmorSlopedCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavySlopedCorner.dds",
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorSlopedCornerBase: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SlopedCornerBase.dds",
    Cost: {
      SteelPlate: 20,
    },
  },
  SmallBlockArmorSlopedCornerBase: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SlopedCornerBase.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeBlockHeavyArmorSlopedCornerBase: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavySlopedCornerBase.dds",
    Cost: {
      SteelPlate: 127,
      MetalGrid: 40,
    },
  },
  SmallBlockHeavyArmorSlopedCornerBase: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavySlopedCornerBase.dds",
    Cost: {
      SteelPlate: 5,
      MetalGrid: 1,
    },
  },
  LargeBlockArmorSlopedCornerTip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SlopedCornerTip.dds",
    Cost: {
      SteelPlate: 5,
    },
  },
  SmallBlockArmorSlopedCornerTip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SlopedCornerTip.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeBlockHeavyArmorSlopedCornerTip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavySlopedCornerTip.dds",
    Cost: {
      SteelPlate: 23,
      MetalGrid: 6,
    },
  },
  SmallBlockHeavyArmorSlopedCornerTip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavySlopedCornerTip.dds",
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallProgrammableBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ProgrammingBlock.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 2,
      LargeTube: 2,
      Motor: 1,
      Display: 1,
      Computer: 2,
    },
  },
  LargeProjector: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Projector.dds",
    Cost: {
      SteelPlate: 21,
      Construction: 4,
      LargeTube: 2,
      Motor: 1,
      Computer: 2,
    },
  },
  SmallProjector: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Projector.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 2,
      LargeTube: 2,
      Motor: 1,
      Computer: 2,
    },
  },
  SmallBlockSensor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Sensor.dds",
    Cost: {
      InteriorPlate: 5,
      Construction: 5,
      Computer: 6,
      RadioCommunication: 4,
      Detector: 6,
      SteelPlate: 2,
    },
  },
  LargeBlockSensor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Sensor.dds",
    Cost: {
      InteriorPlate: 5,
      Construction: 5,
      Computer: 6,
      RadioCommunication: 4,
      Detector: 6,
      SteelPlate: 2,
    },
  },
  TargetDummy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\TargetDummy.dds",
    Cost: {
      SteelPlate: 15,
      SmallTube: 10,
      Motor: 2,
      Computer: 4,
      Display: 1,
    },
  },
  SmallBlockSoundBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SoundBlock.dds",
    Cost: {
      InteriorPlate: 4,
      Construction: 6,
      Computer: 3,
    },
  },
  LargeBlockSoundBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SoundBlock.dds",
    Cost: {
      InteriorPlate: 4,
      Construction: 6,
      Computer: 3,
    },
  },
  ButtonPanelLarge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ButtonPanel.dds",
    Cost: {
      InteriorPlate: 10,
      Construction: 20,
      Computer: 20,
    },
  },
  ButtonPanelSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Button.dds",
    Cost: {
      InteriorPlate: 2,
      Construction: 2,
      Computer: 1,
    },
  },
  TimerBlockLarge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\TimerBlock.dds",
    Cost: {
      InteriorPlate: 6,
      Construction: 30,
      Computer: 5,
    },
  },
  TimerBlockSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\TimerBlock.dds",
    Cost: {
      InteriorPlate: 2,
      Construction: 3,
      Computer: 1,
    },
  },
  LargeProgrammableBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ProgrammingBlock.dds",
    Cost: {
      SteelPlate: 21,
      Construction: 4,
      LargeTube: 2,
      Motor: 1,
      Display: 1,
      Computer: 2,
    },
  },
  LargeTurretControlBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\TurretControlBlockLarge.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 30,
      Detector: 20,
      Motor: 4,
      Display: 6,
      Computer: 20,
      SteelPlate: 20,
    },
  },
  SmallTurretControlBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\TurretControlBlockSmall.dds",
    Cost: {
      InteriorPlate: 4,
      Construction: 10,
      Detector: 4,
      Motor: 2,
      Display: 1,
      Computer: 10,
      SteelPlate: 4,
    },
  },
  LargeBlockRadioAntenna: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\antenna.dds",
    Cost: {
      SteelPlate: 80,
      LargeTube: 40,
      SmallTube: 60,
      Construction: 30,
      Computer: 8,
      RadioCommunication: 40,
    },
  },
  LargeBlockBeacon: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\beacon.dds",
    Cost: {
      SteelPlate: 80,
      Construction: 30,
      LargeTube: 20,
      Computer: 10,
      RadioCommunication: 40,
    },
  },
  SmallBlockBeacon: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\beacon.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 1,
      SmallTube: 1,
      Computer: 1,
      RadioCommunication: 4,
    },
  },
  SmallBlockRadioAntenna: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\antenna.dds",
    Cost: {
      SteelPlate: 1,
      SmallTube: 1,
      Construction: 2,
      Computer: 1,
      RadioCommunication: 4,
    },
  },
  LargeBlockRemoteControl: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RemoteControl.dds",
    Cost: {
      InteriorPlate: 10,
      Construction: 10,
      Motor: 1,
      Computer: 15,
    },
  },
  SmallBlockRemoteControl: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RemoteControl.dds",
    Cost: {
      InteriorPlate: 2,
      Construction: 1,
      Motor: 1,
      Computer: 1,
    },
  },
  LargeBlockLaserAntenna: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LaserAntenna.dds",
    Cost: {
      SteelPlate: 50,
      Construction: 40,
      Motor: 16,
      Detector: 30,
      RadioCommunication: 20,
      Superconductor: 100,
      Computer: 50,
      BulletproofGlass: 4,
    },
  },
  SmallBlockLaserAntenna: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LaserAntennaSmall.dds",
    Cost: {
      SteelPlate: 10,
      SmallTube: 10,
      Construction: 10,
      Motor: 5,
      RadioCommunication: 5,
      Superconductor: 10,
      Computer: 30,
      BulletproofGlass: 2,
    },
  },
  ControlPanel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Control Panel.dds",
    Cost: {
      SteelPlate: 1,
      Construction: 1,
      Computer: 1,
      Display: 1,
    },
  },
  SmallControlPanel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Control Panel.dds",
    Cost: {
      SteelPlate: 1,
      Construction: 1,
      Computer: 1,
      Display: 1,
    },
  },
  LargeBlockCockpit: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Cockpit.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
      Motor: 2,
      Computer: 100,
      Display: 10,
    },
  },
  LargeBlockCockpitSeat: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CockpitSmall.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 20,
      Motor: 1,
      Display: 8,
      Computer: 100,
      BulletproofGlass: 60,
    },
  },
  SmallBlockCockpit: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CockpitSmall.dds",
    Cost: {
      SteelPlate: 10,
      Construction: 10,
      Motor: 1,
      Display: 5,
      Computer: 15,
      BulletproofGlass: 30,
    },
  },
  DBSmallBlockFighterCockpit: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\FighterCockpit.dds",
    Cost: {
      Construction: 20,
      Motor: 1,
      SteelPlate: 20,
      MetalGrid: 10,
      InteriorPlate: 15,
      Display: 4,
      Computer: 20,
      BulletproofGlass: 40,
    },
  },
  CockpitOpen: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CockpitOpen.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
      Motor: 2,
      Computer: 100,
      Display: 4,
    },
  },
  RoverCockpit: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RoverCockpit.dds",
    Cost: {
      InteriorPlate: 30,
      Construction: 25,
      Motor: 2,
      Computer: 20,
      Display: 4,
    },
  },
  LargeBlockGyro: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\gyro.dds",
    Cost: {
      SteelPlate: 600,
      Construction: 40,
      LargeTube: 4,
      MetalGrid: 50,
      Motor: 4,
      Computer: 5,
    },
  },
  SmallBlockGyro: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\gyro.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 5,
      LargeTube: 1,
      Motor: 2,
      Computer: 3,
    },
  },
  OpenCockpitSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\OpenCockpitSmall.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
      Motor: 1,
      Computer: 15,
      Display: 2,
    },
  },
  OpenCockpitLarge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\OpenCockpitLarge.dds",
    Cost: {
      InteriorPlate: 30,
      Construction: 30,
      Motor: 2,
      Computer: 100,
      Display: 6,
    },
  },
  LargeBlockDesk: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Desk.dds",
    Cost: {
      InteriorPlate: 30,
      Construction: 30,
    },
  },
  LargeBlockDeskCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\DeskCorner.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
    },
  },
  LargeBlockDeskChairless: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\DeskChairless.dds",
    Cost: {
      InteriorPlate: 30,
      Construction: 30,
    },
  },
  LargeBlockDeskChairlessCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\DeskChairlessCorner.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
    },
  },
  LargeBlockKitchen: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Kitchen.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 30,
      LargeTube: 6,
      Motor: 6,
      BulletproofGlass: 4,
    },
  },
  LargeBlockBed: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Bed.dds",
    Cost: {
      InteriorPlate: 30,
      Construction: 30,
      SmallTube: 8,
      BulletproofGlass: 10,
    },
  },
  LargeBlockLockerRoom: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LockerRoom.dds",
    Cost: {
      InteriorPlate: 30,
      Construction: 30,
      Display: 4,
      BulletproofGlass: 10,
    },
  },
  LargeBlockLockerRoomCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LockerRoomCorner.dds",
    Cost: {
      InteriorPlate: 25,
      Construction: 30,
      Display: 4,
      BulletproofGlass: 10,
    },
  },
  LargeBlockPlanters: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Planters.dds",
    Cost: {
      InteriorPlate: 10,
      Construction: 20,
      SmallTube: 8,
      BulletproofGlass: 8,
    },
  },
  LargeBlockCouch: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Couch.dds",
    Cost: {
      InteriorPlate: 30,
      Construction: 30,
    },
  },
  LargeBlockCouchCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CouchCorner.dds",
    Cost: {
      InteriorPlate: 35,
      Construction: 35,
    },
  },
  LargeBlockLockers: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Lockers.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
      Display: 3,
      Computer: 2,
    },
  },
  LargeBlockBathroomOpen: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BathroomOpen.dds",
    Cost: {
      InteriorPlate: 30,
      Construction: 30,
      SmallTube: 8,
      Motor: 4,
      LargeTube: 2,
    },
  },
  LargeBlockBathroom: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Bathroom.dds",
    Cost: {
      InteriorPlate: 30,
      Construction: 40,
      SmallTube: 8,
      Motor: 4,
      LargeTube: 2,
    },
  },
  LargeBlockToilet: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Toilet.dds",
    Cost: {
      InteriorPlate: 10,
      Construction: 15,
      SmallTube: 2,
      Motor: 2,
      LargeTube: 1,
    },
  },
  LargeBlockConsole: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Console.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 30,
      Computer: 8,
      Display: 10,
    },
  },
  SmallBlockCockpitIndustrial: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CockpitIndustrial.dds",
    Cost: {
      SteelPlate: 10,
      Construction: 20,
      MetalGrid: 10,
      Motor: 2,
      Display: 6,
      Computer: 20,
      BulletproofGlass: 60,
      SmallTube: 10,
    },
  },
  LargeBlockCockpitIndustrial: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeCockpitIndustrial.dds",
    Cost: {
      SteelPlate: 20,
      Construction: 30,
      MetalGrid: 15,
      Motor: 2,
      Display: 10,
      Computer: 60,
      BulletproofGlass: 80,
      SmallTube: 10,
    },
  },
  FoodDispenser: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\FoodDispenser.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 10,
      Motor: 4,
      Display: 10,
      Computer: 10,
    },
  },
  Jukebox: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Jukebox.dds",
    Cost: {
      InteriorPlate: 15,
      Construction: 10,
      Computer: 4,
      Display: 4,
    },
  },
  LabEquipment: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LabEquipment.dds",
    Cost: {
      InteriorPlate: 15,
      Construction: 15,
      Motor: 1,
      BulletproofGlass: 4,
    },
  },
  Shower: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Shower.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
      SmallTube: 12,
      BulletproofGlass: 8,
    },
  },
  WindowWall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WindowWall.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 10,
      BulletproofGlass: 10,
    },
  },
  WindowWallLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WindowWallLeft.dds",
    Cost: {
      SteelPlate: 10,
      Construction: 10,
      BulletproofGlass: 8,
    },
  },
  WindowWallRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WindowWallRight.dds",
    Cost: {
      SteelPlate: 10,
      Construction: 10,
      BulletproofGlass: 8,
    },
  },
  MedicalStation: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\MedicalStation.dds",
    Cost: {
      InteriorPlate: 15,
      Construction: 15,
      Motor: 2,
      Medical: 1,
      Display: 2,
    },
  },
  TransparentLCDLarge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\TransparentLCD.dds",
    Cost: {
      Construction: 8,
      Computer: 6,
      Display: 10,
      BulletproofGlass: 10,
    },
  },
  TransparentLCDSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\TransparentLCD.dds",
    Cost: {
      Construction: 4,
      Computer: 4,
      Display: 3,
      BulletproofGlass: 1,
    },
  },
  Catwalk: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\GratedCatwalk.dds",
    Cost: {
      Construction: 16,
      Girder: 4,
      SmallTube: 20,
    },
  },
  CatwalkCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\GratedCatwalkCorner.dds",
    Cost: {
      Construction: 24,
      Girder: 4,
      SmallTube: 32,
    },
  },
  CatwalkStraight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\GratedCatwalkStraight.dds",
    Cost: {
      Construction: 24,
      Girder: 4,
      SmallTube: 32,
    },
  },
  CatwalkWall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\GratedCatwalkWall.dds",
    Cost: {
      Construction: 20,
      Girder: 4,
      SmallTube: 26,
    },
  },
  CatwalkRailingEnd: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\GratedCatwalkRailingEnd.dds",
    Cost: {
      Construction: 28,
      Girder: 4,
      SmallTube: 38,
    },
  },
  CatwalkRailingHalfRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\GratedCatwalkRailingHalfRight.dds",
    Cost: {
      Construction: 28,
      Girder: 4,
      SmallTube: 36,
    },
  },
  CatwalkRailingHalfLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\GratedCatwalkRailingHalfLeft.dds",
    Cost: {
      Construction: 28,
      Girder: 4,
      SmallTube: 36,
    },
  },
  GratedStairs: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\GratedStairs.dds",
    Cost: {
      Construction: 22,
      SmallTube: 12,
      InteriorPlate: 16,
    },
  },
  GratedHalfStairs: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\GratedHalfStairs.dds",
    Cost: {
      Construction: 20,
      SmallTube: 6,
      InteriorPlate: 8,
    },
  },
  GratedHalfStairsMirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\GratedHalfStairsMirrored.dds",
    Cost: {
      Construction: 20,
      SmallTube: 6,
      InteriorPlate: 8,
    },
  },
  RailingStraight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RailingStraight.dds",
    Cost: {
      Construction: 8,
      SmallTube: 6,
    },
  },
  RailingDouble: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RailingDouble.dds",
    Cost: {
      Construction: 16,
      SmallTube: 12,
    },
  },
  RailingCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RailingCorner.dds",
    Cost: {
      Construction: 16,
      SmallTube: 12,
    },
  },
  RailingDiagonal: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RailingDiagonal.dds",
    Cost: {
      Construction: 12,
      SmallTube: 9,
    },
  },
  RailingHalfRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RailingHalfRight.dds",
    Cost: {
      Construction: 8,
      SmallTube: 4,
    },
  },
  RailingHalfLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RailingHalfLeft.dds",
    Cost: {
      Construction: 8,
      SmallTube: 4,
    },
  },
  RotatingLightLarge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RotatingLight.dds",
    Cost: {
      Construction: 3,
      Motor: 1,
    },
  },
  RotatingLightSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RotatingLight.dds",
    Cost: {
      Construction: 3,
      Motor: 1,
    },
  },
  Freight1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Freight_I.dds",
    Cost: {
      InteriorPlate: 6,
      Construction: 8,
    },
  },
  Freight2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Freight_II.dds",
    Cost: {
      InteriorPlate: 12,
      Construction: 16,
    },
  },
  Freight3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Freight_III.dds",
    Cost: {
      InteriorPlate: 18,
      Construction: 24,
    },
  },
  Door: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\door.dds",
    Cost: {
      InteriorPlate: 10,
      Construction: 40,
      SmallTube: 4,
      Motor: 2,
      Display: 1,
      Computer: 2,
      SteelPlate: 8,
    },
  },
  SmallDoor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SmallDoor.dds",
    Cost: {
      InteriorPlate: 8,
      Construction: 30,
      SmallTube: 4,
      Motor: 2,
      Display: 1,
      Computer: 2,
      SteelPlate: 6,
    },
  },
  AirtightHangarDoor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HangarDoor.dds",
    Cost: {
      SteelPlate: 350,
      Construction: 40,
      SmallTube: 40,
      Motor: 16,
      Computer: 2,
    },
  },
  LargeBlockSlideDoor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SlideDoor.dds",
    Cost: {
      SteelPlate: 20,
      Construction: 40,
      SmallTube: 4,
      Motor: 4,
      Display: 1,
      Computer: 2,
      BulletproofGlass: 15,
    },
  },
  ArmorCenter: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ArmorCenter.dds",
    Cost: {
      SteelPlate: 140,
    },
  },
  ArmorCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ArmorCorner.dds",
    Cost: {
      SteelPlate: 120,
    },
  },
  ArmorInvCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BlastDoorCornerInverted.dds",
    Cost: {
      SteelPlate: 135,
    },
  },
  ArmorSide: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ArmorSide.dds",
    Cost: {
      SteelPlate: 130,
    },
  },
  SmallArmorCenter: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ArmorCenter.dds",
    Cost: {
      SteelPlate: 5,
    },
  },
  SmallArmorCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ArmorCorner.dds",
    Cost: {
      SteelPlate: 5,
    },
  },
  SmallArmorInvCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BlastDoorCornerInverted.dds",
    Cost: {
      SteelPlate: 5,
    },
  },
  SmallArmorSide: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ArmorSide.dds",
    Cost: {
      SteelPlate: 5,
    },
  },
  StoreBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\StoreBlock.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 20,
      Motor: 6,
      Display: 4,
      Computer: 10,
    },
  },
  SafeZoneBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SafeZoneBlock.dds",
    Cost: {
      SteelPlate: 800,
      Construction: 180,
      GravityGenerator: 10,
      ZoneChip: 5,
      MetalGrid: 80,
      Computer: 120,
    },
  },
  ContractBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ContractsBlock.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 20,
      Motor: 6,
      Display: 4,
      Computer: 10,
    },
  },
  VendingMachine: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\VendingMachine.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 10,
      Motor: 4,
      Display: 4,
      Computer: 10,
    },
  },
  AtmBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ATM.dds",
    Cost: {
      SteelPlate: 20,
      Construction: 20,
      Motor: 2,
      Computer: 10,
      Display: 4,
    },
  },
  LargeBlockBatteryBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Battery.dds",
    Cost: {
      SteelPlate: 80,
      Construction: 30,
      PowerCell: 80,
      Computer: 25,
    },
  },
  SmallBlockBatteryBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Battery.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 5,
      PowerCell: 20,
      Computer: 2,
    },
  },
  SmallBlockSmallBatteryBlock: {
    IconPath: "Textures/GUI/Icons/Cubes/Battery1x1.dds",
    Cost: {
      SteelPlate: 4,
      Construction: 2,
      PowerCell: 2,
      Computer: 2,
    },
  },
  SmallBlockSmallGenerator: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\nuclear_reactor.dds",
    Cost: {
      SteelPlate: 3,
      Construction: 10,
      MetalGrid: 2,
      LargeTube: 1,
      Reactor: 3,
      Motor: 1,
      Computer: 10,
    },
  },
  SmallBlockLargeGenerator: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\nuclear_reactor_large.dds",
    Cost: {
      SteelPlate: 60,
      Construction: 9,
      MetalGrid: 9,
      LargeTube: 3,
      Reactor: 95,
      Motor: 5,
      Computer: 25,
    },
  },
  LargeBlockSmallGenerator: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\nuclear_reactor.dds",
    Cost: {
      SteelPlate: 80,
      Construction: 40,
      MetalGrid: 4,
      LargeTube: 8,
      Reactor: 100,
      Motor: 6,
      Computer: 25,
    },
  },
  LargeBlockLargeGenerator: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\nuclear_reactor_large.dds",
    Cost: {
      SteelPlate: 1000,
      Construction: 70,
      MetalGrid: 40,
      LargeTube: 40,
      Superconductor: 100,
      Reactor: 2000,
      Motor: 20,
      Computer: 75,
    },
  },
  LargeHydrogenEngine: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenEngineLarge.dds",
    Cost: {
      SteelPlate: 100,
      Construction: 70,
      LargeTube: 12,
      SmallTube: 20,
      Motor: 12,
      Computer: 4,
      PowerCell: 1,
    },
  },
  SmallHydrogenEngine: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenEngineSmall.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 20,
      LargeTube: 4,
      SmallTube: 6,
      Motor: 4,
      Computer: 1,
      PowerCell: 1,
    },
  },
  LargeBlockWindTurbine: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WindTurbine.dds",
    Cost: {
      InteriorPlate: 40,
      Motor: 8,
      Construction: 20,
      Girder: 24,
      Computer: 2,
    },
  },
  LargeBlockSolarPanel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SolarPanel.dds",
    Cost: {
      SteelPlate: 4,
      Construction: 14,
      Girder: 12,
      Computer: 4,
      SolarCell: 32,
      BulletproofGlass: 4,
    },
  },
  SmallBlockSolarPanel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SolarPanel.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 2,
      Girder: 4,
      Computer: 1,
      SolarCell: 8,
      BulletproofGlass: 1,
    },
  },
  Monolith: {
    IconPath: "Textures\\GUI\\Icons\\Fake.dds",
    Cost: {
      SteelPlate: 130,
      Superconductor: 130,
    },
  },
  Stereolith: {
    IconPath: "Textures\\GUI\\Icons\\Fake.dds",
    Cost: {
      SteelPlate: 130,
      Superconductor: 130,
    },
  },
  DeadAstronaut: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\DeadAstronaut.dds",
    Cost: {
      SteelPlate: 13,
      Superconductor: 13,
    },
  },
  LargeDeadAstronaut: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\DeadAstronaut.dds",
    Cost: {
      SteelPlate: 13,
      Superconductor: 13,
    },
  },
  LargeBlockRadioAntennaDish: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AntennaDish.dds",
    Cost: {
      Construction: 40,
      Girder: 120,
      SteelPlate: 80,
      Computer: 8,
      RadioCommunication: 40,
    },
  },
  LargeBlockGate: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Gate.dds",
    Cost: {
      SteelPlate: 800,
      Construction: 100,
      SmallTube: 100,
      Motor: 20,
      Computer: 10,
    },
  },
  LargeBlockOffsetDoor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\OffsetDoor.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 35,
      SmallTube: 4,
      Motor: 4,
      Display: 1,
      Computer: 2,
      BulletproofGlass: 6,
    },
  },
  DeadBody01: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\DeadBody_1.dds",
    Cost: {
      BulletproofGlass: 1,
      RadioCommunication: 1,
      Display: 1,
    },
  },
  DeadBody02: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\DeadBody_2.dds",
    Cost: {
      BulletproofGlass: 1,
      RadioCommunication: 1,
      Display: 1,
    },
  },
  DeadBody03: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\DeadBody_3.dds",
    Cost: {
      BulletproofGlass: 1,
      RadioCommunication: 1,
      Display: 1,
    },
  },
  DeadBody04: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\DeadBody_4.dds",
    Cost: {
      BulletproofGlass: 1,
      RadioCommunication: 1,
      Display: 1,
    },
  },
  DeadBody05: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\DeadBody_5.dds",
    Cost: {
      BulletproofGlass: 1,
      RadioCommunication: 1,
      Display: 1,
    },
  },
  DeadBody06: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\DeadBody_6.dds",
    Cost: {
      BulletproofGlass: 1,
      RadioCommunication: 1,
      Display: 1,
    },
  },
  GravityGenerator: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\gravity_generator.dds",
    Cost: {
      SteelPlate: 150,
      GravityGenerator: 6,
      Construction: 60,
      LargeTube: 4,
      Motor: 6,
      Computer: 40,
    },
  },
  GravityGeneratorSphere: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\gravity_generator_spherical.dds",
    Cost: {
      SteelPlate: 150,
      GravityGenerator: 6,
      Construction: 60,
      LargeTube: 4,
      Motor: 6,
      Computer: 40,
    },
  },
  VirtualMassLarge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ArtificialMass.dds",
    Cost: {
      SteelPlate: 90,
      Superconductor: 20,
      Construction: 30,
      Computer: 20,
      GravityGenerator: 9,
    },
  },
  VirtualMassSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ArtificialMass.dds",
    Cost: {
      SteelPlate: 3,
      Superconductor: 2,
      Construction: 2,
      Computer: 2,
      GravityGenerator: 1,
    },
  },
  SpaceBallLarge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SpaceBall.dds",
    Cost: {
      SteelPlate: 225,
      Construction: 30,
      Computer: 20,
      GravityGenerator: 3,
    },
  },
  SpaceBallSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SpaceBall.dds",
    Cost: {
      SteelPlate: 70,
      Construction: 10,
      Computer: 7,
      GravityGenerator: 1,
    },
  },
  LargeBlockMagneticPlate: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\MagneticPlateLarge.dds",
    Cost: {
      SteelPlate: 450,
      Construction: 60,
      Motor: 20,
    },
  },
  SmallBlockMagneticPlate: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\MagneticPlate.dds",
    Cost: {
      SteelPlate: 6,
      Construction: 15,
      Motor: 3,
    },
  },
  LargeBlockLargeIndustrialContainer: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CargoContainer.dds",
    Cost: {
      InteriorPlate: 360,
      Construction: 80,
      MetalGrid: 24,
      SmallTube: 60,
      Motor: 20,
      Display: 1,
      Computer: 8,
    },
  },
  VerticalButtonPanelLarge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\VerticalButtonPanel.dds",
    Cost: {
      InteriorPlate: 5,
      Construction: 10,
      Computer: 5,
    },
  },
  VerticalButtonPanelSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\VerticalButtonPanel.dds",
    Cost: {
      InteriorPlate: 5,
      Construction: 10,
      Computer: 5,
    },
  },
  LargeBlockConveyorPipeSeamless: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PipeSeamless.dds",
    Cost: {
      InteriorPlate: 14,
      Construction: 20,
      SmallTube: 12,
      Motor: 6,
    },
  },
  LargeBlockConveyorPipeCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PipeCorner.dds",
    Cost: {
      InteriorPlate: 14,
      Construction: 20,
      SmallTube: 12,
      Motor: 6,
    },
  },
  LargeBlockConveyorPipeJunction: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PipeNode.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 30,
      SmallTube: 20,
      Motor: 6,
    },
  },
  LargeBlockConveyorPipeIntersection: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PipeJunction.dds",
    Cost: {
      InteriorPlate: 18,
      Construction: 20,
      SmallTube: 16,
      Motor: 6,
    },
  },
  LargeBlockConveyorPipeFlange: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PipeFlange.dds",
    Cost: {
      InteriorPlate: 14,
      Construction: 20,
      SmallTube: 12,
      Motor: 6,
    },
  },
  LargeBlockConveyorPipeEnd: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PipeEnd.dds",
    Cost: {
      InteriorPlate: 14,
      Construction: 20,
      SmallTube: 12,
      Motor: 6,
    },
  },
  LargeHydrogenTankIndustrial: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenTankIndustrial.dds",
    Cost: {
      SteelPlate: 280,
      LargeTube: 80,
      SmallTube: 60,
      Computer: 8,
      Construction: 40,
    },
  },
  LargeAssemblerIndustrial: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AdvancedAssemblerIndustrial.dds",
    Cost: {
      SteelPlate: 140,
      Construction: 80,
      Motor: 20,
      Display: 10,
      MetalGrid: 10,
      Computer: 160,
    },
  },
  LargeRefineryIndustrial: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AdvancedRefinery.dds",
    Cost: {
      SteelPlate: 1200,
      Construction: 40,
      LargeTube: 20,
      Motor: 16,
      MetalGrid: 20,
      Computer: 20,
    },
  },
  LargeBlockCylindricalColumn: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CylindricalColumnLarge.dds",
    Cost: {
      InteriorPlate: 25,
      Construction: 10,
    },
  },
  SmallBlockCylindricalColumn: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CylindricalColumnSmall.dds",
    Cost: {
      InteriorPlate: 5,
      Construction: 3,
    },
  },
  LargeBlockConveyorSorterIndustrial: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConveyorSorterReskin.dds",
    Cost: {
      InteriorPlate: 50,
      Construction: 120,
      SmallTube: 50,
      Computer: 20,
      Motor: 2,
    },
  },
  LargeGridBeamBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderStraight.dds",
    Cost: {
      SteelPlate: 25,
    },
  },
  LargeGridBeamBlockSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderSlope.dds",
    Cost: {
      SteelPlate: 13,
    },
  },
  LargeGridBeamBlockRound: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderRound.dds",
    Cost: {
      SteelPlate: 13,
    },
  },
  LargeGridBeamBlockSlope2x1Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderSlope2x1Base.dds",
    Cost: {
      SteelPlate: 19,
    },
  },
  LargeGridBeamBlockSlope2x1Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderSlope2x1Tip.dds",
    Cost: {
      SteelPlate: 7,
    },
  },
  LargeGridBeamBlockHalf: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderStraightHalf.dds",
    Cost: {
      SteelPlate: 12,
    },
  },
  LargeGridBeamBlockHalfSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderSlopeHalf.dds",
    Cost: {
      SteelPlate: 7,
    },
  },
  LargeGridBeamBlockEnd: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderStraightEnd.dds",
    Cost: {
      SteelPlate: 25,
    },
  },
  LargeGridBeamBlockJunction: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderJunction.dds",
    Cost: {
      SteelPlate: 25,
    },
  },
  LargeGridBeamBlockTJunction: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderT-Junction.dds",
    Cost: {
      SteelPlate: 25,
    },
  },
  SmallGridBeamBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderStraight.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallGridBeamBlockSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderSlope.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallGridBeamBlockRound: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderRound.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallGridBeamBlockSlope2x1Base: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderSlope2x1Base.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallGridBeamBlockSlope2x1Tip: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderSlope2x1Tip.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallGridBeamBlockHalf: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderStraightHalf.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallGridBeamBlockHalfSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderSlopeHalf.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallGridBeamBlockEnd: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderStraightEnd.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallGridBeamBlockJunction: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderJunction.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallGridBeamBlockTJunction: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGirderT-Junction.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeBlockLargeHydrogenThrustIndustrial: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenThrusterIndustrialLarge.dds",
    Cost: {
      SteelPlate: 150,
      Construction: 180,
      MetalGrid: 250,
      LargeTube: 40,
    },
  },
  LargeBlockSmallHydrogenThrustIndustrial: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenThrusterIndustrialSmall.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 60,
      MetalGrid: 40,
      LargeTube: 8,
    },
  },
  SmallBlockLargeHydrogenThrustIndustrial: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenThrusterIndustrialLarge.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 30,
      MetalGrid: 22,
      LargeTube: 10,
    },
  },
  SmallBlockSmallHydrogenThrustIndustrial: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenThrusterIndustrialSmall.dds",
    Cost: {
      SteelPlate: 7,
      Construction: 15,
      MetalGrid: 4,
      LargeTube: 2,
    },
  },
  Passage: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\passage.dds",
    Cost: {
      InteriorPlate: 74,
      Construction: 20,
      SmallTube: 48,
    },
  },
  Passage2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassageStraight.dds",
    Cost: {
      InteriorPlate: 74,
      Construction: 20,
      SmallTube: 48,
    },
  },
  Passage2Wall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassageWall.dds",
    Cost: {
      InteriorPlate: 50,
      Construction: 14,
      SmallTube: 32,
    },
  },
  LargeStairs: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\stairs.dds",
    Cost: {
      InteriorPlate: 50,
      Construction: 30,
    },
  },
  LargeRamp: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ramp.dds",
    Cost: {
      InteriorPlate: 70,
      Construction: 16,
    },
  },
  LargeSteelCatwalk: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\steel_catwalk.dds",
    Cost: {
      InteriorPlate: 27,
      Construction: 5,
      SmallTube: 20,
    },
  },
  LargeSteelCatwalk2Sides: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SteelCatwalk_2Sides.dds",
    Cost: {
      InteriorPlate: 32,
      Construction: 7,
      SmallTube: 25,
    },
  },
  LargeSteelCatwalkCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SteelCatwalk_Corner.dds",
    Cost: {
      InteriorPlate: 32,
      Construction: 7,
      SmallTube: 25,
    },
  },
  LargeSteelCatwalkPlate: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SteelCatwalk_Plate.dds",
    Cost: {
      InteriorPlate: 23,
      Construction: 7,
      SmallTube: 17,
    },
  },
  LargeCoverWall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\cover_wall_full.dds",
    Cost: {
      SteelPlate: 4,
      Construction: 10,
    },
  },
  LargeCoverWallHalf: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\cover_wall.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 6,
    },
  },
  LargeBlockInteriorWall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\interior_wall.dds",
    Cost: {
      InteriorPlate: 25,
      Construction: 10,
    },
  },
  LargeInteriorPillar: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\interior_pillar.dds",
    Cost: {
      InteriorPlate: 25,
      Construction: 10,
      SmallTube: 4,
    },
  },
  PassengerSeatLarge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassengerSeat.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
    },
  },
  PassengerSeatSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassengerSeat.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
    },
  },
  PassengerSeatSmallNew: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassengerSeat.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
    },
  },
  PassengerSeatSmallOffset: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassengerSeat.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
    },
  },
  Ladder2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Ladder.dds",
    Cost: {
      InteriorPlate: 10,
      Construction: 20,
      SmallTube: 10,
    },
  },
  LadderSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Ladder.dds",
    Cost: {
      InteriorPlate: 10,
      Construction: 20,
      SmallTube: 10,
    },
  },
  SmallTextPanel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\TextPanel.dds",
    Cost: {
      InteriorPlate: 1,
      Construction: 4,
      Computer: 4,
      Display: 3,
      BulletproofGlass: 1,
    },
  },
  SmallLCDPanelWide: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LCDPanelWide.dds",
    Cost: {
      InteriorPlate: 1,
      Construction: 8,
      Computer: 8,
      Display: 6,
      BulletproofGlass: 2,
    },
  },
  SmallLCDPanel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LCDPanel.dds",
    Cost: {
      InteriorPlate: 1,
      Construction: 4,
      Computer: 4,
      Display: 3,
      BulletproofGlass: 2,
    },
  },
  LargeBlockCorner_LCD_1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Corner_LCD_1.dds",
    Cost: {
      Construction: 5,
      Computer: 3,
      Display: 1,
    },
  },
  LargeBlockCorner_LCD_2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Corner_LCD_2.dds",
    Cost: {
      Construction: 5,
      Computer: 3,
      Display: 1,
    },
  },
  LargeBlockCorner_LCD_Flat_1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Corner_LCD_Flat_1.dds",
    Cost: {
      Construction: 5,
      Computer: 3,
      Display: 1,
    },
  },
  LargeBlockCorner_LCD_Flat_2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Corner_LCD_Flat_2.dds",
    Cost: {
      Construction: 5,
      Computer: 3,
      Display: 1,
    },
  },
  SmallBlockCorner_LCD_1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Corner_LCD_1.dds",
    Cost: {
      Construction: 3,
      Computer: 2,
      Display: 1,
    },
  },
  SmallBlockCorner_LCD_2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Corner_LCD_2.dds",
    Cost: {
      Construction: 3,
      Computer: 2,
      Display: 1,
    },
  },
  SmallBlockCorner_LCD_Flat_1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Corner_LCD_Flat_1.dds",
    Cost: {
      Construction: 3,
      Computer: 2,
      Display: 1,
    },
  },
  SmallBlockCorner_LCD_Flat_2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Corner_LCD_Flat_2.dds",
    Cost: {
      Construction: 3,
      Computer: 2,
      Display: 1,
    },
  },
  LargeTextPanel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\TextPanel.dds",
    Cost: {
      InteriorPlate: 1,
      Construction: 6,
      Computer: 6,
      Display: 10,
      BulletproofGlass: 2,
    },
  },
  LargeLCDPanel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LCDPanel.dds",
    Cost: {
      InteriorPlate: 1,
      Construction: 6,
      Computer: 6,
      Display: 10,
      BulletproofGlass: 6,
    },
  },
  LargeLCDPanelWide: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LCDPanelWide.dds",
    Cost: {
      InteriorPlate: 2,
      Construction: 12,
      Computer: 12,
      Display: 20,
      BulletproofGlass: 12,
    },
  },
  LargeBlockFrontLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\light.dds",
    Cost: {
      SteelPlate: 8,
      LargeTube: 2,
      InteriorPlate: 20,
      Construction: 15,
      BulletproofGlass: 4,
    },
  },
  SmallBlockFrontLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\light.dds",
    Cost: {
      SteelPlate: 1,
      LargeTube: 1,
      InteriorPlate: 1,
      Construction: 1,
      BulletproofGlass: 2,
    },
  },
  SmallLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\interior_light.dds",
    Cost: {
      Construction: 2,
    },
  },
  SmallBlockSmallLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\interior_light.dds",
    Cost: {
      Construction: 2,
    },
  },
  LargeBlockLight_1corner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Light_1corner.dds",
    Cost: {
      Construction: 3,
    },
  },
  LargeBlockLight_2corner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Light_2corner.dds",
    Cost: {
      Construction: 6,
    },
  },
  SmallBlockLight_1corner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Light_1corner.dds",
    Cost: {
      Construction: 2,
    },
  },
  SmallBlockLight_2corner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Light_2corner.dds",
    Cost: {
      Construction: 4,
    },
  },
  OxygenTankSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\OxygenTankSmall.dds",
    Cost: {
      SteelPlate: 16,
      LargeTube: 8,
      SmallTube: 10,
      Computer: 8,
      Construction: 10,
    },
  },
  OxygenTank: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\OxygenTank.dds",
    Cost: {
      SteelPlate: 80,
      LargeTube: 40,
      SmallTube: 60,
      Computer: 8,
      Construction: 40,
    },
  },
  LargeHydrogenTank: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenTank.dds",
    Cost: {
      SteelPlate: 280,
      LargeTube: 80,
      SmallTube: 60,
      Computer: 8,
      Construction: 40,
    },
  },
  LargeHydrogenTankSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\largeHydrogenTankSmall.dds",
    Cost: {
      SteelPlate: 80,
      LargeTube: 40,
      SmallTube: 60,
      Computer: 8,
      Construction: 40,
    },
  },
  SmallHydrogenTank: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenTank.dds",
    Cost: {
      SteelPlate: 40,
      LargeTube: 20,
      SmallTube: 30,
      Computer: 4,
      Construction: 20,
    },
  },
  SmallHydrogenTankSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SmallHydrogenTankSmall.dds",
    Cost: {
      SteelPlate: 3,
      LargeTube: 1,
      SmallTube: 2,
      Computer: 4,
      Construction: 2,
    },
  },
  AirVent: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AirVent.dds",
    Cost: {
      SteelPlate: 45,
      Construction: 20,
      Motor: 10,
      Computer: 5,
    },
  },
  SmallAirVent: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AirVent.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 10,
      Motor: 2,
      Computer: 5,
    },
  },
  SmallBlockSmallContainer: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\container.dds",
    Cost: {
      InteriorPlate: 3,
      Construction: 1,
      Computer: 1,
      Motor: 1,
      Display: 1,
    },
  },
  SmallBlockMediumContainer: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\container_medium.dds",
    Cost: {
      InteriorPlate: 30,
      Construction: 10,
      Computer: 4,
      Motor: 4,
      Display: 1,
    },
  },
  SmallBlockLargeContainer: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\container_large.dds",
    Cost: {
      InteriorPlate: 75,
      Construction: 25,
      Computer: 6,
      Motor: 8,
      Display: 1,
    },
  },
  LargeBlockSmallContainer: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\container.dds",
    Cost: {
      InteriorPlate: 40,
      Construction: 40,
      MetalGrid: 4,
      SmallTube: 20,
      Motor: 4,
      Display: 1,
      Computer: 2,
    },
  },
  LargeBlockLargeContainer: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\container_large.dds",
    Cost: {
      InteriorPlate: 360,
      Construction: 80,
      MetalGrid: 24,
      SmallTube: 60,
      Motor: 20,
      Display: 1,
      Computer: 8,
    },
  },
  SmallBlockConveyor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConveyorSmall.dds",
    Cost: {
      InteriorPlate: 4,
      Construction: 4,
      Motor: 1,
    },
  },
  LargeBlockConveyor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\conveyor.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 30,
      SmallTube: 20,
      Motor: 6,
    },
  },
  Collector: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\collector.dds",
    Cost: {
      SteelPlate: 45,
      Construction: 50,
      SmallTube: 12,
      Motor: 8,
      Display: 4,
      Computer: 10,
    },
  },
  CollectorSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\collector.dds",
    Cost: {
      SteelPlate: 35,
      Construction: 35,
      SmallTube: 12,
      Motor: 8,
      Display: 2,
      Computer: 8,
    },
  },
  Connector: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Connector.dds",
    Cost: {
      SteelPlate: 150,
      Construction: 40,
      SmallTube: 12,
      Motor: 8,
      Computer: 20,
    },
  },
  ConnectorSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConnectorSmall.dds",
    Cost: {
      SteelPlate: 7,
      Construction: 4,
      SmallTube: 2,
      Motor: 1,
      Computer: 4,
    },
  },
  ConnectorMedium: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Connector.dds",
    Cost: {
      SteelPlate: 21,
      Construction: 12,
      SmallTube: 6,
      Motor: 6,
      Computer: 6,
    },
  },
  ConveyorTube: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConveyorTube.dds",
    Cost: {
      InteriorPlate: 14,
      Construction: 20,
      SmallTube: 12,
      Motor: 6,
    },
  },
  ConveyorTubeSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConveyorTubeSmall.dds",
    Cost: {
      InteriorPlate: 1,
      Motor: 1,
      Construction: 1,
    },
  },
  ConveyorTubeMedium: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConveyorTube.dds",
    Cost: {
      InteriorPlate: 10,
      Construction: 20,
      SmallTube: 10,
      Motor: 6,
    },
  },
  ConveyorFrameMedium: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConveyorFrameMedium.dds",
    Cost: {
      InteriorPlate: 5,
      Construction: 12,
      SmallTube: 5,
      Motor: 2,
    },
  },
  ConveyorTubeCurved: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConveyorTubeCurved.dds",
    Cost: {
      InteriorPlate: 14,
      Construction: 20,
      SmallTube: 12,
      Motor: 6,
    },
  },
  ConveyorTubeSmallCurved: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConveyorCurveSmall.dds",
    Cost: {
      InteriorPlate: 1,
      Motor: 1,
      Construction: 1,
    },
  },
  ConveyorTubeCurvedMedium: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConveyorTubeCurved.dds",
    Cost: {
      InteriorPlate: 7,
      Construction: 20,
      SmallTube: 10,
      Motor: 6,
    },
  },
  SmallShipConveyorHub: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Conveyor.dds",
    Cost: {
      InteriorPlate: 15,
      Construction: 20,
      SmallTube: 15,
      Motor: 2,
    },
  },
  LargeBlockConveyorSorter: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConveyorSorterLarge.dds",
    Cost: {
      InteriorPlate: 50,
      Construction: 120,
      SmallTube: 50,
      Computer: 20,
      Motor: 2,
    },
  },
  MediumBlockConveyorSorter: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConveyorSorterMedium.dds",
    Cost: {
      InteriorPlate: 5,
      Construction: 12,
      SmallTube: 5,
      Computer: 5,
      Motor: 2,
    },
  },
  SmallBlockConveyorSorter: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ConveyorSorterSmall.dds",
    Cost: {
      InteriorPlate: 5,
      Construction: 12,
      SmallTube: 5,
      Computer: 5,
      Motor: 2,
    },
  },
  LargePistonBase: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Piston.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 10,
      LargeTube: 4,
      Motor: 4,
      Computer: 2,
    },
  },
  LargePistonTop: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PistonTop.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 10,
      LargeTube: 12,
      Motor: 4,
      Computer: 2,
    },
  },
  SmallPistonBase: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Piston.dds",
    Cost: {
      SteelPlate: 4,
      Construction: 4,
      SmallTube: 4,
      Motor: 2,
      Computer: 1,
    },
  },
  SmallPistonTop: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PistonTop.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 4,
      SmallTube: 4,
      Motor: 2,
      Computer: 1,
      LargeTube: 2,
    },
  },
  LargeStator: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\motor.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 10,
      LargeTube: 4,
      Motor: 4,
      Computer: 2,
    },
  },
  LargeRotor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RotorPart.dds",
    Cost: {
      SteelPlate: 30,
      LargeTube: 6,
    },
  },
  SmallStator: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\motor.dds",
    Cost: {
      SteelPlate: 5,
      Construction: 5,
      SmallTube: 1,
      Motor: 1,
      Computer: 1,
    },
  },
  SmallRotor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RotorPart.dds",
    Cost: {
      SteelPlate: 12,
      SmallTube: 6,
    },
  },
  LargeAdvancedStator: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AdvancedMotor.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 10,
      LargeTube: 4,
      Motor: 4,
      Computer: 2,
    },
  },
  LargeAdvancedRotor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AdvancedRotorPart.dds",
    Cost: {
      SteelPlate: 30,
      LargeTube: 10,
    },
  },
  SmallAdvancedStator: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AdvancedMotor.dds",
    Cost: {
      SteelPlate: 5,
      Construction: 5,
      SmallTube: 1,
      Motor: 1,
      Computer: 1,
    },
  },
  SmallAdvancedRotor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AdvancedRotorPart.dds",
    Cost: {
      SteelPlate: 30,
      LargeTube: 10,
    },
  },
  LargeHinge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Hinge.dds",
    Cost: {
      SteelPlate: 16,
      Construction: 10,
      LargeTube: 4,
      Motor: 4,
      Computer: 2,
    },
  },
  LargeHingeHead: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HingeHead.dds",
    Cost: {
      SteelPlate: 12,
      LargeTube: 4,
      Construction: 8,
    },
  },
  MediumHinge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HingeMedium.dds",
    Cost: {
      SteelPlate: 10,
      Construction: 6,
      LargeTube: 2,
      Motor: 2,
      Computer: 2,
    },
  },
  MediumHingeHead: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HingeHeadMedium.dds",
    Cost: {
      SteelPlate: 6,
      LargeTube: 2,
      Construction: 4,
    },
  },
  SmallHinge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HingeSmall.dds",
    Cost: {
      SteelPlate: 6,
      Construction: 4,
      LargeTube: 1,
      Motor: 2,
      Computer: 2,
    },
  },
  SmallHingeHead: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HingeHeadSmall.dds",
    Cost: {
      SteelPlate: 3,
      LargeTube: 1,
      Construction: 2,
    },
  },
  LargeMedicalRoom: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\medical_room.dds",
    Cost: {
      InteriorPlate: 240,
      Construction: 80,
      MetalGrid: 60,
      SmallTube: 20,
      LargeTube: 5,
      Display: 10,
      Computer: 10,
      Medical: 15,
    },
  },
  LargeBlockCryoChamber: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CryoPod.dds",
    Cost: {
      InteriorPlate: 40,
      Construction: 20,
      Motor: 8,
      Display: 8,
      Medical: 3,
      Computer: 30,
      BulletproofGlass: 10,
    },
  },
  SmallBlockCryoChamber: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CryoPod.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 10,
      Motor: 4,
      Display: 4,
      Medical: 3,
      Computer: 15,
      BulletproofGlass: 5,
    },
  },
  LargeRefinery: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\refinery.dds",
    Cost: {
      SteelPlate: 1200,
      Construction: 40,
      LargeTube: 20,
      Motor: 16,
      MetalGrid: 20,
      Computer: 20,
    },
  },
  "Blast Furnace": {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Blastfurnace.dds",
    Cost: {
      SteelPlate: 120,
      Construction: 20,
      Motor: 10,
      Computer: 10,
    },
  },
  OxygenGenerator: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\OxygenGenerator.dds",
    Cost: {
      SteelPlate: 120,
      Construction: 5,
      LargeTube: 2,
      Motor: 4,
      Computer: 5,
    },
  },
  OxygenGeneratorSmall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\OxygenGeneratorSmall.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 8,
      LargeTube: 2,
      Motor: 1,
      Computer: 3,
    },
  },
  LargeAssembler: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\assembler.dds",
    Cost: {
      SteelPlate: 140,
      Construction: 80,
      Motor: 20,
      Display: 10,
      MetalGrid: 10,
      Computer: 160,
    },
  },
  BasicAssembler: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\basicAssembler.dds",
    Cost: {
      SteelPlate: 80,
      Construction: 40,
      Motor: 10,
      Display: 4,
      Computer: 80,
    },
  },
  SurvivalKitLarge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SurvivalKitLarge.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 2,
      Medical: 3,
      Motor: 4,
      Display: 1,
      Computer: 5,
    },
  },
  SurvivalKit: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SurvivalKitSmall.dds",
    Cost: {
      SteelPlate: 6,
      Construction: 2,
      Medical: 3,
      Motor: 4,
      Display: 1,
      Computer: 5,
    },
  },
  LargeBlockOxygenFarm: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SolarHydroponics.dds",
    Cost: {
      SteelPlate: 40,
      BulletproofGlass: 100,
      LargeTube: 20,
      SmallTube: 10,
      Construction: 20,
      Computer: 20,
    },
  },
  LargeProductivityModule: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\UpgradeProductivity.dds",
    Cost: {
      SteelPlate: 100,
      Construction: 40,
      SmallTube: 20,
      Computer: 60,
      Motor: 4,
    },
  },
  LargeEffectivenessModule: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\UpgradeEffectiveness.dds",
    Cost: {
      SteelPlate: 100,
      Construction: 50,
      SmallTube: 15,
      Superconductor: 20,
      Motor: 4,
    },
  },
  LargeEnergyModule: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\UpgradeEnergy.dds",
    Cost: {
      SteelPlate: 100,
      Construction: 40,
      SmallTube: 20,
      PowerCell: 20,
      Motor: 4,
    },
  },
  SmallExhaustPipe: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ExhaustPipe_Racing_Small.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 1,
      SmallTube: 2,
      Motor: 2,
    },
  },
  LargeExhaustPipe: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ExhaustPipe_Racing_Large.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 10,
      LargeTube: 2,
      Motor: 4,
    },
  },
  BuggyCockpit: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BuggyCockpit_Racing.dds",
    Cost: {
      InteriorPlate: 30,
      Construction: 25,
      Motor: 2,
      Computer: 20,
      Display: 4,
    },
  },
  Viewport1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Viewport_Racing.dds",
    Cost: {
      SteelPlate: 10,
      Construction: 10,
      BulletproofGlass: 8,
    },
  },
  Viewport2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Viewport2_Racing.dds",
    Cost: {
      SteelPlate: 10,
      Construction: 10,
      BulletproofGlass: 8,
    },
  },
  OffroadSuspension3x3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3_RacingRight.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 15,
      LargeTube: 6,
      SmallTube: 12,
      Motor: 6,
    },
  },
  OffroadSuspension5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5_RacingRight.dds",
    Cost: {
      SteelPlate: 70,
      Construction: 40,
      LargeTube: 20,
      SmallTube: 30,
      Motor: 20,
    },
  },
  OffroadSuspension1x1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1_RacingRight.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 15,
      LargeTube: 6,
      SmallTube: 12,
      Motor: 6,
    },
  },
  OffroadSmallSuspension3x3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3_RacingRight.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 7,
      SmallTube: 2,
      Motor: 1,
    },
  },
  OffroadSmallSuspension5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5_RacingRight.dds",
    Cost: {
      SteelPlate: 16,
      Construction: 12,
      SmallTube: 4,
      Motor: 2,
    },
  },
  OffroadSmallSuspension1x1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1_RacingRight.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 7,
      SmallTube: 2,
      Motor: 1,
    },
  },
  OffroadSuspension3x3mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3_RacingLeft.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 15,
      LargeTube: 6,
      SmallTube: 12,
      Motor: 6,
    },
  },
  OffroadSuspension5x5mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5_RacingLeft.dds",
    Cost: {
      SteelPlate: 70,
      Construction: 40,
      LargeTube: 20,
      SmallTube: 30,
      Motor: 20,
    },
  },
  OffroadSuspension1x1mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1_RacingLeft.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 15,
      LargeTube: 6,
      SmallTube: 12,
      Motor: 6,
    },
  },
  OffroadSmallSuspension3x3mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3_RacingLeft.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 7,
      SmallTube: 2,
      Motor: 1,
    },
  },
  OffroadSmallSuspension5x5mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5_RacingLeft.dds",
    Cost: {
      SteelPlate: 16,
      Construction: 12,
      SmallTube: 4,
      Motor: 2,
    },
  },
  OffroadSmallSuspension1x1mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1_RacingLeft.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 7,
      SmallTube: 2,
      Motor: 1,
    },
  },
  OffroadSmallRealWheel1x1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1_RacingRight.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 5,
      LargeTube: 1,
    },
  },
  OffroadSmallRealWheel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3_RacingRight.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 15,
      LargeTube: 3,
    },
  },
  OffroadSmallRealWheel5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5_RacingRight.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 25,
      LargeTube: 5,
    },
  },
  OffroadRealWheel1x1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1_RacingRight.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 30,
      LargeTube: 10,
    },
  },
  OffroadRealWheel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3_RacingRight.dds",
    Cost: {
      SteelPlate: 70,
      Construction: 50,
      LargeTube: 20,
    },
  },
  OffroadRealWheel5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5_RacingRight.dds",
    Cost: {
      SteelPlate: 130,
      Construction: 70,
      LargeTube: 30,
    },
  },
  OffroadSmallRealWheel1x1mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1_RacingLeft.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 5,
      LargeTube: 1,
    },
  },
  OffroadSmallRealWheelmirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3_RacingLeft.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 15,
      LargeTube: 3,
    },
  },
  OffroadSmallRealWheel5x5mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5_RacingLeft.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 25,
      LargeTube: 5,
    },
  },
  OffroadRealWheel1x1mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1_RacingLeft.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 30,
      LargeTube: 10,
    },
  },
  OffroadRealWheelmirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3_RacingLeft.dds",
    Cost: {
      SteelPlate: 70,
      Construction: 50,
      LargeTube: 20,
    },
  },
  OffroadRealWheel5x5mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5_RacingLeft.dds",
    Cost: {
      SteelPlate: 130,
      Construction: 70,
      LargeTube: 30,
    },
  },
  OffroadWheel1x1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Wheel1x1_Racing.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 30,
      LargeTube: 10,
    },
  },
  OffroadSmallWheel1x1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Wheel1x1_Racing.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 5,
      LargeTube: 1,
    },
  },
  OffroadWheel3x3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Wheel3x3_Racing.dds",
    Cost: {
      SteelPlate: 70,
      Construction: 50,
      LargeTube: 20,
    },
  },
  OffroadSmallWheel3x3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Wheel3x3_Racing.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 15,
      LargeTube: 3,
    },
  },
  OffroadWheel5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Wheel5x5_Racing.dds",
    Cost: {
      SteelPlate: 130,
      Construction: 70,
      LargeTube: 30,
    },
  },
  OffroadSmallWheel5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Wheel5x5_Racing.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 25,
      LargeTube: 5,
    },
  },
  OffsetLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CarLight1_Racing.dds",
    Cost: {
      Construction: 2,
    },
  },
  OffsetSpotlight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\CarLight2_Racing.dds",
    Cost: {
      Construction: 2,
      BulletproofGlass: 1,
    },
  },
  BarredWindow: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BarredWindow_Racing_Flat.dds",
    Cost: {
      Girder: 1,
      Construction: 4,
    },
  },
  BarredWindowSlope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BarredWindow_Racing_Slope.dds",
    Cost: {
      Girder: 1,
      Construction: 4,
    },
  },
  BarredWindowSide: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BarredWindow_Racing_Side.dds",
    Cost: {
      Girder: 1,
      Construction: 4,
    },
  },
  BarredWindowFace: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BarredWindow_Racing_Face.dds",
    Cost: {
      Girder: 1,
      Construction: 4,
    },
  },
  StorageShelf1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Shelf_1.dds",
    Cost: {
      Girder: 10,
      SteelPlate: 50,
      Construction: 50,
      SmallTube: 50,
      InteriorPlate: 50,
    },
  },
  StorageShelf2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Shelf_2.dds",
    Cost: {
      Girder: 30,
      PowerCell: 20,
      Motor: 20,
      MetalGrid: 20,
    },
  },
  StorageShelf3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Shelf_3.dds",
    Cost: {
      Girder: 10,
      Reactor: 10,
      Superconductor: 10,
      Thrust: 10,
      GravityGenerator: 2,
    },
  },
  LargeLCDPanel5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LCDPanel3x3.dds",
    Cost: {
      InteriorPlate: 25,
      Construction: 150,
      Computer: 25,
      Display: 250,
      BulletproofGlass: 150,
    },
  },
  LargeLCDPanel5x3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LCDPanel5x3.dds",
    Cost: {
      InteriorPlate: 15,
      Construction: 90,
      Computer: 15,
      Display: 150,
      BulletproofGlass: 90,
    },
  },
  LargeLCDPanel3x3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LCDPanel3x3.dds",
    Cost: {
      InteriorPlate: 10,
      Construction: 50,
      Computer: 10,
      Display: 90,
      BulletproofGlass: 50,
    },
  },
  LargeBlockSciFiWall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SciFiWall.dds",
    Cost: {
      InteriorPlate: 25,
      Construction: 10,
    },
  },
  LargeNeonTubesStraight1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\NeonTubesStraight1.dds",
    Cost: {
      InteriorPlate: 6,
      SmallTube: 6,
      Construction: 2,
    },
  },
  LargeNeonTubesStraight2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\NeonTubesStraight2.dds",
    Cost: {
      InteriorPlate: 6,
      SmallTube: 6,
      Construction: 2,
    },
  },
  LargeNeonTubesCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\NeonTubesCorner.dds",
    Cost: {
      InteriorPlate: 6,
      SmallTube: 6,
      Construction: 2,
    },
  },
  LargeNeonTubesBendUp: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\NeonTubesBendUp.dds",
    Cost: {
      InteriorPlate: 12,
      SmallTube: 12,
      Construction: 4,
    },
  },
  LargeNeonTubesBendDown: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\NeonTubesBendDown.dds",
    Cost: {
      InteriorPlate: 3,
      SmallTube: 3,
      Construction: 1,
    },
  },
  LargeNeonTubesStraightEnd1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\NeonTubesStraightEnd1.dds",
    Cost: {
      InteriorPlate: 6,
      SmallTube: 6,
      Construction: 2,
    },
  },
  LargeNeonTubesStraightEnd2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\NeonTubesStraightEnd2.dds",
    Cost: {
      InteriorPlate: 10,
      SmallTube: 6,
      Construction: 4,
    },
  },
  LargeNeonTubesStraightDown: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\NeonTubesStraightDown.dds",
    Cost: {
      InteriorPlate: 9,
      SmallTube: 9,
      Construction: 3,
    },
  },
  LargeNeonTubesU: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\NeonTubesU.dds",
    Cost: {
      InteriorPlate: 18,
      SmallTube: 18,
      Construction: 6,
    },
  },
  LargeBlockSciFiTerminal: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\NeonTerminal.dds",
    Cost: {
      Construction: 4,
      Computer: 2,
      Display: 4,
      InteriorPlate: 2,
    },
  },
  LargeSciFiButtonTerminal: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SciFiButtonTerminal.dds",
    Cost: {
      InteriorPlate: 5,
      Construction: 10,
      Computer: 4,
      Display: 4,
    },
  },
  SmallSideDoor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SmallSideDoor.dds",
    Cost: {
      InteriorPlate: 10,
      Construction: 26,
      BulletproofGlass: 4,
      Motor: 2,
      Display: 1,
      Computer: 2,
      SteelPlate: 8,
    },
  },
  LargeBlockBarCounter: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BarCounter_SciFi.dds",
    Cost: {
      InteriorPlate: 16,
      Construction: 10,
      Motor: 1,
      BulletproofGlass: 6,
    },
  },
  LargeBlockBarCounterCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BarCounterCorner_SciFi.dds",
    Cost: {
      InteriorPlate: 24,
      Construction: 14,
      Motor: 2,
      BulletproofGlass: 10,
    },
  },
  LargeSciFiButtonPanel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ButtonPanel_SciFi.dds",
    Cost: {
      InteriorPlate: 10,
      Construction: 20,
      Computer: 20,
      Display: 5,
    },
  },
  SmallBlockSmallThrustSciFi: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Small_IonThrusterSmall_SciFi.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 2,
      LargeTube: 1,
      Thrust: 1,
    },
  },
  SmallBlockLargeThrustSciFi: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Small_IonThrusterLarge_SciFi.dds",
    Cost: {
      SteelPlate: 5,
      Construction: 2,
      LargeTube: 5,
      Thrust: 12,
    },
  },
  LargeBlockSmallThrustSciFi: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\large_IonThrusterSmall_SciFi.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 60,
      LargeTube: 8,
      Thrust: 80,
    },
  },
  LargeBlockLargeThrustSciFi: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\large_IonThrusterLarge_SciFi.dds",
    Cost: {
      SteelPlate: 150,
      Construction: 100,
      LargeTube: 40,
      Thrust: 960,
    },
  },
  LargeBlockLargeAtmosphericThrustSciFi: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Large_AtmosphericThrusterLarge_SciFi.dds",
    Cost: {
      SteelPlate: 230,
      Construction: 60,
      LargeTube: 50,
      MetalGrid: 40,
      Motor: 1100,
    },
  },
  LargeBlockSmallAtmosphericThrustSciFi: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Large_AtmosphericThrusterSmall_SciFi.dds",
    Cost: {
      SteelPlate: 35,
      Construction: 50,
      LargeTube: 8,
      MetalGrid: 10,
      Motor: 110,
    },
  },
  SmallBlockLargeAtmosphericThrustSciFi: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Small_AtmosphericThrusterLarge_SciFi.dds",
    Cost: {
      SteelPlate: 20,
      Construction: 30,
      LargeTube: 4,
      MetalGrid: 8,
      Motor: 90,
    },
  },
  SmallBlockSmallAtmosphericThrustSciFi: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Small_AtmosphericThrusterSmall_SciFi.dds",
    Cost: {
      SteelPlate: 3,
      Construction: 22,
      LargeTube: 1,
      MetalGrid: 1,
      Motor: 18,
    },
  },
  LargeSymbolA: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_A.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolB: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_B.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolC: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_C.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolD: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_D.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolE: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_E.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolF: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_F.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolG: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_G.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolH: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_H.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolI: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_I.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolJ: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_J.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolK: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_K.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolL: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_L.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolM: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_M.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolN: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_N.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolO: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_O.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolP: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_P.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolQ: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Q.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolR: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_R.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolS: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_S.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolT: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_T.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolU: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_U.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolV: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_V.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolW: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_W.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolX: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_X.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolY: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Y.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolZ: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Z.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  SmallSymbolA: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_A.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolB: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_B.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolC: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_C.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolD: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_D.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolE: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_E.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolF: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_F.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolG: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_G.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolH: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_H.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolI: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_I.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolJ: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_J.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolK: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_K.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolL: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_L.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolM: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_M.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolN: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_N.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolO: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_O.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolP: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_P.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolQ: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Q.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolR: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_R.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolS: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_S.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolT: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_T.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolU: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_U.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolV: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_V.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolW: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_W.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolX: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_X.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolY: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Y.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolZ: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Z.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeSymbol0: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_0.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbol1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_1.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbol2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_2.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbol3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_3.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbol4: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_4.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbol5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_5.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbol6: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_6.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbol7: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_7.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbol8: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_8.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbol9: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_9.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  SmallSymbol0: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_0.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbol1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_1.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbol2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_2.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbol3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_3.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbol4: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_4.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbol5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_5.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbol6: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_6.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbol7: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_7.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbol8: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_8.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbol9: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_9.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  LargeSymbolHyphen: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Hyphen.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolUnderscore: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Underscore.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolDot: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Dot.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolApostrophe: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Apostrophe.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolAnd: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_And.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolColon: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Colon.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolExclamationMark: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_ExclamationMark.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  LargeSymbolQuestionMark: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_QuestionMark.dds",
    Cost: {
      SteelPlate: 4,
    },
  },
  SmallSymbolHyphen: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Hyphen.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolUnderscore: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Underscore.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolDot: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Dot.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolApostrophe: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Apostrophe.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolAnd: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_And.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolColon: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_Colon.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolExclamationMark: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_ExclamationMark.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallSymbolQuestionMark: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Symbol_QuestionMark.dds",
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockSmallThrust: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\thrust.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 2,
      LargeTube: 1,
      Thrust: 1,
    },
  },
  SmallBlockLargeThrust: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\thrust_large.dds",
    Cost: {
      SteelPlate: 5,
      Construction: 2,
      LargeTube: 5,
      Thrust: 12,
    },
  },
  LargeBlockSmallThrust: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\thrust.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 60,
      LargeTube: 8,
      Thrust: 80,
    },
  },
  LargeBlockLargeThrust: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\thrust_large.dds",
    Cost: {
      SteelPlate: 150,
      Construction: 100,
      LargeTube: 40,
      Thrust: 960,
    },
  },
  LargeBlockLargeHydrogenThrust: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenThrusterLarge.dds",
    Cost: {
      SteelPlate: 150,
      Construction: 180,
      MetalGrid: 250,
      LargeTube: 40,
    },
  },
  LargeBlockSmallHydrogenThrust: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenThrusterSmall.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 60,
      MetalGrid: 40,
      LargeTube: 8,
    },
  },
  SmallBlockLargeHydrogenThrust: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenThrusterLarge.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 30,
      MetalGrid: 22,
      LargeTube: 10,
    },
  },
  SmallBlockSmallHydrogenThrust: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HydrogenThrusterSmall.dds",
    Cost: {
      SteelPlate: 7,
      Construction: 15,
      MetalGrid: 4,
      LargeTube: 2,
    },
  },
  LargeBlockLargeAtmosphericThrust: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AtmosphericThrusterLargeLarge.dds",
    Cost: {
      SteelPlate: 230,
      Construction: 60,
      LargeTube: 50,
      MetalGrid: 40,
      Motor: 1100,
    },
  },
  LargeBlockSmallAtmosphericThrust: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AtmosphericThrusterSmall.dds",
    Cost: {
      SteelPlate: 35,
      Construction: 50,
      LargeTube: 8,
      MetalGrid: 10,
      Motor: 110,
    },
  },
  SmallBlockLargeAtmosphericThrust: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AtmosphericThrusterLargeLarge.dds",
    Cost: {
      SteelPlate: 20,
      Construction: 30,
      LargeTube: 4,
      MetalGrid: 8,
      Motor: 90,
    },
  },
  SmallBlockSmallAtmosphericThrust: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AtmosphericThrusterSmall.dds",
    Cost: {
      SteelPlate: 3,
      Construction: 22,
      LargeTube: 1,
      MetalGrid: 1,
      Motor: 18,
    },
  },
  SmallBlockDrill: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\drill.dds",
    Cost: {
      SteelPlate: 32,
      Construction: 30,
      LargeTube: 4,
      Motor: 1,
      Computer: 1,
    },
  },
  LargeBlockDrill: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\drill.dds",
    Cost: {
      SteelPlate: 300,
      Construction: 40,
      LargeTube: 12,
      Motor: 5,
      Computer: 5,
    },
  },
  LargeShipGrinder: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Grinder.dds",
    Cost: {
      SteelPlate: 20,
      Construction: 30,
      LargeTube: 1,
      Motor: 4,
      Computer: 2,
    },
  },
  SmallShipGrinder: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Grinder.dds",
    Cost: {
      SteelPlate: 12,
      Construction: 17,
      SmallTube: 4,
      Motor: 4,
      Computer: 2,
    },
  },
  LargeShipWelder: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Welder.dds",
    Cost: {
      SteelPlate: 20,
      Construction: 30,
      LargeTube: 1,
      Motor: 2,
      Computer: 2,
    },
  },
  SmallShipWelder: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Welder.dds",
    Cost: {
      SteelPlate: 12,
      Construction: 17,
      SmallTube: 6,
      Motor: 2,
      Computer: 2,
    },
  },
  LargeOreDetector: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\detector.dds",
    Cost: {
      SteelPlate: 50,
      Construction: 40,
      Motor: 5,
      Computer: 25,
      Detector: 20,
    },
  },
  SmallBlockOreDetector: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\detector.dds",
    Cost: {
      SteelPlate: 3,
      Construction: 2,
      Motor: 1,
      Computer: 1,
      Detector: 1,
    },
  },
  LargeBlockLandingGear: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\landing_gear.dds",
    Cost: {
      SteelPlate: 150,
      Construction: 20,
      Motor: 6,
    },
  },
  SmallBlockLandingGear: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\landing_gear.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 5,
      Motor: 1,
    },
  },
  LargeBlockSmallMagneticPlate: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SimpleMagneticPlate.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 3,
      Motor: 1,
    },
  },
  SmallBlockSmallMagneticPlate: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SimpleMagneticPlate.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 1,
      Motor: 1,
    },
  },
  LargeJumpDrive: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\JumpDrive.dds",
    Cost: {
      SteelPlate: 60,
      MetalGrid: 50,
      GravityGenerator: 20,
      Detector: 20,
      PowerCell: 120,
      Superconductor: 1000,
      Computer: 300,
      Construction: 40,
    },
  },
  SmallCameraBlock: {
    IconPath: "Textures\\GUI\\Icons\\Camera.dds",
    Cost: {
      SteelPlate: 2,
      Computer: 3,
    },
  },
  LargeCameraBlock: {
    IconPath: "Textures\\GUI\\Icons\\Camera.dds",
    Cost: {
      SteelPlate: 2,
      Computer: 3,
    },
  },
  LargeShipMergeBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\MergeBlock.dds",
    Cost: {
      SteelPlate: 12,
      Construction: 15,
      Motor: 2,
      LargeTube: 6,
      Computer: 2,
    },
  },
  SmallShipMergeBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\MergeBlock.dds",
    Cost: {
      SteelPlate: 4,
      Construction: 5,
      Motor: 1,
      SmallTube: 2,
      Computer: 1,
    },
  },
  SmallShipSmallMergeBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\MergeBlockSmall.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 3,
      Motor: 1,
      SmallTube: 1,
      Computer: 1,
    },
  },
  LgParachute: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ParachuteHatch.dds",
    Cost: {
      SteelPlate: 9,
      Construction: 25,
      SmallTube: 5,
      Motor: 3,
      Computer: 2,
    },
  },
  SmParachute: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ParachuteHatch.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 2,
      SmallTube: 1,
      Motor: 1,
      Computer: 1,
    },
  },
  LargeBlockWeaponRack: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WeaponRack.dds",
    Cost: {
      InteriorPlate: 30,
      Construction: 20,
    },
  },
  SmallBlockWeaponRack: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WeaponRack_small.dds",
    Cost: {
      InteriorPlate: 3,
      Construction: 3,
    },
  },
  FireCover: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\FireCover.dds",
    Cost: {
      SteelPlate: 4,
      Construction: 10,
    },
  },
  FireCoverCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\FireCoverCorner.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 20,
    },
  },
  HalfWindow: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfWindow.dds",
    Cost: {
      Girder: 4,
      SteelPlate: 10,
      BulletproofGlass: 10,
    },
  },
  HalfWindowInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfWindowInv.dds",
    Cost: {
      Girder: 4,
      SteelPlate: 10,
      BulletproofGlass: 10,
    },
  },
  HalfWindowCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfWindowCorner.dds",
    Cost: {
      Girder: 8,
      SteelPlate: 20,
      BulletproofGlass: 20,
    },
  },
  HalfWindowCornerInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HalfWindowCornerInv.dds",
    Cost: {
      Girder: 8,
      SteelPlate: 20,
      BulletproofGlass: 20,
    },
  },
  Embrasure: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Embrasure.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 20,
      MetalGrid: 10,
    },
  },
  PassageSciFi: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassageSciFi.dds",
    Cost: {
      InteriorPlate: 74,
      Construction: 20,
      SmallTube: 48,
    },
  },
  PassageSciFiWall: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassageSciFiWall.dds",
    Cost: {
      InteriorPlate: 50,
      Construction: 14,
      SmallTube: 32,
    },
  },
  PassageSciFiIntersection: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassageSciFiIntersection.dds",
    Cost: {
      InteriorPlate: 35,
      Construction: 10,
      SmallTube: 25,
    },
  },
  PassageSciFiGate: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassageSciFiGate.dds",
    Cost: {
      InteriorPlate: 35,
      Construction: 10,
      SmallTube: 25,
    },
  },
  PassageSciFiLight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassageSciFiLight.dds",
    Cost: {
      InteriorPlate: 74,
      Construction: 20,
      SmallTube: 48,
    },
  },
  PassageScifiCorner: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassageScifiCorner.dds",
    Cost: {
      InteriorPlate: 74,
      Construction: 20,
      SmallTube: 48,
    },
  },
  PassageSciFiTjunction: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassageSciFiT-junction.dds",
    Cost: {
      InteriorPlate: 55,
      Construction: 16,
      SmallTube: 38,
    },
  },
  PassageSciFiWindow: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassageSciFiWindow.dds",
    Cost: {
      InteriorPlate: 60,
      Construction: 16,
      BulletproofGlass: 16,
      SmallTube: 38,
    },
  },
  BridgeWindow1x1Slope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BridgeWindow1x1Slope.dds",
    Cost: {
      Girder: 8,
      SteelPlate: 5,
      InteriorPlate: 10,
      BulletproofGlass: 25,
    },
  },
  BridgeWindow1x1Face: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BridgeWindow1x1Face.dds",
    Cost: {
      Girder: 8,
      SteelPlate: 2,
      InteriorPlate: 4,
      BulletproofGlass: 18,
    },
  },
  PassengerBench: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\PassengerBench.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
    },
  },
  LargeLightPanel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightPanel.dds",
    Cost: {
      Construction: 10,
      InteriorPlate: 5,
    },
  },
  SmallLightPanel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LightPanel.dds",
    Cost: {
      Construction: 2,
      InteriorPlate: 1,
    },
  },
  LargeBlockSmallGeneratorWarfare2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ReactorReskinSmall.dds",
    Cost: {
      SteelPlate: 80,
      Construction: 40,
      MetalGrid: 4,
      LargeTube: 8,
      Reactor: 100,
      Motor: 6,
      Computer: 25,
    },
  },
  LargeBlockLargeGeneratorWarfare2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\ReactorReskinLarge.dds",
    Cost: {
      SteelPlate: 1000,
      Construction: 70,
      MetalGrid: 40,
      LargeTube: 40,
      Superconductor: 100,
      Reactor: 2000,
      Motor: 20,
      Computer: 75,
    },
  },
  SmallBlockSmallGeneratorWarfare2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SmallGridReactorReskinSmall.dds",
    Cost: {
      SteelPlate: 3,
      Construction: 10,
      MetalGrid: 2,
      LargeTube: 1,
      Reactor: 3,
      Motor: 1,
      Computer: 10,
    },
  },
  SmallBlockLargeGeneratorWarfare2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SmallGridReactorReskinLarge.dds",
    Cost: {
      SteelPlate: 60,
      Construction: 9,
      MetalGrid: 9,
      LargeTube: 3,
      Reactor: 95,
      Motor: 5,
      Computer: 25,
    },
  },
  AirtightHangarDoorWarfare2A: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HangarDoorWarfareA_Base.dds",
    Cost: {
      SteelPlate: 350,
      Construction: 40,
      SmallTube: 40,
      Motor: 16,
      Computer: 2,
    },
  },
  AirtightHangarDoorWarfare2B: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HangarDoorWarfareB_Base.dds",
    Cost: {
      SteelPlate: 350,
      Construction: 40,
      SmallTube: 40,
      Motor: 16,
      Computer: 2,
    },
  },
  AirtightHangarDoorWarfare2C: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HangarDoorWarfareC_Base.dds",
    Cost: {
      SteelPlate: 350,
      Construction: 40,
      SmallTube: 40,
      Motor: 16,
      Computer: 2,
    },
  },
  SmallMissileLauncherWarfare2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RocketlauncherReskin.dds",
    Cost: {
      SteelPlate: 4,
      Construction: 2,
      MetalGrid: 1,
      LargeTube: 4,
      Motor: 1,
      Computer: 1,
    },
  },
  SmallGatlingGunWarfare2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\gatlingGunReskin.dds",
    Cost: {
      SteelPlate: 4,
      Construction: 1,
      MetalGrid: 2,
      SmallTube: 6,
      Motor: 1,
      Computer: 1,
    },
  },
  LargeBlockBatteryBlockWarfare2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BatteryReskin.dds",
    Cost: {
      SteelPlate: 80,
      Construction: 30,
      PowerCell: 80,
      Computer: 25,
    },
  },
  SmallBlockBatteryBlockWarfare2: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\BatteryReskin_Small.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 5,
      PowerCell: 20,
      Computer: 2,
    },
  },
  SlidingHatchDoor: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HatchDoor.dds",
    Cost: {
      SteelPlate: 40,
      Construction: 50,
      SmallTube: 10,
      Motor: 4,
      Display: 2,
      Computer: 2,
      BulletproofGlass: 10,
    },
  },
  SlidingHatchDoorHalf: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HatchDoorHalf.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 50,
      SmallTube: 10,
      Motor: 4,
      Display: 2,
      Computer: 2,
      BulletproofGlass: 10,
    },
  },
  SmallSearchlight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Small_Searchlight_Racing.dds",
    Cost: {
      SteelPlate: 1,
      Construction: 3,
      LargeTube: 1,
      Motor: 2,
      Computer: 5,
      BulletproofGlass: 2,
    },
  },
  LargeSearchlight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Large_Searchlight_Racing.dds",
    Cost: {
      SteelPlate: 5,
      Construction: 20,
      LargeTube: 2,
      Motor: 4,
      Computer: 5,
      BulletproofGlass: 4,
    },
  },
  LargeHeatVentBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeatVent.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 20,
      LargeTube: 10,
      Motor: 5,
    },
  },
  SmallHeatVentBlock: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeatVent_small.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 1,
      LargeTube: 1,
      Motor: 1,
    },
  },
  SmallBlockStandingCockpit: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\StandingBridge.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
      Motor: 1,
      Computer: 20,
      Display: 2,
    },
  },
  LargeBlockStandingCockpit: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\StandingBridge.dds",
    Cost: {
      InteriorPlate: 20,
      Construction: 20,
      Motor: 1,
      Computer: 20,
      Display: 2,
    },
  },
  SmallBlockSmallModularThruster: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SmallGridModularIonThrusterSmall.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 2,
      LargeTube: 1,
      Thrust: 1,
    },
  },
  SmallBlockLargeModularThruster: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\SmallGridModularIonThrusterLarge.dds",
    Cost: {
      SteelPlate: 5,
      Construction: 2,
      LargeTube: 5,
      Thrust: 12,
    },
  },
  LargeBlockSmallModularThruster: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGridModularIonThrusterSmall.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 60,
      LargeTube: 8,
      Thrust: 80,
    },
  },
  LargeBlockLargeModularThruster: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\LargeGridModularIonThrusterLarge.dds",
    Cost: {
      SteelPlate: 150,
      Construction: 100,
      LargeTube: 40,
      Thrust: 960,
    },
  },
  LargeWarhead: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\warhead.dds",
    Cost: {
      SteelPlate: 20,
      Girder: 24,
      Construction: 12,
      SmallTube: 12,
      Computer: 2,
      Explosives: 6,
    },
  },
  SmallWarhead: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\warhead.dds",
    Cost: {
      SteelPlate: 4,
      Girder: 1,
      Construction: 1,
      SmallTube: 2,
      Computer: 1,
      Explosives: 2,
    },
  },
  LargeDecoy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\decoy.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 10,
      Computer: 10,
      RadioCommunication: 1,
      LargeTube: 2,
    },
  },
  SmallDecoy: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\decoy.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 1,
      Computer: 1,
      RadioCommunication: 1,
      SmallTube: 2,
    },
  },
  LargeGatlingTurret: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\gatling_turret.dds",
    Cost: {
      SteelPlate: 40,
      Construction: 40,
      MetalGrid: 15,
      SmallTube: 6,
      Motor: 8,
      Computer: 10,
    },
  },
  SmallGatlingTurret: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\gatling_turret.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 30,
      MetalGrid: 5,
      SmallTube: 6,
      Motor: 4,
      Computer: 10,
    },
  },
  LargeMissileTurret: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\missile_turret.dds",
    Cost: {
      SteelPlate: 40,
      Construction: 50,
      MetalGrid: 15,
      LargeTube: 6,
      Motor: 16,
      Computer: 10,
    },
  },
  SmallMissileTurret: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\missile_turret.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 40,
      MetalGrid: 5,
      LargeTube: 2,
      Motor: 8,
      Computer: 10,
    },
  },
  LargeInteriorTurret: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\interior_turret.dds",
    Cost: {
      InteriorPlate: 6,
      Construction: 20,
      SmallTube: 1,
      Motor: 2,
      Computer: 5,
      SteelPlate: 4,
    },
  },
  SmallMissileLauncher: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\missile_launcher_small.dds",
    Cost: {
      SteelPlate: 4,
      Construction: 2,
      MetalGrid: 1,
      LargeTube: 4,
      Motor: 1,
      Computer: 1,
    },
  },
  LargeMissileLauncher: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\missile_launcher.dds",
    Cost: {
      SteelPlate: 35,
      Construction: 8,
      MetalGrid: 30,
      LargeTube: 25,
      Motor: 6,
      Computer: 4,
    },
  },
  SmallRocketLauncherReload: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RocketLauncherReload.dds",
    Cost: {
      SmallTube: 50,
      InteriorPlate: 50,
      Construction: 24,
      LargeTube: 8,
      MetalGrid: 10,
      Motor: 4,
      Computer: 2,
      SteelPlate: 8,
    },
  },
  SmallGatlingGun: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\gatling_gun.dds",
    Cost: {
      SteelPlate: 4,
      Construction: 1,
      MetalGrid: 2,
      SmallTube: 6,
      Motor: 1,
      Computer: 1,
    },
  },
  SmallBlockAutocannon: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AutoCannon.dds",
    Cost: {
      SteelPlate: 6,
      Construction: 2,
      MetalGrid: 2,
      SmallTube: 2,
      Motor: 1,
      Computer: 1,
    },
  },
  SmallBlockMediumCalibreGun: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\MediumCaliberGun.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 10,
      MetalGrid: 5,
      LargeTube: 10,
      Computer: 1,
    },
  },
  LargeBlockLargeCalibreGun: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyCalibreGun.dds",
    Cost: {
      SteelPlate: 250,
      Construction: 20,
      MetalGrid: 20,
      LargeTube: 20,
      Computer: 5,
    },
  },
  LargeRailgun: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RailGun.dds",
    Cost: {
      SteelPlate: 350,
      Construction: 150,
      Superconductor: 150,
      LargeTube: 60,
      PowerCell: 100,
      Computer: 100,
    },
  },
  SmallRailgun: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\RailGun.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 20,
      Superconductor: 20,
      LargeTube: 6,
      PowerCell: 10,
      Computer: 20,
    },
  },
  LargeCalibreTurret: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\HeavyCalibreTurret.dds",
    Cost: {
      SteelPlate: 450,
      Construction: 400,
      MetalGrid: 50,
      LargeTube: 40,
      Motor: 30,
      Computer: 20,
    },
  },
  LargeBlockMediumCalibreTurret: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\MediumCalibreTurretLarge.dds",
    Cost: {
      SteelPlate: 300,
      Construction: 280,
      MetalGrid: 30,
      LargeTube: 30,
      Motor: 20,
      Computer: 20,
    },
  },
  SmallBlockMediumCalibreTurret: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\MediumCalibreTurret_Small.dds",
    Cost: {
      SteelPlate: 50,
      Construction: 100,
      MetalGrid: 10,
      LargeTube: 6,
      Motor: 10,
      Computer: 20,
    },
  },
  AutoCannonTurret: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\AutocannonTurret_icon.dds",
    Cost: {
      SteelPlate: 20,
      Construction: 40,
      MetalGrid: 6,
      SmallTube: 4,
      Motor: 4,
      Computer: 10,
    },
  },
  Suspension3x3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3Right.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 15,
      LargeTube: 6,
      SmallTube: 12,
      Motor: 6,
    },
  },
  Suspension5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5Right.dds",
    Cost: {
      SteelPlate: 70,
      Construction: 40,
      LargeTube: 20,
      SmallTube: 30,
      Motor: 20,
    },
  },
  Suspension1x1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1Right.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 15,
      LargeTube: 6,
      SmallTube: 12,
      Motor: 6,
    },
  },
  SmallSuspension3x3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3Right.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 7,
      SmallTube: 2,
      Motor: 1,
    },
  },
  SmallSuspension5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5Right.dds",
    Cost: {
      SteelPlate: 16,
      Construction: 12,
      SmallTube: 4,
      Motor: 2,
    },
  },
  SmallSuspension1x1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1Right.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 7,
      SmallTube: 2,
      Motor: 1,
    },
  },
  Suspension3x3mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3Left.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 15,
      LargeTube: 6,
      SmallTube: 12,
      Motor: 6,
    },
  },
  Suspension5x5mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5Left.dds",
    Cost: {
      SteelPlate: 70,
      Construction: 40,
      LargeTube: 20,
      SmallTube: 30,
      Motor: 20,
    },
  },
  Suspension1x1mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1Left.dds",
    Cost: {
      SteelPlate: 25,
      Construction: 15,
      LargeTube: 6,
      SmallTube: 12,
      Motor: 6,
    },
  },
  SmallSuspension3x3mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3Left.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 7,
      SmallTube: 2,
      Motor: 1,
    },
  },
  SmallSuspension5x5mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5Left.dds",
    Cost: {
      SteelPlate: 16,
      Construction: 12,
      SmallTube: 4,
      Motor: 2,
    },
  },
  SmallSuspension1x1mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1Left.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 7,
      SmallTube: 2,
      Motor: 1,
    },
  },
  SmallRealWheel1x1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1Right.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 5,
      LargeTube: 1,
    },
  },
  SmallRealWheel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3Right.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 15,
      LargeTube: 3,
    },
  },
  SmallRealWheel5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5Right.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 25,
      LargeTube: 5,
    },
  },
  RealWheel1x1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1Right.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 30,
      LargeTube: 10,
    },
  },
  RealWheel: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3Right.dds",
    Cost: {
      SteelPlate: 70,
      Construction: 50,
      LargeTube: 20,
    },
  },
  RealWheel5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5Right.dds",
    Cost: {
      SteelPlate: 130,
      Construction: 70,
      LargeTube: 30,
    },
  },
  SmallRealWheel1x1mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1Left.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 5,
      LargeTube: 1,
    },
  },
  SmallRealWheelmirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3Left.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 15,
      LargeTube: 3,
    },
  },
  SmallRealWheel5x5mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5Left.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 25,
      LargeTube: 5,
    },
  },
  RealWheel1x1mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension1x1Left.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 30,
      LargeTube: 10,
    },
  },
  RealWheelmirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension3x3Left.dds",
    Cost: {
      SteelPlate: 70,
      Construction: 50,
      LargeTube: 20,
    },
  },
  RealWheel5x5mirrored: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\WheelSuspension5x5Left.dds",
    Cost: {
      SteelPlate: 130,
      Construction: 70,
      LargeTube: 30,
    },
  },
  Wheel1x1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Wheel1x1.dds",
    Cost: {
      SteelPlate: 30,
      Construction: 30,
      LargeTube: 10,
    },
  },
  SmallWheel1x1: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Wheel1x1.dds",
    Cost: {
      SteelPlate: 2,
      Construction: 5,
      LargeTube: 1,
    },
  },
  Wheel3x3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Wheel3x3.dds",
    Cost: {
      SteelPlate: 70,
      Construction: 50,
      LargeTube: 20,
    },
  },
  SmallWheel3x3: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Wheel3x3.dds",
    Cost: {
      SteelPlate: 8,
      Construction: 15,
      LargeTube: 3,
    },
  },
  Wheel5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Wheel5x5.dds",
    Cost: {
      SteelPlate: 130,
      Construction: 70,
      LargeTube: 30,
    },
  },
  SmallWheel5x5: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Wheel5x5.dds",
    Cost: {
      SteelPlate: 15,
      Construction: 25,
      LargeTube: 5,
    },
  },
  LargeWindowSquare: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\window.dds",
    Cost: {
      InteriorPlate: 12,
      Construction: 8,
      SmallTube: 4,
    },
  },
  LargeWindowEdge: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\window2.dds",
    Cost: {
      InteriorPlate: 16,
      Construction: 12,
      SmallTube: 6,
    },
  },
  Window1x2Slope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2Slope.dds",
    Cost: {
      Girder: 16,
      BulletproofGlass: 55,
    },
  },
  Window1x2Inv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2Inv.dds",
    Cost: {
      Girder: 15,
      BulletproofGlass: 40,
    },
  },
  Window1x2Face: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2Face.dds",
    Cost: {
      Girder: 15,
      BulletproofGlass: 40,
    },
  },
  Window1x2SideLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2SideLeft.dds",
    Cost: {
      Girder: 13,
      BulletproofGlass: 26,
    },
  },
  Window1x2SideLeftInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2SideLeftInv.dds",
    Cost: {
      Girder: 13,
      BulletproofGlass: 26,
    },
  },
  Window1x2SideRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2SideRight.dds",
    Cost: {
      Girder: 13,
      BulletproofGlass: 26,
    },
  },
  Window1x2SideRightInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2SideRightInv.dds",
    Cost: {
      Girder: 13,
      BulletproofGlass: 26,
    },
  },
  Window1x1Slope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1Slope.dds",
    Cost: {
      Girder: 12,
      BulletproofGlass: 35,
    },
  },
  Window1x1Face: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1Face.dds",
    Cost: {
      Girder: 11,
      BulletproofGlass: 24,
    },
  },
  Window1x1Side: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1Side.dds",
    Cost: {
      Girder: 9,
      BulletproofGlass: 17,
    },
  },
  Window1x1SideInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1SideInv.dds",
    Cost: {
      Girder: 9,
      BulletproofGlass: 17,
    },
  },
  Window1x1Inv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1Inv.dds",
    Cost: {
      Girder: 11,
      BulletproofGlass: 24,
    },
  },
  Window1x2Flat: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2Flat.dds",
    Cost: {
      Girder: 15,
      BulletproofGlass: 50,
    },
  },
  Window1x2FlatInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2FlatInv.dds",
    Cost: {
      Girder: 15,
      BulletproofGlass: 50,
    },
  },
  Window1x1Flat: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1Flat.dds",
    Cost: {
      Girder: 10,
      BulletproofGlass: 25,
    },
  },
  Window1x1FlatInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1FlatInv.dds",
    Cost: {
      Girder: 10,
      BulletproofGlass: 25,
    },
  },
  Window3x3Flat: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window3x3Flat.dds",
    Cost: {
      Girder: 40,
      BulletproofGlass: 196,
    },
  },
  Window3x3FlatInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window3x3FlatInv.dds",
    Cost: {
      Girder: 40,
      BulletproofGlass: 196,
    },
  },
  Window2x3Flat: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window2x3Flat.dds",
    Cost: {
      Girder: 25,
      BulletproofGlass: 140,
    },
  },
  Window2x3FlatInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window2x3FlatInv.dds",
    Cost: {
      Girder: 25,
      BulletproofGlass: 140,
    },
  },
  SmallWindow1x2Slope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2Slope.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 3,
    },
  },
  SmallWindow1x2Inv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2Inv.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 3,
    },
  },
  SmallWindow1x2Face: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2Face.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 3,
    },
  },
  SmallWindow1x2SideLeft: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2SideLeft.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 3,
    },
  },
  SmallWindow1x2SideLeftInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2SideLeftInv.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 3,
    },
  },
  SmallWindow1x2SideRight: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2SideRight.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 3,
    },
  },
  SmallWindow1x2SideRightInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2SideRightInv.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 3,
    },
  },
  SmallWindow1x1Slope: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1Slope.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 2,
    },
  },
  SmallWindow1x1Face: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1Face.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 2,
    },
  },
  SmallWindow1x1Side: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1Side.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 2,
    },
  },
  SmallWindow1x1SideInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1SideInv.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 2,
    },
  },
  SmallWindow1x1Inv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1Inv.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 2,
    },
  },
  SmallWindow1x2Flat: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2Flat.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 3,
    },
  },
  SmallWindow1x2FlatInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x2FlatInv.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 3,
    },
  },
  SmallWindow1x1Flat: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1Flat.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 2,
    },
  },
  SmallWindow1x1FlatInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window1x1FlatInv.dds",
    Cost: {
      Girder: 1,
      BulletproofGlass: 2,
    },
  },
  SmallWindow3x3Flat: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window3x3Flat.dds",
    Cost: {
      Girder: 3,
      BulletproofGlass: 12,
    },
  },
  SmallWindow3x3FlatInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window3x3FlatInv.dds",
    Cost: {
      Girder: 3,
      BulletproofGlass: 12,
    },
  },
  SmallWindow2x3Flat: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window2x3Flat.dds",
    Cost: {
      Girder: 2,
      BulletproofGlass: 8,
    },
  },
  SmallWindow2x3FlatInv: {
    IconPath: "Textures\\GUI\\Icons\\Cubes\\Window2x3FlatInv.dds",
    Cost: {
      Girder: 2,
      BulletproofGlass: 8,
    },
  },
  /* ... ..... ... .. .. ....... ... .. ..... .. .... ..... .. ... . . */
  LargeArmorHalfCenterPanelLight: {
    Cost: {
      SteelPlate: 3,
    },
  },
  LargeBlockInsetPlantCouch: {
    Cost: {
      BulletproofGlass: 20,
      SmallTube: 10,
      Construction: 30,
      InteriorPlate: 30,
    },
  },
  LargeBlockInsetButtonPanel: {
    Cost: {
      Display: 10,
      Computer: 20,
      Construction: 20,
      InteriorPlate: 20,
    },
  },
  LargeBlockCryoRoom: {
    Cost: {
      BulletproofGlass: 10,
      Computer: 30,
      Medical: 3,
      Display: 8,
      Motor: 8,
      Construction: 20,
      InteriorPlate: 40,
    },
  },
  LargeBlockSmallFlatAtmosphericThrustDShape: {
    Cost: {
      Motor: 30,
      MetalGrid: 3,
      LargeTube: 3,
      Construction: 20,
      SteelPlate: 15,
    },
  },
  LargeBlockInsetKitchen: {
    Cost: {
      BulletproofGlass: 6,
      Motor: 6,
      LargeTube: 8,
      Construction: 30,
      InteriorPlate: 30,
    },
  },
  LargeBlockLargeFlatAtmosphericThrust: {
    Cost: {
      Motor: 400,
      MetalGrid: 15,
      LargeTube: 20,
      Construction: 25,
      SteelPlate: 90,
    },
  },
  LargeBlockSmallFlatAtmosphericThrust: {
    Cost: {
      Motor: 30,
      MetalGrid: 3,
      LargeTube: 3,
      Construction: 20,
      SteelPlate: 15,
    },
  },
  LargeBlockInsetEntertainmentCorner: {
    Cost: {
      Display: 8,
      Computer: 10,
      Construction: 20,
      InteriorPlate: 30,
    },
  },
  OffroadSmallShortSuspension2x2Mirrored: {
    Cost: {
      Motor: 1,
      SmallTube: 2,
      Construction: 5,
      SteelPlate: 5,
    },
  },
  OffroadSmallShortSuspension2x2: {
    Cost: {
      Motor: 1,
      SmallTube: 2,
      Construction: 5,
      SteelPlate: 5,
    },
  },
  SmallBlockLargeFlatAtmosphericThrustDShape: {
    Cost: {
      Motor: 30,
      MetalGrid: 3,
      LargeTube: 2,
      Construction: 14,
      SteelPlate: 8,
    },
  },
  TrussSlopedSmall: {
    Cost: {
      SmallTube: 1,
      Girder: 1,
    },
  },
  TrussSmall: {
    Cost: {
      SmallTube: 1,
      Girder: 2,
    },
  },
  SmallBlockSmallFlatAtmosphericThrust: {
    Cost: {
      Motor: 6,
      MetalGrid: 1,
      LargeTube: 1,
      Construction: 11,
      SteelPlate: 2,
    },
  },
  SmallBlockBeaconReskin: {
    Cost: {
      RadioCommunication: 4,
      Computer: 1,
      SmallTube: 1,
      Construction: 1,
      SteelPlate: 2,
    },
  },
  SmallArmorHalfCenterPanelLight: {
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockCapCockpit: {
    Cost: {
      BulletproofGlass: 10,
      Computer: 15,
      Display: 4,
      Motor: 1,
      Construction: 10,
      SteelPlate: 20,
    },
  },
  SmallShortSuspension3x3mirrored: {
    Cost: {
      Motor: 1,
      SmallTube: 2,
      Construction: 7,
      SteelPlate: 8,
    },
  },
  SmallShortSuspension3x3: {
    Cost: {
      Motor: 1,
      SmallTube: 2,
      Construction: 7,
      SteelPlate: 8,
    },
  },
  TrussHalfSmall: {
    Cost: {
      SmallTube: 1,
      Girder: 1,
    },
  },
  SmallRoundArmorPanelHeavy: {
    Cost: {
      MetalGrid: 1,
      SteelPlate: 2,
    },
  },
  LargeBlockConveyorCap: {
    Cost: {
      Construction: 10,
      InteriorPlate: 10,
    },
  },
  TrussFloor: {
    Cost: {
      SmallTube: 30,
      Girder: 24,
      Construction: 16,
    },
  },
  TrussFloorAngled: {
    Cost: {
      SmallTube: 30,
      Girder: 24,
      Construction: 16,
    },
  },
  TrussLadder: {
    Cost: {
      SmallTube: 30,
      Girder: 20,
      Construction: 20,
      InteriorPlate: 10,
    },
  },
  TrussFloorHalf: {
    Cost: {
      SmallTube: 20,
      Girder: 12,
      Construction: 14,
    },
  },
  LargeBlockConveyorPipeCap: {
    Cost: {
      Construction: 10,
      InteriorPlate: 10,
    },
  },
  LargeBarrelThree: {
    Cost: {
      Construction: 18,
      LargeTube: 3,
      SteelPlate: 15,
    },
  },
  TrussHalf: {
    Cost: {
      SmallTube: 5,
      Girder: 10,
    },
  },
  LargeFullBlockLCDPanel: {
    Cost: {
      BulletproofGlass: 6,
      Display: 10,
      Computer: 6,
      Construction: 20,
      InteriorPlate: 20,
    },
  },
  LargeWarningSignEaster9: {
    Cost: {
      InteriorPlate: 6,
      Construction: 4,
    },
  },
  TrussSloped: {
    Cost: {
      SmallTube: 5,
      Girder: 10,
    },
  },
  LargeBlockInsetBed: {
    Cost: {
      SmallTube: 8,
      Construction: 30,
      InteriorPlate: 60,
    },
  },
  LargeWarningSignEaster2: {
    Cost: {
      InteriorPlate: 6,
      Construction: 4,
    },
  },
  LargeBarrel: {
    Cost: {
      Construction: 6,
      LargeTube: 1,
      SteelPlate: 5,
    },
  },
  LargeWarningSignEaster3: {
    Cost: {
      InteriorPlate: 2,
      Construction: 4,
    },
  },
  LargeBlockInsetAquarium: {
    Cost: {
      BulletproofGlass: 10,
      Gravity: 1,
      SmallTube: 10,
      Motor: 1,
      Construction: 30,
      InteriorPlate: 30,
    },
  },
  Truss: {
    Cost: {
      SmallTube: 10,
      Girder: 20,
    },
  },
  LargeButtonPanelPedestal: {
    Cost: {
      Computer: 5,
      Construction: 10,
      InteriorPlate: 5,
    },
  },
  LargeBlockInsetBookshelf: {
    Cost: {
      Construction: 30,
      InteriorPlate: 30,
    },
  },
  LargeBlockWindTurbineReskin: {
    Cost: {
      Computer: 2,
      Girder: 24,
      Construction: 20,
      Motor: 8,
      InteriorPlate: 40,
    },
  },
  TrussAngled: {
    Cost: {
      SmallTube: 10,
      Girder: 20,
    },
  },
  LargeBarrelStack: {
    Cost: {
      Construction: 60,
      Girder: 8,
      MetalGrid: 4,
      LargeTube: 10,
      SteelPlate: 50,
    },
  },
  LargeCrate: {
    Cost: {
      Construction: 24,
      Motor: 4,
      SmallTube: 8,
      SteelPlate: 20,
    },
  },
  LargeMedicalRoomReskin: {
    Cost: {
      Medical: 15,
      Computer: 10,
      Display: 10,
      LargeTube: 5,
      SmallTube: 20,
      MetalGrid: 60,
      Construction: 80,
      InteriorPlate: 240,
    },
  },
  LargeControlPanelPedestal: {
    Cost: {
      Display: 1,
      Computer: 1,
      Construction: 10,
      InteriorPlate: 5,
    },
  },
  HoloLCDLarge: {
    Cost: {
      Computer: 8,
      Motor: 1,
      Construction: 10,
    },
  },
  LargeWarningSignEaster11: {
    Cost: {
      InteriorPlate: 4,
      Construction: 4,
    },
  },
  LargeBlockBeaconReskin: {
    Cost: {
      RadioCommunication: 40,
      Computer: 10,
      LargeTube: 20,
      Construction: 30,
      SteelPlate: 80,
    },
  },
  TrussFloorAngledInverted: {
    Cost: {
      SmallTube: 30,
      Construction: 16,
      Girder: 24,
    },
  },
  LargeBlockHalfBed: {
    Cost: {
      Display: 3,
      SmallTube: 6,
      Construction: 16,
      InteriorPlate: 14,
    },
  },
  LargeRoundArmorPanelLight: {
    Cost: {
      SteelPlate: 8,
    },
  },
  LargeRoundArmorPanelCornerLight: {
    Cost: {
      SteelPlate: 5,
    },
  },
  LargeBlockColorableSolarPanel: {
    Cost: {
      BulletproofGlass: 4,
      SolarCell: 32,
      Computer: 4,
      Girder: 12,
      Construction: 14,
      SteelPlate: 4,
    },
  },
  LargeBlockColorableSolarPanelCorner: {
    Cost: {
      BulletproofGlass: 2,
      SolarCell: 16,
      Computer: 4,
      Girder: 6,
      Construction: 7,
      SteelPlate: 2,
    },
  },
  LargeBlockColorableSolarPanelCornerInverted: {
    Cost: {
      BulletproofGlass: 2,
      SolarCell: 16,
      Computer: 4,
      Girder: 6,
      Construction: 7,
      SteelPlate: 2,
    },
  },
  /* ... ..... ... .. .. ....... ... .. ..... .. .... ..... .. ... . . */
  LargeBlockArmorRaisedSlopedCorner: {
    Cost: {
      SteelPlate: 18,
    },
  },
  LargeBlockArmorSquareSlopedCornerTip: {
    Cost: {
      SteelPlate: 6,
    },
  },
  LargeBlockArmorSquareSlopedCornerBase: {
    Cost: {
      SteelPlate: 18,
    },
  },
  LargeBlockArmorSquareSlopedCornerTipInv: {
    Cost: {
      SteelPlate: 9,
    },
  },
  LargeBlockHeavyArmorRaisedSlopedCorner: {
    Cost: {
      SteelPlate: 100,
      MetalGrid: 30,
    },
  },
  LargeBlockHeavyArmorSquareSlopedCornerTip: {
    Cost: {
      SteelPlate: 30,
      MetalGrid: 9,
    },
  },
  LargeBlockHeavyArmorSquareSlopedCornerBase: {
    Cost: {
      SteelPlate: 105,
      MetalGrid: 35,
    },
  },
  LargeBlockHeavyArmorSquareSlopedCornerTipInv: {
    Cost: {
      SteelPlate: 55,
      MetalGrid: 18,
    },
  },
  LargeBlockArmorSlopeTransitionBase: {
    Cost: {
      SteelPlate: 16,
    },
  },
  LargeBlockArmorSlopeTransition: {
    Cost: {
      SteelPlate: 10,
    },
  },
  LargeBlockArmorSlopeTransitionTip: {
    Cost: {
      SteelPlate: 5,
    },
  },
  LargeBlockArmorSlopeTransitionBaseMirrored: {
    Cost: {
      SteelPlate: 16,
    },
  },
  LargeBlockArmorSlopeTransitionMirrored: {
    Cost: {
      SteelPlate: 10,
    },
  },
  LargeBlockArmorSlopeTransitionTipMirrored: {
    Cost: {
      SteelPlate: 5,
    },
  },
  LargeBlockHeavyArmorSlopeTransitionBase: {
    Cost: {
      SteelPlate: 95,
      MetalGrid: 35,
    },
  },
  LargeBlockHeavyArmorSlopeTransition: {
    Cost: {
      SteelPlate: 60,
      MetalGrid: 18,
    },
  },
  LargeBlockHeavyArmorSlopeTransitionTip: {
    Cost: {
      SteelPlate: 30,
      MetalGrid: 9,
    },
  },
  LargeBlockHeavyArmorSlopeTransitionBaseMirrored: {
    Cost: {
      SteelPlate: 95,
      MetalGrid: 35,
    },
  },
  LargeBlockHeavyArmorSlopeTransitionMirrored: {
    Cost: {
      SteelPlate: 60,
      MetalGrid: 18,
    },
  },
  LargeBlockHeavyArmorSlopeTransitionTipMirrored: {
    Cost: {
      SteelPlate: 30,
      MetalGrid: 9,
    },
  },
  LargeCoverWallHalfMirrored: {
    Cost: {
      Construction: 6,
      SteelPlate: 2,
    },
  },
  LargeBlockDeskCornerInv: {
    Cost: {
      InteriorPlate: 60,
      Construction: 60,
    },
  },
  LargeBlockDeskChairlessCornerInv: {
    Cost: {
      InteriorPlate: 60,
      Construction: 60,
    },
  },
  LargeNeonTubesT: {
    Cost: {
      SmallTube: 9,
      InteriorPlate: 9,
      Construction: 3,
    },
  },
  LargeNeonTubesCircle: {
    Cost: {
      SmallTube: 12,
      InteriorPlate: 12,
      Construction: 4,
    },
  },
  LadderShaft: {
    Cost: {
      SmallTube: 50,
      InteriorPlate: 80,
      Construction: 40,
    },
  },
  RailingCenter: {
    Cost: {
      SmallTube: 6,
      Construction: 8,
    },
  },
  Railing2x1Right: {
    Cost: {
      SmallTube: 7,
      Construction: 10,
    },
  },
  Railing2x1Left: {
    Cost: {
      SmallTube: 7,
      Construction: 10,
    },
  },
  CatwalkHalf: {
    Cost: {
      Construction: 10,
      Girder: 2,
      SmallTube: 10,
    },
  },
  CatwalkHalfRailing: {
    Cost: {
      Construction: 14,
      Girder: 2,
      SmallTube: 16,
    },
  },
  CatwalkHalfCenterRailing: {
    Cost: {
      Construction: 14,
      Girder: 2,
      SmallTube: 16,
    },
  },
  CatwalkHalfOuterRailing: {
    Cost: {
      Construction: 14,
      Girder: 2,
      SmallTube: 16,
    },
  },
  ConveyorTubeT: {
    Cost: {
      Motor: 6,
      SmallTube: 14,
      Construction: 24,
      InteriorPlate: 16,
    },
  },
  ConveyorTubeDuct: {
    Cost: {
      Motor: 6,
      SmallTube: 12,
      Construction: 20,
      InteriorPlate: 14,
      SteelPlate: 25,
    },
  },
  ConveyorTubeDuctCurved: {
    Cost: {
      Motor: 6,
      SmallTube: 12,
      Construction: 20,
      InteriorPlate: 14,
      SteelPlate: 25,
    },
  },
  ConveyorTubeDuctT: {
    Cost: {
      Motor: 6,
      SmallTube: 14,
      Construction: 24,
      InteriorPlate: 16,
      SteelPlate: 22,
    },
  },
  LargeBlockConveyorPipeT: {
    Cost: {
      Motor: 6,
      SmallTube: 14,
      Construction: 24,
      InteriorPlate: 16,
    },
  },
  WindowRound: {
    Cost: {
      Girder: 15,
      BulletproofGlass: 45,
    },
  },
  WindowRoundInv: {
    Cost: {
      Girder: 15,
      BulletproofGlass: 45,
    },
  },
  WindowRoundCorner: {
    Cost: {
      Girder: 13,
      BulletproofGlass: 33,
    },
  },
  WindowRoundCornerInv: {
    Cost: {
      Girder: 13,
      BulletproofGlass: 33,
    },
  },
  WindowRoundFace: {
    Cost: {
      Girder: 9,
      BulletproofGlass: 21,
    },
  },
  WindowRoundFaceInv: {
    Cost: {
      Girder: 9,
      BulletproofGlass: 21,
    },
  },
  WindowRoundInwardsCorner: {
    Cost: {
      Girder: 13,
      BulletproofGlass: 20,
    },
  },
  WindowRoundInwardsCornerInv: {
    Cost: {
      Girder: 13,
      BulletproofGlass: 20,
    },
  },
  HalfWindowDiagonal: {
    Cost: {
      Girder: 6,
      BulletproofGlass: 14,
      SteelPlate: 14,
    },
  },
  HalfWindowRound: {
    Cost: {
      Girder: 7,
      BulletproofGlass: 18,
      SteelPlate: 16,
    },
  },
  BridgeWindow1x1FaceInverted: {
    Cost: {
      Girder: 5,
      BulletproofGlass: 12,
      SteelPlate: 6,
      InteriorPlate: 12,
    },
  },
  GatT_Large: {
    Cost: {
      SteelPlate: 15,
      Construction: 30,
      Computer: 10,
      Motor: 6,
      SmallTube: 6,
      MetalGrid: 10,
    },
  },
  CompactTurretMissile_Large: {
    Cost: {
      SteelPlate: 10,
      Construction: 40,
      Computer: 12,
      Motor: 8,
      LargeTube: 12,
      MetalGrid: 2,
    },
  },
  LargeGatlingTurret_MK1: {
    Cost: {
      SteelPlate: 40,
      Construction: 40,
      Computer: 10,
      Motor: 8,
      SmallTube: 6,
      MetalGrid: 15,
      AlliageGatling: 40,
    },
  },
  LargeMissileTurret_MK1: {
    Cost: {
      SteelPlate: 40,
      Construction: 50,
      Computer: 10,
      Motor: 16,
      LargeTube: 6,
      MetalGrid: 15,
      AlliageArmeMissiles: 40,
    },
  },
  LargeMissileLauncher_MK1: {
    Cost: {
      SteelPlate: 35,
      Construction: 8,
      Computer: 4,
      Motor: 6,
      LargeTube: 25,
      MetalGrid: 30,
      AlliageArmeMissiles: 35,
    },
  },
  CompactTurretMissile_Large_MK1: {
    Cost: {
      SteelPlate: 10,
      Construction: 40,
      Computer: 12,
      Motor: 8,
      LargeTube: 12,
      MetalGrid: 2,
      AlliageArmeMissiles: 10,
    },
  },
  GatT_Large_MK1: {
    Cost: {
      SteelPlate: 15,
      Construction: 30,
      Computer: 10,
      Motor: 6,
      SmallTube: 6,
      MetalGrid: 10,
      AlliageGatling: 20,
    },
  },
  LargeBlockMediumCalibreTurret_MK1: {
    Cost: {
      SteelPlate: 300,
      Construction: 280,
      Computer: 20,
      Motor: 20,
      LargeTube: 30,
      MetalGrid: 30,
      AlliageArtillerie: 400,
    },
  },
  LargeCalibreTurret_MK1: {
    Cost: {
      SteelPlate: 450,
      Construction: 400,
      Computer: 20,
      Motor: 30,
      LargeTube: 40,
      MetalGrid: 50,
      AlliageArtillerie: 450,
    },
  },
  LargeBlockLargeCalibreGun_MK1: {
    Cost: {
      SteelPlate: 350,
      Construction: 20,
      Computer: 5,
      LargeTube: 20,
      MetalGrid: 20,
      AlliageArtillerie: 250,
    },
  },
  LargeRailgun_MK1: {
    Cost: {
      SteelPlate: 350,
      AlliageRailgun: 350,
      Computer: 100,
      PowerCell: 100,
      LargeTube: 60,
      Superconductor: 150,
      Construction: 150,
    },
  },
  Basic_all1_mk1: {
    Cost: {
      SteelPlate: 970,
      Computer: 80,
      PuceOpale: 1,
      Display: 4,
      Motor: 10,
      ComposantBismuth: 600,
    },
  },
  all1_mk1: {
    Cost: {
      SteelPlate: 970,
      Computer: 80,
      Display: 4,
      AlliageAssembleur: 120,
      ComposantNPCMk1: 150,
      PuceOpaleMk1: 3,
      Motor: 10,
      ComposantBismuth: 600,
    },
  },
  assPuce: {
    Cost: {
      SteelPlate: 9500,
      Motor: 1200,
      ComposantBismuth: 600,
      Computer: 6000,
    },
  },
  LSConteneuropale: {
    Cost: {
      InteriorPlate: 40,
      Construction: 40,
      AlliageConteneurCargo: 18,
      Computer: 2,
      Display: 1,
      Motor: 4,
      SmallTube: 20,
      MetalGrid: 4,
    },
  },
  LLConteneur: {
    Cost: {
      InteriorPlate: 360,
      Construction: 80,
      AlliageConteneurCargo: 80,
      Computer: 8,
      Display: 1,
      Motor: 20,
      SmallTube: 60,
      MetalGrid: 24,
    },
  },
  LargeOreDetectoropale: {
    Cost: {
      SteelPlate: 50,
      AlliageDetecteur: 50,
      Detector: 20,
      Computer: 25,
      Motor: 5,
      Construction: 40,
    },
  },
  LargeBlockBatteryBlockopale: {
    Cost: {
      SteelPlate: 80,
      AlliageBatterie: 80,
      Construction: 30,
      PuceOpale: 12,
      Computer: 25,
      PowerCell: 80,
    },
  },
  LargeBlockWindTurbineopale: {
    Cost: {
      InteriorPlate: 40,
      AlliageEolienne: 24,
      Computer: 2,
      Girder: 24,
      PuceOpaleMk1: 3,
      Construction: 20,
      Motor: 8,
    },
  },
  LargeBlockSolarPanelopale: {
    Cost: {
      BulletproofGlass: 4,
      AlliageCelluleSolaire: 12,
      Construction: 14,
      SolarCell: 32,
      Computer: 4,
      Girder: 12,
      PuceOpaleMk1: 3,
    },
  },
  LGyro: {
    Cost: {
      SteelPlate: 600,
      AlliageGyroscope: 315,
      Computer: 5,
      Motor: 4,
      MetalGrid: 50,
      PuceOpale: 6,
      LargeTube: 4,
      Construction: 40,
    },
  },
  OxygenTankLargeop: {
    Cost: {
      SteelPlate: 80,
      AlliageReservoirHydrogene: 30,
      Construction: 40,
      Computer: 8,
      SmallTube: 60,
      LargeTube: 40,
      PuceOpale: 3,
    },
  },
  LargeHydrogenTankop: {
    Cost: {
      SteelPlate: 280,
      AlliageReservoirHydrogene: 160,
      Construction: 80,
      Computer: 8,
      SmallTube: 60,
      LargeTube: 80,
      PuceOpale: 9,
    },
  },
  LargeHydrogenTankSmallop: {
    Cost: {
      SteelPlate: 80,
      AlliageReservoirHydrogene: 30,
      Construction: 40,
      Computer: 8,
      PuceOpale: 3,
      SmallTube: 60,
      LargeTube: 40,
    },
  },
  LargeHydrogenTankIndustrialopale: {
    Cost: {
      SteelPlate: 320,
      AlliageReservoirHydrogene: 80,
      Construction: 40,
      Computer: 8,
      PuceOpale: 9,
      SmallTube: 60,
      LargeTube: 80,
    },
  },
  JumpDriveopale: {
    Cost: {
      SteelPlate: 60,
      AlliageSautSpatiale: 20,
      Construction: 40,
      Computer: 300,
      PuceOpaleMk1: 3,
      Superconductor: 1000,
      PowerCell: 120,
      Detector: 20,
      GravityGenerator: 20,
      MetalGrid: 50,
    },
  },
  raf1: {
    Cost: {
      SteelPlate: 985,
      Motor: 120,
      ComposantBismuth: 300,
      Computer: 50,
    },
  },
  raf_mk1: {
    Cost: {
      SteelPlate: 1600,
      Motor: 480,
      Computer: 400,
      PuceOpaleMk1: 15,
      SmallTube: 620,
      MetalGrid: 125,
      LargeTube: 40,
      AlliageRaffinerie: 1200,
      ComposantBismuth: 580,
    },
  },
  LargeProductivityModuleopale: {
    Cost: {
      Motor: 16,
      Computer: 60,
      SmallTube: 20,
      PuceOpaleMk1: 6,
      AlliageModuleVitesse: 60,
      MetalGrid: 20,
      LargeTube: 20,
      ComposantBismuth: 40,
      SteelPlate: 1100,
    },
  },
  LargeEffectivenessModuleopale: {
    Cost: {
      Motor: 4,
      Superconductor: 20,
      SmallTube: 15,
      PuceOpaleMk1: 6,
      AlliageModuleRendement: 20,
      SteelPlate: 140,
      ComposantBismuth: 50,
    },
  },
  LargeEnergyModuleopale: {
    Cost: {
      Motor: 4,
      PowerCell: 20,
      PuceOpaleMk1: 6,
      AlliageModuleEnergetique: 20,
      SmallTube: 15,
      SteelPlate: 140,
      ComposantBismuth: 40,
    },
  },
  Gpetition: {
    Cost: {
      SteelPlate: 25,
      AlliagePropulseurIonnique: 22,
      Construction: 60,
      Thrust: 80,
      LargeTube: 8,
    },
  },
  Ggrosion: {
    Cost: {
      SteelPlate: 150,
      AlliagePropulseurIonnique: 150,
      Construction: 100,
      Thrust: 960,
      LargeTube: 50,
    },
  },
  LargeBlockSmallThrustSciFiop: {
    Cost: {
      SteelPlate: 25,
      AlliagePropulseurIonnique: 22,
      Construction: 60,
      Thrust: 80,
      LargeTube: 8,
    },
  },
  LargeBlockLargeThrustSciFiop: {
    Cost: {
      SteelPlate: 150,
      AlliagePropulseurIonnique: 150,
      Construction: 100,
      Thrust: 960,
      LargeTube: 50,
    },
  },
  Ggrosatmo: {
    Cost: {
      SteelPlate: 230,
      AlliagePropulseurAtmospherique: 230,
      Construction: 60,
      Motor: 1100,
      MetalGrid: 40,
      LargeTube: 50,
    },
  },
  Gpetitatmo: {
    Cost: {
      SteelPlate: 25,
      AlliagePropulseurAtmospherique: 22,
      Construction: 20,
      Motor: 110,
      MetalGrid: 10,
      LargeTube: 8,
    },
  },
  LargeBlockLargeAtmosphericThrustSciFiop: {
    Cost: {
      SteelPlate: 230,
      AlliagePropulseurAtmospherique: 230,
      Construction: 60,
      Motor: 1100,
      MetalGrid: 40,
      LargeTube: 50,
    },
  },
  LargeBlockSmallAtmosphericThrustSciFiop: {
    Cost: {
      SteelPlate: 25,
      AlliagePropulseurAtmospherique: 22,
      Construction: 20,
      Motor: 110,
      MetalGrid: 10,
      LargeTube: 8,
    },
  },
  Spetitgroshydro: {
    Cost: {
      SteelPlate: 25,
      AlliagePropulseurHydrogene: 28,
      Construction: 60,
      LargeTube: 8,
      MetalGrid: 40,
    },
  },
  Ggroshydro: {
    Cost: {
      SteelPlate: 150,
      AlliagePropulseurHydrogene: 150,
      Construction: 180,
      LargeTube: 40,
      MetalGrid: 250,
    },
  },
  LargeBlockLargeHydrogenThrustIndustrialop: {
    Cost: {
      SteelPlate: 150,
      AlliagePropulseurHydrogene: 150,
      Construction: 180,
      LargeTube: 40,
      MetalGrid: 250,
    },
  },
  LargeBlockSmallHydrogenThrustIndustrialop: {
    Cost: {
      SteelPlate: 25,
      AlliagePropulseurHydrogene: 28,
      Construction: 60,
      LargeTube: 8,
      MetalGrid: 40,
    },
  },
  RotaryAirlock: {
    Cost: {
      SteelPlate: 8,
      Construction: 40,
      BulletproofGlass: 10,
      InteriorPlate: 8,
      Computer: 2,
      Display: 2,
      Motor: 2,
      SmallTube: 4,
      MetalGrid: 4,
    },
  },
  RotaryAirlockCorner: {
    Cost: {
      SteelPlate: 8,
      Construction: 40,
      BulletproofGlass: 20,
      InteriorPlate: 8,
      Computer: 2,
      Display: 2,
      Motor: 2,
      SmallTube: 4,
      MetalGrid: 4,
    },
  },
  Refinerybronze: {
    Cost: {
      SteelPlate: 1200,
      Computer: 20,
      MetalGrid: 20,
      Motor: 16,
      LargeTube: 20,
      Construction: 40,
      PuceBronze: 1,
    },
  },
  Refineryargent: {
    Cost: {
      SteelPlate: 1200,
      Computer: 20,
      MetalGrid: 20,
      Motor: 16,
      LargeTube: 20,
      Construction: 40,
      PuceArgent: 1,
    },
  },
  Refineryor: {
    Cost: {
      SteelPlate: 1200,
      Computer: 20,
      MetalGrid: 20,
      Motor: 16,
      LargeTube: 20,
      Construction: 40,
      PuceOr: 1,
    },
  },
  Assemblerbronze: {
    Cost: {
      SteelPlate: 140,
      Computer: 160,
      MetalGrid: 10,
      Display: 10,
      Motor: 20,
      Construction: 80,
      PuceBronze: 1,
    },
  },
  Assembleror: {
    Cost: {
      SteelPlate: 140,
      Computer: 160,
      MetalGrid: 10,
      Display: 10,
      Motor: 20,
      Construction: 80,
      PuceOr: 1,
    },
  },
  Assemblerargent: {
    Cost: {
      SteelPlate: 140,
      Computer: 160,
      MetalGrid: 10,
      Display: 10,
      Motor: 20,
      Construction: 80,
      PuceArgent: 1,
    },
  },
  LargeShipWelderBronze: {
    Cost: {
      SteelPlate: 20,
      Construction: 30,
      Computer: 2,
      Motor: 2,
      LargeTube: 1,
      PuceBronze: 1,
    },
  },
  LargeShipGrinderBronze: {
    Cost: {
      SteelPlate: 20,
      Construction: 30,
      Computer: 2,
      Motor: 4,
      LargeTube: 1,
      PuceBronze: 1,
    },
  },
  LargeBlockDrillBronze: {
    Cost: {
      SteelPlate: 300,
      Computer: 5,
      Motor: 5,
      LargeTube: 12,
      Construction: 40,
      PuceBronze: 1,
    },
  },
  SmallBlockArmorRaisedSlopedCorner: {
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorSquareSlopedCornerTip: {
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorSquareSlopedCornerTipInv: {
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorSquareSlopedCornerBase: {
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockHeavyArmorRaisedSlopedCorner: {
    Cost: {
      SteelPlate: 4,
      MetalGrid: 2,
    },
  },
  SmallBlockHeavyArmorSquareSlopedCornerTip: {
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallBlockHeavyArmorSquareSlopedCornerBase: {
    Cost: {
      SteelPlate: 4,
      MetalGrid: 2,
    },
  },
  SmallBlockHeavyArmorSquareSlopedCornerTipInv: {
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  SmallBlockHeavyArmorSlopeTransition: {
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  SmallBlockHeavyArmorSlopeTransitionTip: {
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallBlockHeavyArmorSlopeTransitionBaseMirrored: {
    Cost: {
      SteelPlate: 4,
      MetalGrid: 2,
    },
  },
  SmallBlockHeavyArmorSlopeTransitionMirrored: {
    Cost: {
      SteelPlate: 3,
      MetalGrid: 1,
    },
  },
  SmallBlockHeavyArmorSlopeTransitionTipMirrored: {
    Cost: {
      SteelPlate: 2,
      MetalGrid: 1,
    },
  },
  SmallBlockHeavyArmorSlopeTransitionBase: {
    Cost: {
      SteelPlate: 4,
      MetalGrid: 2,
    },
  },
  SmallBlockArmorSlopeTransitionBaseMirrored: {
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorSlopeTransitionMirrored: {
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorSlopeTransitionTipMirrored: {
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorSlopeTransitionBase: {
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorSlopeTransition: {
    Cost: {
      SteelPlate: 1,
    },
  },
  SmallBlockArmorSlopeTransitionTip: {
    Cost: {
      SteelPlate: 1,
    },
  },
  AQD_SG_GravityGenerator: {
    Cost: {
      SteelPlate: 53,
      Computer: 15,
      Motor: 2,
      LargeTube: 2,
      Construction: 20,
      GravityGenerator: 2,
    },
  },
  SmallBlockConveyorConverter: {
    Cost: {
      InteriorPlate: 6,
      Construction: 8,
      Motor: 2,
      SmallTube: 6,
    },
  },
  SmallAdvancedStatorSmall: {
    Cost: {
      SteelPlate: 5,
      Computer: 1,
      Motor: 1,
      Construction: 5,
      SmallTube: 1,
    },
  },
  SmallAdvancedRotorSmall: {
    Cost: {
      SteelPlate: 10,
      Computer: 2,
      Motor: 2,
      Construction: 6,
      SmallTube: 1,
    },
  },
  AQD_SG_BasicRefinery: {
    Cost: {
      SteelPlate: 30,
      Computer: 2,
      Motor: 2,
      Construction: 5,
    },
  },
  AQD_SG_BasicAssembler: {
    Cost: {
      SteelPlate: 40,
      Computer: 40,
      Display: 2,
      Motor: 5,
      Construction: 20,
    },
  },
  SmallNeonTubesBendUp: {
    Cost: {
      InteriorPlate: 1,
      SmallTube: 1,
      Construction: 1,
    },
  },
  SmallNeonTubesU: {
    Cost: {
      InteriorPlate: 1,
      SmallTube: 1,
      Construction: 1,
    },
  },
  SmallNeonTubesT: {
    Cost: {
      InteriorPlate: 1,
      SmallTube: 1,
      Construction: 1,
    },
  },
  SmallNeonTubesCircle: {
    Cost: {
      InteriorPlate: 1,
      SmallTube: 1,
      Construction: 1,
    },
  },
  SmallNeonTubesStraight1: {
    Cost: {
      InteriorPlate: 1,
      SmallTube: 1,
      Construction: 1,
    },
  },
  SmallNeonTubesStraight2: {
    Cost: {
      InteriorPlate: 1,
      SmallTube: 1,
      Construction: 1,
    },
  },
  SmallNeonTubesCorner: {
    Cost: {
      InteriorPlate: 1,
      SmallTube: 1,
      Construction: 1,
    },
  },
  SmallNeonTubesBendDown: {
    Cost: {
      InteriorPlate: 1,
      SmallTube: 1,
      Construction: 1,
    },
  },
  SmallNeonTubesStraightEnd1: {
    Cost: {
      InteriorPlate: 1,
      SmallTube: 1,
      Construction: 1,
    },
  },
  SmallNeonTubesStraightDown: {
    Cost: {
      InteriorPlate: 1,
      SmallTube: 1,
      Construction: 1,
    },
  },
  AQD_SG_Jumpdrive: {
    Cost: {
      SteelPlate: 30,
      Construction: 10,
      Computer: 100,
      Superconductor: 200,
      PowerCell: 60,
      Detector: 10,
      GravityGenerator: 10,
      MetalGrid: 10,
    },
  },
  SmallMissileLauncher_V: {
    Cost: {
      SteelPlate: 15,
      Construction: 40,
      Computer: 10,
      Motor: 8,
      LargeTube: 2,
      MetalGrid: 5,
    },
  },
  SmallWindowRound: {
    Cost: {
      BulletproofGlass: 2,
      Girder: 1,
    },
  },
  SmallWindowRoundInv: {
    Cost: {
      BulletproofGlass: 2,
      Girder: 1,
    },
  },
  SmallWindowRoundCorner: {
    Cost: {
      BulletproofGlass: 2,
      Girder: 1,
    },
  },
  SmallWindowRoundCornerInv: {
    Cost: {
      BulletproofGlass: 2,
      Girder: 1,
    },
  },
  SmallWindowRoundFace: {
    Cost: {
      BulletproofGlass: 2,
      Girder: 1,
    },
  },
  SmallWindowRoundFaceInv: {
    Cost: {
      BulletproofGlass: 2,
      Girder: 1,
    },
  },
  SmallWindowRoundInwardsCorner: {
    Cost: {
      BulletproofGlass: 2,
      Girder: 1,
    },
  },
  SmallWindowRoundInwardsCornerInv: {
    Cost: {
      BulletproofGlass: 2,
      Girder: 1,
    },
  },
  EngineerPlushie: {
    Cost: {
      EngineerPlushie: 1,
    },
  },
  ConveyorTubeDuctSmall: {
    Cost: {
      SteelPlate: 2,
      InteriorPlate: 1,
      Construction: 1,
      Motor: 1,
    },
  },
  ConveyorTubeDuctSmallCurved: {
    Cost: {
      SteelPlate: 2,
      InteriorPlate: 1,
      Construction: 1,
      Motor: 1,
    },
  },
  ConveyorTubeDuctSmallT: {
    Cost: {
      SteelPlate: 2,
      InteriorPlate: 2,
      Construction: 2,
      Motor: 1,
    },
  },
  AQD_SG_ConnectorSmall: {
    Cost: {
      SteelPlate: 5,
      Computer: 4,
      Motor: 1,
      SmallTube: 2,
      Construction: 4,
    },
  },
  SmallBlockSmallThrustSciFiop: {
    Cost: {
      SteelPlate: 1,
      Construction: 1,
      Thrust: 1,
      LargeTube: 1,
    },
  },
  SmallBlockLargeThrustSciFiop: {
    Cost: {
      SteelPlate: 5,
      Construction: 1,
      Thrust: 12,
      LargeTube: 5,
    },
  },
  Sgrosatmo: {
    Cost: {
      SteelPlate: 20,
      AlliagePropulseurAtmospherique: 20,
      Construction: 30,
      Motor: 90,
      MetalGrid: 8,
      LargeTube: 4,
    },
  },
  Spetitatmo: {
    Cost: {
      SteelPlate: 3,
      AlliagePropulseurAtmospherique: 6,
      Construction: 22,
      Motor: 18,
      MetalGrid: 1,
      LargeTube: 1,
    },
  },
  SmallBlockLargeAtmosphericThrustSciFiop: {
    Cost: {
      SteelPlate: 5,
      Thrust: 12,
      LargeTube: 5,
      Construction: 2,
    },
  },
  SmallBlockSmallAtmosphericThrustSciFiop: {
    Cost: {
      SteelPlate: 2,
      Thrust: 1,
      LargeTube: 1,
      Construction: 2,
    },
  },
  SmallBlockLargeHydrogenThrustIndustrialop: {
    Cost: {
      SteelPlate: 30,
      Construction: 30,
      LargeTube: 10,
      MetalGrid: 22,
    },
  },
  SmallBlockSmallHydrogenThrustIndustrialop: {
    Cost: {
      SteelPlate: 7,
      Construction: 15,
      LargeTube: 2,
      MetalGrid: 2,
    },
  },
  GatT_Small: {
    Cost: {
      SteelPlate: 10,
      Construction: 30,
      Computer: 10,
      Motor: 4,
      SmallTube: 6,
      MetalGrid: 5,
    },
  },
  CompactTurretMissile_Small: {
    Cost: {
      SteelPlate: 10,
      Construction: 40,
      Computer: 12,
      Motor: 8,
      LargeTube: 4,
      MetalGrid: 2,
    },
  },
  AngledInteriorWallA: {
    Cost: {
      Construction: 10,
      InteriorPlate: 25,
    },
  },
  AngledInteriorWallB: {
    Cost: {
      Construction: 10,
      InteriorPlate: 25,
    },
  },
  LargeBlockInsetLight: {
    Cost: {
      InteriorPlate: 20,
      Construction: 10,
      SteelPlate: 10,
    },
  },
  PipeWorkBlockA: {
    Cost: {
      LargeTube: 10,
      InteriorPlate: 20,
      Construction: 20,
    },
  },
  PipeWorkBlockB: {
    Cost: {
      LargeTube: 10,
      InteriorPlate: 20,
      Construction: 20,
    },
  },
  LargeBlockAccessPanel1: {
    Cost: {
      InteriorPlate: 5,
      Computer: 5,
      Construction: 15,
    },
  },
  LargeBlockAccessPanel2: {
    Cost: {
      InteriorPlate: 5,
      SmallTube: 10,
      Construction: 15,
    },
  },
  LargeBlockAccessPanel3: {
    Cost: {
      InteriorPlate: 3,
      Computer: 3,
      Construction: 8,
    },
  },
  LargeBlockAccessPanel4: {
    Cost: {
      InteriorPlate: 10,
      Construction: 10,
    },
  },
  AirVentFan: {
    Cost: {
      Computer: 5,
      Motor: 10,
      Construction: 20,
      SteelPlate: 30,
    },
  },
  AirVentFanFull: {
    Cost: {
      Computer: 5,
      Motor: 10,
      Construction: 30,
      SteelPlate: 45,
    },
  },
  LargeCameraTopMounted: {
    Cost: {
      Computer: 3,
      SteelPlate: 2,
    },
  },
  LargeWarningSign1: {
    Cost: {
      InteriorPlate: 6,
      Construction: 4,
    },
  },
  LargeWarningSign2: {
    Cost: {
      InteriorPlate: 6,
      Construction: 4,
    },
  },
  LargeWarningSign3: {
    Cost: {
      InteriorPlate: 6,
      Construction: 4,
    },
  },
  LargeWarningSign4: {
    Cost: {
      InteriorPlate: 2,
      Construction: 4,
    },
  },
  LargeWarningSign5: {
    Cost: {
      InteriorPlate: 2,
      Construction: 4,
    },
  },
  LargeWarningSign6: {
    Cost: {
      InteriorPlate: 2,
      Construction: 4,
    },
  },
  LargeWarningSign7: {
    Cost: {
      InteriorPlate: 2,
      Construction: 4,
    },
  },
  LargeWarningSign8: {
    Cost: {
      InteriorPlate: 4,
      Construction: 4,
    },
  },
  LargeWarningSign9: {
    Cost: {
      InteriorPlate: 4,
      Construction: 4,
    },
  },
  LargeWarningSign10: {
    Cost: {
      InteriorPlate: 4,
      Construction: 4,
    },
  },
  LargeWarningSign11: {
    Cost: {
      InteriorPlate: 4,
      Construction: 4,
    },
  },
  LargeWarningSign12: {
    Cost: {
      InteriorPlate: 4,
      Construction: 4,
    },
  },
  LargeWarningSign13: {
    Cost: {
      InteriorPlate: 4,
      Construction: 4,
    },
  },
  LargeBlockSensorReskin: {
    Cost: {
      SteelPlate: 2,
      Detector: 6,
      RadioCommunication: 4,
      Computer: 6,
      Construction: 5,
      InteriorPlate: 5,
    },
  },
  LargeProgrammableBlockReskin: {
    Cost: {
      Computer: 2,
      Display: 1,
      Motor: 1,
      LargeTube: 2,
      Construction: 4,
      SteelPlate: 21,
    },
  },
  TimerBlockReskinLarge: {
    Cost: {
      Computer: 5,
      Construction: 30,
      InteriorPlate: 6,
    },
  },
  EmotionControllerLarge: {
    Cost: {
      BulletproofGlass: 6,
      Display: 12,
      Computer: 20,
      Construction: 30,
      InteriorPlate: 10,
    },
  },
  EventControllerLarge: {
    Cost: {
      Display: 4,
      Computer: 10,
      Construction: 30,
      InteriorPlate: 10,
    },
  },
  LargePathRecorderBlock: {
    Cost: {
      SteelPlate: 20,
      Computer: 20,
      Motor: 4,
      Detector: 20,
      Construction: 30,
      InteriorPlate: 20,
    },
  },
  LargeBasicMission: {
    Cost: {
      SteelPlate: 20,
      Computer: 20,
      Motor: 4,
      Detector: 20,
      Construction: 30,
      InteriorPlate: 20,
    },
  },
  LargeFlightMovement: {
    Cost: {
      SteelPlate: 20,
      Computer: 20,
      Motor: 4,
      Detector: 20,
      Construction: 30,
      InteriorPlate: 20,
    },
  },
  LargeDefensiveCombat: {
    Cost: {
      SteelPlate: 20,
      Computer: 20,
      Motor: 4,
      Detector: 20,
      Construction: 30,
      InteriorPlate: 20,
    },
  },
  LargeOffensiveCombat: {
    Cost: {
      SteelPlate: 20,
      Computer: 20,
      Motor: 4,
      Detector: 20,
      Construction: 30,
      InteriorPlate: 20,
    },
  },
  AirDuct1: {
    Cost: {
      SteelPlate: 10,
      LargeTube: 4,
      Construction: 30,
      InteriorPlate: 20,
    },
  },
  AirDuct2: {
    Cost: {
      SteelPlate: 10,
      LargeTube: 4,
      Construction: 30,
      InteriorPlate: 20,
    },
  },
  AirDuctLight: {
    Cost: {
      SteelPlate: 10,
      LargeTube: 4,
      Construction: 30,
      InteriorPlate: 20,
    },
  },
  AirDuctCorner: {
    Cost: {
      SteelPlate: 10,
      LargeTube: 4,
      Construction: 30,
      InteriorPlate: 20,
    },
  },
  AirDuctT: {
    Cost: {
      SteelPlate: 8,
      LargeTube: 4,
      Construction: 30,
      InteriorPlate: 15,
    },
  },
  AirDuctX: {
    Cost: {
      SteelPlate: 5,
      LargeTube: 4,
      Construction: 30,
      InteriorPlate: 10,
    },
  },
  AirDuctRamp: {
    Cost: {
      SteelPlate: 5,
      LargeTube: 4,
      Construction: 30,
      InteriorPlate: 10,
    },
  },
  AirDuctGrate: {
    Cost: {
      Construction: 10,
      InteriorPlate: 10,
    },
  },
  OffroadSuspension2x2: {
    Cost: {
      LargeTube: 15,
      Construction: 40,
      SteelPlate: 50,
    },
  },
  OffroadSuspension2x2Mirrored: {
    Cost: {
      LargeTube: 15,
      Construction: 40,
      SteelPlate: 50,
    },
  },
  OffroadWheel2x2: {
    Cost: {
      LargeTube: 15,
      Construction: 40,
      SteelPlate: 50,
    },
  },
  Wheel2x2: {
    Cost: {
      LargeTube: 15,
      Construction: 40,
      SteelPlate: 50,
    },
  },
  AirVentFull: {
    Cost: {
      Computer: 5,
      Motor: 10,
      Construction: 30,
      SteelPlate: 45,
    },
  },
  OffroadRealWheel2x2: {
    Cost: {
      Motor: 6,
      SmallTube: 12,
      LargeTube: 6,
      Construction: 15,
      SteelPlate: 25,
    },
  },
  OffroadRealWheel2x2Mirrored: {
    Cost: {
      Motor: 6,
      SmallTube: 12,
      LargeTube: 6,
      Construction: 15,
      SteelPlate: 25,
    },
  },
  EventControllerSmall: {
    Cost: {
      Display: 1,
      Computer: 2,
      Construction: 3,
      InteriorPlate: 2,
    },
  },
  SmallPathRecorderBlock: {
    Cost: {
      SteelPlate: 2,
      Computer: 10,
      Motor: 2,
      Detector: 4,
      Construction: 5,
      InteriorPlate: 2,
    },
  },
  SmallBasicMission: {
    Cost: {
      SteelPlate: 2,
      Computer: 10,
      Motor: 2,
      Detector: 4,
      Construction: 5,
      InteriorPlate: 2,
    },
  },
  SmallFlightMovement: {
    Cost: {
      SteelPlate: 2,
      Computer: 10,
      Motor: 2,
      Detector: 4,
      Construction: 5,
      InteriorPlate: 2,
    },
  },
  SmallDefensiveCombat: {
    Cost: {
      SteelPlate: 2,
      Computer: 10,
      Motor: 2,
      Detector: 4,
      Construction: 5,
      InteriorPlate: 2,
    },
  },
  SmallOffensiveCombat: {
    Cost: {
      SteelPlate: 2,
      Computer: 10,
      Motor: 2,
      Detector: 4,
      Construction: 5,
      InteriorPlate: 2,
    },
  },
  SmallBlockInsetLight: {
    Cost: {
      InteriorPlate: 1,
      Construction: 2,
      SteelPlate: 1,
    },
  },
  SmallBlockAccessPanel2: {
    Cost: {
      InteriorPlate: 1,
      SmallTube: 1,
      Construction: 2,
    },
  },
  SmallBlockAccessPanel3: {
    Cost: {
      InteriorPlate: 1,
      SmallTube: 1,
      Construction: 4,
    },
  },
  SmallBlockAccessPanel4: {
    Cost: {
      InteriorPlate: 10,
      Construction: 10,
    },
  },
  SmallAirVentFan: {
    Cost: {
      Computer: 5,
      Motor: 2,
      Construction: 10,
      SteelPlate: 3,
    },
  },
  SmallAirVentFanFull: {
    Cost: {
      Computer: 5,
      Motor: 2,
      Construction: 15,
      SteelPlate: 5,
    },
  },
  SmallCameraTopMounted: {
    Cost: {
      Computer: 3,
      SteelPlate: 2,
    },
  },
  SmallWarningSign1: {
    Cost: {
      InteriorPlate: 6,
      Construction: 4,
    },
  },
  SmallWarningSign2: {
    Cost: {
      InteriorPlate: 2,
      Construction: 4,
    },
  },
  SmallWarningSign3: {
    Cost: {
      InteriorPlate: 6,
      Construction: 4,
    },
  },
  SmallWarningSign4: {
    Cost: {
      InteriorPlate: 2,
      Construction: 4,
    },
  },
  SmallWarningSign5: {
    Cost: {
      InteriorPlate: 6,
      Construction: 4,
    },
  },
  SmallWarningSign6: {
    Cost: {
      InteriorPlate: 2,
      Construction: 4,
    },
  },
  SmallWarningSign7: {
    Cost: {
      InteriorPlate: 2,
      Construction: 4,
    },
  },
  SmallWarningSign8: {
    Cost: {
      InteriorPlate: 1,
      Construction: 2,
    },
  },
  SmallWarningSign9: {
    Cost: {
      InteriorPlate: 1,
      Construction: 2,
    },
  },
  SmallWarningSign10: {
    Cost: {
      InteriorPlate: 1,
      Construction: 2,
    },
  },
  SmallWarningSign11: {
    Cost: {
      InteriorPlate: 1,
      Construction: 2,
    },
  },
  SmallWarningSign12: {
    Cost: {
      InteriorPlate: 1,
      Construction: 2,
    },
  },
  SmallWarningSign13: {
    Cost: {
      InteriorPlate: 1,
      Construction: 2,
    },
  },
  SmallBlockAccessPanel1: {
    Cost: {
      InteriorPlate: 2,
      SmallTube: 2,
      Construction: 8,
    },
  },
  SmallBlockSensorReskin: {
    Cost: {
      SteelPlate: 2,
      Detector: 6,
      RadioCommunication: 4,
      Computer: 6,
      Construction: 5,
      InteriorPlate: 5,
    },
  },
  SmallProgrammableBlockReskin: {
    Cost: {
      Computer: 2,
      Display: 1,
      Motor: 1,
      LargeTube: 2,
      Construction: 2,
      SteelPlate: 2,
    },
  },
  TimerBlockReskinSmall: {
    Cost: {
      Computer: 1,
      Construction: 3,
      InteriorPlate: 2,
    },
  },
  EmotionControllerSmall: {
    Cost: {
      BulletproofGlass: 1,
      Display: 1,
      Computer: 5,
      Construction: 3,
      InteriorPlate: 1,
    },
  },
  SmallAirVentFull: {
    Cost: {
      Computer: 5,
      Motor: 2,
      Construction: 15,
      SteelPlate: 5,
    },
  },
  SmallBlockConveyorCapMedium: {
    Cost: {
      Construction: 10,
      InteriorPlate: 10,
    },
  },
  OffroadSmallSuspension2x2: {
    Cost: {
      Motor: 1,
      SmallTube: 2,
      Construction: 7,
      SteelPlate: 8,
    },
  },
  OffroadSmallSuspension2x2Mirrored: {
    Cost: {
      Motor: 1,
      SmallTube: 2,
      Construction: 7,
      SteelPlate: 8,
    },
  },
  OffroadSmallWheel2x2: {
    Cost: {
      LargeTube: 2,
      Construction: 10,
      SteelPlate: 5,
    },
  },
  SmallBlockConveyorCap: {
    Cost: {
      Construction: 2,
      InteriorPlate: 2,
    },
  },
  SpeederCockpit: {
    Cost: {
      Display: 4,
      Computer: 20,
      Motor: 2,
      Construction: 25,
      InteriorPlate: 30,
    },
  },
  SpeederCockpitCompact: {
    Cost: {
      Display: 4,
      Computer: 20,
      Motor: 2,
      Construction: 25,
      InteriorPlate: 30,
    },
  },
  SmallWheel2x2: {
    Cost: {
      LargeTube: 2,
      Construction: 10,
      SteelPlate: 5,
    },
  },
  SabiroidPlushie: {
    Cost: {
      SabiroidPlushie: 1,
    },
  },
  OffroadSmallRealWheel2x2: {
    Cost: {
      Motor: 1,
      SmallTube: 2,
      Construction: 7,
      SteelPlate: 8,
    },
  },
  OffroadSmallRealWheel2x2Mirrored: {
    Cost: {
      Motor: 1,
      SmallTube: 2,
      Construction: 7,
      SteelPlate: 8,
    },
  },
};
export const componentsData = {
  Construction: {
    IconPath: construction,
    Cost: {
      Iron: 2.66666675,
    },
    Weight: 8,
  },
  Girder: {
    IconPath: girder,
    Cost: {
      Iron: 2.0,
    },
    Weight: 6,
  },
  MetalGrid: {
    IconPath: metalGrid,
    Cost: {
      Iron: 4.0,
      Nickel: 1.66666663,
      Cobalt: 1.0,
    },
    Weight: 6,
  },
  InteriorPlate: {
    IconPath: interiorPlate,
    Cost: {
      Iron: 1.0,
    },
    Weight: 3,
  },
  SteelPlate: {
    IconPath: steelPlate,
    Cost: {
      Iron: 7.0,
    },
    Weight: 20,
  },
  SmallTube: {
    IconPath: smallTube,
    Cost: {
      Iron: 1.66666663,
    },
    Weight: 4,
  },
  LargeTube: {
    IconPath: largeTube,
    Cost: {
      Iron: 10.0,
    },
    Weight: 25,
  },
  Motor: {
    IconPath: motor,
    Cost: {
      Iron: 6.66666651,
      Nickel: 1.66666663,
    },
    Weight: 24,
  },
  Display: {
    IconPath: display,
    Cost: {
      Iron: 0.333333343,
      Silicon: 1.66666663,
    },
    Weight: 8,
  },
  BulletproofGlass: {
    IconPath: bulletproofGlass,
    Cost: {
      Silicon: 5.0,
    },
    Weight: 15,
  },
  Computer: {
    IconPath: computer,
    Cost: {
      Iron: 0.166666672,
      Silicon: 0.06666667,
    },
    Weight: 0.2,
  },
  Reactor: {
    IconPath: reactor,
    Cost: {
      Iron: 5.0,
      Stone: 6.66666651,
      Silver: 1.66666663,
    },
    Weight: 25,
  },
  Thrust: {
    IconPath: thrust,
    Cost: {
      Iron: 10.0,
      Cobalt: 3.33333325,
      Gold: 0.333333343,
      Platinum: 0.13333334,
    },
    Weight: 40,
  },
  GravityGenerator: {
    IconPath: gravityGenerator,
    Cost: {
      Silver: 1.66666663,
      Gold: 3.33333325,
      Cobalt: 73.3333359,
      Iron: 200.0,
    },
    Weight: 800,
  },
  Medical: {
    IconPath: medical,
    Cost: {
      Iron: 20.0,
      Nickel: 23.333334,
      Silver: 6.66666651,
    },
    Weight: 150,
  },
  RadioCommunication: {
    IconPath: radioCommunication,
    Cost: {
      Iron: 2.66666675,
      Silicon: 0.333333343,
    },
    Weight: 8,
  },
  Detector: {
    IconPath: detector,
    Cost: {
      Iron: 1.66666663,
      Nickel: 5.0,
    },
    Weight: 5,
  },
  Canvas: {
    IconPath: canvas,
    Cost: {
      Silicon: 11.666667,
      Iron: 0.6666667,
    },
    Weight: 15,
  },
  Explosives: {
    IconPath: explosives,
    Cost: {
      Silicon: 0.166666672,
      Magnesium: 0.6666667,
    },
    Weight: 2,
  },
  SolarCell: {
    IconPath: solarCell,
    Cost: {
      Nickel: 1.0,
      Silicon: 2.0,
    },
    Weight: 6,
  },
  PowerCell: {
    IconPath: powerCell,
    Cost: {
      Iron: 3.33333325,
      Silicon: 0.333333343,
      Nickel: 0.6666667,
    },
    Weight: 25,
  },
  Superconductor: {
    IconPath: superconductor,
    Cost: {
      Iron: 3.33333325,
      Gold: 0.6666667,
    },
    Weight: 15,
  },
  ZoneChip: {
    IconPath: zoneChip,
    Cost: {
      ZoneChip: 1,
    },
    Weight: 0.25,
  },
  AlliageGatling: {
    IconPath: mk1,
    Cost: {
      Iron: 0,
    },
    Weight: 20,
  },
  AlliageArmeMissiles: {
    IconPath: mk1,
    Cost: {
      Iron: 0,
    },
    Weight: 20,
  },
  AlliageArtillerie: {
    IconPath: mk1,
    Cost: {
      Iron: 0,
    },
    Weight: 20,
  },
  AlliageRailgun: {
    IconPath: mk1,
    Cost: {
      Iron: 0,
    },
    Weight: 20,
  },
  PuceOpale: {
    IconPath: mk1,
    Cost: {
      Bismuth: 500,
      Opale: 250,
      Stone: 2166.66666651,
      Silicon: 5,
      Nickel: 3,
    },
    Weight: 1,
  },
  ComposantBismuth: {
    IconPath: mk1,
    Cost: {
      Bismuth: 20,
      Stone: 15,
    },
    Weight: 8,
  },
  AlliageAssembleur: {
    IconPath: mk1,
    Cost: {
      Opale: 1,
      Iron: 7,
      Bismuth: 5,
      ComposantNPCT1: 1,
      Stone: 3.33333325,
      Nickel: 0.25,
      Silicon: 0.2,
      Cobalt: 0.1,
    },
    Weight: 20,
  },
  ComposantNPCMk1: {
    IconPath: mk1,
    Cost: {
      ComposantNPCMk1: 1,
    },
    Weight: 20,
  },
  AlliageConteneurCargo: {
    IconPath: mk1,
    Cost: {
      Opale: 1,
      Iron: 6.8,
      Bismuth: 5,
      Silicon: 0.2,
      Stone: 0.27,
      Nickel: 1.2,
      Cobalt: 0.4,
    },
    Weight: 20,
  },
  AlliageDetecteur: {
    IconPath: mk1,
    Cost: {
      Opale: 1,
      Iron: 6.3,
      Silicon: 0.2,
      Nickel: 2.6,
      Bismuth: 5,
      Stone: 3.33333325,
    },
    Weight: 20,
  },
  AlliageBatterie: {
    IconPath: mk1,
    Cost: {
      Opale: 1,
      Stone: 4,
      Nickel: 0.6,
      ComposantNPCT1: 1,
      Silicon: 0.37,
      Bismuth: 5,
      Iron: 6.8,
    },
    Weight: 20,
  },
  AlliageEolienne: {
    IconPath: mk1,
    Cost: {
      Opale: 1,
      Nickel: 0.6,
      Silicon: 0.2,
      Bismuth: 5,
      Iron: 6.8,
    },
    Weight: 20,
  },
  PuceOpaleMk1: {
    IconPath: mk1,
    Cost: {
      Opale: 2750,
      Silicon: 5005,
      Nickel: 5003,
      PuceVote: 2,
      PuceContreFacon: 1,
      Bismuth: 500,
      Stone: 2166.66666651,
    },
    Weight: 1,
  },
  AlliageCelluleSolaire: {
    IconPath: mk1,
    Cost: {
      Opale: 1,
      Stone: 7.666666651,
      Nickel: 3.2,
      Iron: 4.5,
      Bismuth: 5,
      Silicon: 8.6,
    },
    Weight: 20,
  },
  AlliageGyroscope: {
    IconPath: mk1,
    Cost: {
      Opale: 0.5,
      Iron: 9,
      Bismuth: 0.9,
      ComposantNPCT1: 1,
      Nickel: 0.36,
      Cobalt: 0.2,
      Silicon: 0.2,
    },
    Weight: 20,
  },
  AlliageReservoirHydrogene: {
    IconPath: mk1,
    Cost: {
      Iron: 22.25,
      Opale: 0.5,
      Bismuth: 0.2,
      ComposantNPCT1: 1,
      Silicon: 0.2,
      Stone: 2.666666651,
    },
    Weight: 20,
  },
  AlliageSautSpatiale: {
    IconPath: mk1,
    Cost: {
      Opale: 1,
      Iron: 256.3,
      ComposantNPCT1: 1,
      Nickel: 15.8,
      Cobalt: 91,
      Silicon: 3.6,
      Bismuth: 5,
      Stone: 6.666666651,
    },
    Weight: 20,
  },
  AlliageRaffinerie: {
    IconPath: mk1,
    Cost: {
      Opale: 0.5,
      Iron: 5,
      Stone: 9.333333325,
      Bismuth: 1.2,
      Nickel: 0.2,
      Silicon: 0.4,
    },
    Weight: 20,
  },
  AlliageModuleVitesse: {
    IconPath: mk1,
    Cost: {
      Opale: 2.333333333,
      Iron: 8.8,
      Nickel: 0.1,
      Silicon: 0.1,
      Bismuth: 5,
      Stone: 2.6666666651,
    },
    Weight: 20,
  },
  AlliageModuleRendement: {
    IconPath: mk1,
    Cost: {
      Opale: 1.333333333,
      Stone: 1.6666666651,
      Bismuth: 4.333333333,
      Gold: 0.4,
      Iron: 28.55,
      Nickel: 0.2,
    },
    Weight: 20,
  },
  AlliageModuleEnergetique: {
    IconPath: mk1,
    Cost: {
      Opale: 1,
      Silicon: 0.2,
      Bismuth: 5,
      Iron: 28.55,
      Stone: 0.3,
      Nickel: 0.4,
    },
    Weight: 20,
  },
  AlliagePropulseurIonnique: {
    IconPath: mk1,
    Cost: {
      Iron: 45.25,
      Cobalt: 26,
      Gold: 1.3,
      Opale: 2.56,
      ComposantNPCT1: 1,
      Stone: 11.666666651,
      Platinum: 0.5,
      Bismuth: 6.4,
    },
    Weight: 20,
  },
  AlliagePropulseurAtmospherique: {
    IconPath: mk1,
    Cost: {
      Opale: 0.5,
      Iron: 23,
      ComposantNPCT1: 1,
      Nickel: 10,
      Cobalt: 0.3,
      Bismuth: 24.7,
    },
    Weight: 20,
  },
  AlliagePropulseurHydrogene: {
    IconPath: mk1,
    Cost: {
      Iron: 11.75,
      Cobalt: 2,
      ComposantNPCT1: 1,
      Nickel: 3.2,
      Opale: 0.5,
      Bismuth: 5,
    },
    Weight: 20,
  },
  PuceBronze: {
    IconPath: mk1,
    Cost: {
      PuceBronze: 1,
    },
    Weight: 40,
  },
  PuceArgent: {
    IconPath: mk1,
    Cost: {
      PuceArgent: 1,
    },
    Weight: 40,
  },
  PuceOr: {
    IconPath: mk1,
    Cost: {
      PuceOr: 1,
    },
    Weight: 40,
  },
  EngineerPlushie: {
    IconPath: Plushie,
    Cost: {
      EngineerPlushie: 1,
    },
    Weight: 1,
  },
  SabiroidPlushie: {
    IconPath: SabiroidPlushie,
    Cost: {
      SabiroidPlushie: 1,
    },
    Weight: 1,
  },
};
/* const ingotsData = {
    Stone: {
      IconPath: "Textures\\GUI\\Icons\\ingot\\ingot_class.dds",
      Cost: {
        Stone: 1.0,
      },
    },
    Iron: {
      IconPath: "Textures\\GUI\\Icons\\ingot\\iron_ingot.dds",
      Cost: {
        "Iron Ore": 0.7,
      },
    },
    Nickel: {
      IconPath: "Textures\\GUI\\Icons\\ingot\\nickel_ingot.dds",
      Cost: {
        "Nickel Ore": 0.4,
      },
    },
    Cobalt: {
      IconPath: "Textures\\GUI\\Icons\\ingot\\cobalt_ingot.dds",
      Cost: {
        "Cobalt Ore": 0.3,
      },
    },
    Magnesium: {
      IconPath: "Textures\\GUI\\Icons\\ingot\\magnesium_ingot.dds",
      Cost: {
        "Magnesium Ore": 0.007,
      },
    },
    Silicon: {
      IconPath: "Textures\\GUI\\Icons\\ingot\\silicon_ingot.dds",
      Cost: {
        "Silicon Ore": 0.7,
      },
    },
    Silver: {
      IconPath: "Textures\\GUI\\Icons\\ingot\\silver_ingot.dds",
      Cost: {
        "Silver Ore": 0.1,
      },
    },
    Gold: {
      IconPath: "Textures\\GUI\\Icons\\ingot\\gold_ingot.dds",
      Cost: {
        "Gold Ore": 0.01,
      },
    },
    Platinum: {
      IconPath: "Textures\\GUI\\Icons\\ingot\\platinum_ingot.dds",
      Cost: {
        "Platinum Ore": 0.005,
      },
    },
    Uranium: {
      IconPath: "Textures\\GUI\\Icons\\ingot\\uranium_ingot.dds",
      Cost: {
        "Uranium Ore": 0.01,
      },
    },
    Scrap: {
      IconPath: "Textures\\GUI\\Icons\\component\\ScrapMetalComponent.dds",
      Cost: {
        "Iron Ore": 0.8,
      },
    },
    Ice: {
      IconPath: "Textures\\GUI\\Icons\\ingot\\gravel_ingot.dds",
      Cost: {
        "Stone Ore": 0.9,
      },
    },
  }; */
