const fr = {
  app: {
    Privacy: "Politique de confidentialité",
    cookie:
      "Nous utilisons des cookies pour améliorer l’expérience utilisateur et analyser le trafic sur notre site web. En cliquant sur “Accepter“, vous consentez à l’utilisation de cookies sur notre site web, conformément à la description fournie dans notre politique relative aux cookies.",
    block: "Blocs",
    Size: "Taille",
    shipMaxWeight: "Votre vaisseau peut peser jusqu'à",
    uploadLabel: "Téléchargez ou déposez un fichier ici",
    Edit: "Modifier",
    Remove: "Supprimer",
    step1: "Renommez le fichier .sbc, en changeant l'extension en .zip",
    step2: "Décompressez le fichier .zip avec un utilitaire d'archives.",
    step3: "Vous obtiendrez un nouveau fichier sans extension.",
    step4: "Supprimez l'archive .zip",
    step5: "Renommez le nouveau fichier sans extension obtenue, en ajoutant l'extension .sbc",
    step6: "Enfin, vous pouvez uploader votre nouveau fichier.",
    clickHere: "Cliquez ici pour télécharger votre fichier",
    compressedFile:
      "Votre fichier est probablement compressé. Suivez les instructions ci-dessous pour le réparer. Puis, essayez de le télécharger à nouveau.",
    unknownBlocks: "blocs inconnus ont été trouvés dans votre plan.",
    Calculating: "En traitement...",
    velocity1: "Le calcul de la vitesse terminale n'est pas possible avec ces paramètres.",
    velocity2: "Veuillez essayer de modifier l'un d'entre eux",
    invalid:
      "Vous avez saisie des paramètres non valides. Un au moins un est égal à zéro ou négatif.",
    chute1: "Vous aurez besoin de",
    chute2: "parachutes pour descendre à",
    title: "Fini les vaisseaux de merde",
    subTitleA: "Analyseur de blueprint",
    parserA1: "Pour créer un blueprint, ciblez la grille et appuyez sur",
    parserA2: "Ctrl-B.",
    parserA3: "La création de plans fonctionne aussi bien en mode survie qu'en mode créatif.",
    bpA1: "Nom :",
    bpA2: "Propriétaire :",
    bpA3: "Taille :",
    bpA4: "Enlever",
    bpA5: "Composants",
    bpA6: "Lingots",
    bpA7: "Aucun plan n'a encore été transféré",
    bpA8: "Les plans sont enregistrés localement dans votre dossier",
    bpA9: "%appdata%/SpaceEngineers/Blueprints",
    bpA10: "par défaut",
    bpA11: "Ou vous pouvez les télécharger à partir de steam",
    bpA12:
      "Valve vous permet également de télécharger vos fichiers de sauvegarde du Steam cloud via un navigateur Web. Vous pouvez les télécharger en cliquant ci-dessous et en vous connectant avec votre compte Steam. Les blueprints sont les fichiers SBC.",
    subTitleB: "Calculateur de propulsion",
    gridSize: "Taille de la grille :",
    small: "Petite",
    large: "Grande",
    _large: "Grand",
    gridMass: "Masse du vaisseau (Kg) :",
    gravity: "Gravité",
    fillCargo: "Remplir mes conteneurs de cargaison (avec n'importe quel minerai)",
    cargoMultiplier: "Multiplicateur d'espace de cargaison",
    cargoMultiplier2: "Multiplicateur de cargaison",
    realistic: "Réaliste",
    cargoCount: "Nombre de conteneurs",
    smallCargo: "Petit conteneur",
    mediumCargo: "Moyen conteneur",
    largeCargo: "Grand conteneur",
    shipThrusters: "Minimum requis",
    shipNeeds: "Votre vaisseau a besoin de",
    shipNeedsB:
      "de poussée pour lutter contre la gravité. Pour maintenir ce poids, vous aurez besoin d'au moins une des catégories d'éléments suivants :",
    shipNeedsC: "avec les cargos remplis",
    shipNeedsD: "à vide",
    Thruster: "Propulseur",
    Count: "Nombre",
    ThrusterEfficiencies: "Efficacité des propulseurs",
    Earthlike: "Terre",
    Alien: "Alien",
    Mars: "Mars",
    Pertam: "Pertam",
    Triton: "Triton",
    Moon: "Lune",
    Europa: "Europa",
    Titan: "Titan",
    SelectPlanet: "Sélectionnez la planète ou la lune:",
    LargeIonCount: "Nombre Grand Ions:",
    NumberOfThrusters: "Nombre de propulseurs",
    LargeHydrogenCount: "Nombre Grand Hydro:",
    LargeAtmosphericCount: "Nombre Grand Atmo:",
    LargeFlatAtmosphericCount: "Nombre Grand Atmo plat:",
    SmallIonCount: "Nombre Petit Ions:",
    SmallHydrogenCount: "Nombre Petit Hydrogen:",
    SmallAtmosphericCount: "Nombre Petit Atmo:",
    SmallFlatAtmosphericCount: "Nombre Petit Atmo Plat:",
    AscentAngle: "L'angle d'ascension",
    ascentB:
      "est utilisé lorsque vous voulez utiliser les propulseurs arrière et inférieur ensemble. Le calculateur réduira la poussée à 70,7%.",
    subTitleC: "Calculateur de parachute",
    Reminder: "Rappel:",
    reminderText:
      "Emportez toujours des parachutes supplémentaires, au cas où le lieu de largage serait une montagne.",
    LargeGrid: "Grande grille",
    SmallGrid: "Petite grille",
    Hint: "Indice :",
    EarthIs: "La Terre c'est :",
    MarsIs: "Mars c'est :",
    AlienIs: "Alien c'est :",
    PertamIs: "Pertam c'est :",
    TritonIs: "Triton c'est :",
    Atmospheres: "Atmosphères (atm): ",
    DesiredVelocity: "Vitesse terminale souhaitée",
    ComputationTimeout: "Délai de calcul (sec):",
    ShowComputation: "Afficher le processus de calcul (calcul plus lent)",
    Calculate: "Calculer",
    subTitleD: "Calculateur de moteur de saut",
    MassInKg: "Mass en Kg",
    NumberOfJumpRive: "Nombre de moteurs de saut",
    JumpDriveCount: "Nombre de moteurs de saut:",
    MaximumJumpDistance: "Distance maximale de saut (Km) :",
    Graph: "Graphique :",
    subTitleE: "Portée des dégâts des propulseurs",
    BlockSize: "Taille du bloc",
    Dimensions: "Dimensions",
    Volume: "Volume",
    MaximumThrust: "Poussée maximale",
    Mass: "Masse",
    MaxPowerConsumption: "Consommation électrique maximale",
    MaxHydrogenConsumption: "Consommation maximale d'hydrogène",
    ThrustToMass: "Thrust to Mass",
    ThrustToSize: "Thrust to Size",
    ThrustToPower: "Thrust to Power",
    FlameLength: "Longueur de la flamme",
    Large: "Grande",
    LargeAtmosphericThruster: "Grand propulseur atmosphérique",
    LargeFlatAtmosphericThruster: "Grand propulseur atmosphérique plat",
    AtmosphericThruster: "Propulseur atmosphérique",
    FlatAtmosphericThruster: "Propulseur atmosphérique plat",
    LargeHydrogenThruster: "Grand propulseur à hydrogène",
    HydrogenThruster: "Propulseur à hydrogène ",
    LargeIonThruster: "Grand propulseur d'ions",
    IonThruster: "Propulseur d'ions",
    Small: "Petit",
    AlienCamouflage: "Camouflage alien",
    Info: "Info",
    ClickToClipboard: "Cliquez pour copier dans le presse-papiers.",
    Clouds: "Nuages",
    Sky: "Ciel",
    Landscapes: "Paysages",
    Trees: "Arbres",
    Bushes: "Buissons",
  },
};

export default fr;
