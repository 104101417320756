import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import headerBP from "../../assets/images/headerBP2.webp";
import cosmonautscta from "../../assets/images/cosmonauts-cta.webp";
import MKBox from "../../components/MKBox";

function Privacy() {
  const { t } = useTranslation("app");
  return (
    <>
      <span id="header">
        <DefaultNavbar
          routes={[]}
          action={{
            type: "external",
            route: "https://steamcommunity.com/profiles/76561198158138204",
            label: "@OXOMUS",
            color: "info",
          }}
          sticky
        />
      </span>
      <MKBox
        id="video-inner"
        minHeight="65vh"
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          backgroundImage: `url(${headerBP})`,
        }}
      >
        <span className="offuscator">
          <img src={cosmonautscta} alt="cosmonautscta" id="image-se" />
          <Container>
            <Grid id="override" container item xs={12} lg={12} justifyContent="center" mx="auto">
              <h1 className="title">
                Privacy Policy
                <br /> for Se Calculator
              </h1>
            </Grid>
          </Container>
        </span>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <div>
            <Grid
              id="main-container"
              container
              item
              xs={12}
              lg={12}
              spacing={2}
              justifyContent="center"
              mx="auto"
            >
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    At Se Calculator, accessible from www.se-calculator.com, one of our main
                    priorities is the privacy of our visitors. This Privacy Policy document contains
                    types of information that is collected and recorded by Se Calculator and how we
                    use it.
                  </p>

                  <p>
                    If you have additional questions or require more information about our Privacy
                    Policy, do not hesitate to contact us.
                  </p>

                  <p>
                    This Privacy Policy applies only to our online activities and is valid for
                    visitors to our website with regards to the information that they shared and/or
                    collect in Se Calculator. This policy is not applicable to any information
                    collected offline or via channels other than this website.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Consent
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    By using our website, you hereby consent to our Privacy Policy and agree to its
                    terms.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Information we collect
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    The personal information that you are asked to provide, and the reasons why you
                    are asked to provide it, will be made clear to you at the point we ask you to
                    provide your personal information.
                  </p>
                  <p>
                    If you contact us directly, we may receive additional information about you such
                    as your name, email address, phone number, the contents of the message and/or
                    attachments you may send us, and any other information you may choose to
                    provide.
                  </p>
                  <p>
                    When you register for an Account, we may ask for your contact information,
                    including items such as name, company name, address, email address, and
                    telephone number.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    How we use your information
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>We use the information we collect in various ways, including to:</p>
                  <ul>
                    <li>Provide, operate, and maintain our website</li>
                    <li>Improve, personalize, and expand our website</li>
                    <li>Understand and analyze how you use our website</li>
                    <li>Develop new products, services, features, and functionality</li>
                    <li>
                      Communicate with you, either directly or through one of our partners,
                      including for customer service, to provide you with updates and other
                      information relating to the website, and for marketing and promotional
                      purposes
                    </li>
                    <li>Send you emails</li>
                    <li>Find and prevent fraud</li>
                  </ul>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Log Files
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    Se Calculator follows a standard procedure of using log files. These files log
                    visitors when they visit websites. All hosting companies do this and a part of
                    hosting services analytics. The information collected by log files include
                    internet protocol (IP) addresses, browser type, Internet Service Provider (ISP),
                    date and time stamp, referring/exit pages, and possibly the number of clicks.
                    These are not linked to any information that is personally identifiable. The
                    purpose of the information is for analyzing trends, administering the site,
                    tracking users movement on the website, and gathering demographic information.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Cookies and Web Beacons
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    Like any other website, Se Calculator uses cookies. These cookies are used to
                    store information including visitors preferences, and the pages on the website
                    that the visitor accessed or visited. The information is used to optimize the
                    users experience by customizing our web page content based on visitors browser
                    type and/or other information.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Google DoubleClick DART Cookie
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    Google is one of a third-party vendor on our site. It also uses cookies, known
                    as DART cookies, to serve ads to our site visitors based upon their visit to
                    www.website.com and other sites on the internet. However, visitors may choose to
                    decline the use of DART cookies by visiting the Google ad and content network
                    Privacy Policy at the following URL –{" "}
                    <a href="https://policies.google.com/technologies/ads">
                      https://policies.google.com/technologies/ads
                    </a>
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Advertising Partners Privacy Policies
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    You may consult this list to find the Privacy Policy for each of the advertising
                    partners of Se Calculator.
                  </p>

                  <p>
                    Third-party ad servers or ad networks uses technologies like cookies,
                    JavaScript, or Web Beacons that are used in their respective advertisements and
                    links that appear on Se Calculator, which are sent directly to users browser.
                    They automatically receive your IP address when this occurs. These technologies
                    are used to measure the effectiveness of their advertising campaigns and/or to
                    personalize the advertising content that you see on websites that you visit.
                  </p>

                  <p>
                    Note that Se Calculator has no access to or control over these cookies that are
                    used by third-party advertisers.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Third Party Privacy Policies
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    Se Calculator&apos;s Privacy Policy does not apply to other advertisers or
                    websites. Thus, are advising you to consult the respective Privacy Policies of
                    these third-party ad servers for more detailed information. It may include their
                    practices and instructions about how to opt-out of certain options.{" "}
                  </p>

                  <p>
                    You can choose to disable cookies through your individual browser options. To
                    know more detailed information about cookie management with specific web
                    browsers, it can be found at the browsers respective websites.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    CCPA Privacy Rights (Do Not Sell My Personal Information)
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                  <p>
                    Request that a business that collects a consumer&apos;s personal data disclose
                    the categories and specific pieces of personal data that a business has
                    collected about consumers.
                  </p>
                  <p>
                    Request that a business delete any personal data about the consumer that a
                    business has collected.
                  </p>
                  <p>
                    Request that a business that sells a consumer&apos;s personal data, not sell the
                    consumer&apos;s personal data.
                  </p>
                  <p>
                    If you make a request, we have one month to respond to you. If you would like to
                    exercise any of these rights, please contact us.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    GDPR Data Protection Rights
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    We would like to make sure you are fully aware of all of your data protection
                    rights. Every user is entitled to the following:
                  </p>
                  <p>
                    The right to access – You have the right to request copies of your personal
                    data. We may charge you a small fee for this service.
                  </p>
                  <p>
                    The right to rectification – You have the right to request that we correct any
                    information you believe is inaccurate. You also have the right to request that
                    we complete the information you believe is incomplete.
                  </p>
                  <p>
                    The right to erasure – You have the right to request that we erase your personal
                    data, under certain conditions.
                  </p>
                  <p>
                    The right to restrict processing – You have the right to request that we
                    restrict the processing of your personal data, under certain conditions.
                  </p>
                  <p>
                    The right to object to processing – You have the right to object to our
                    processing of your personal data, under certain conditions.
                  </p>
                  <p>
                    The right to data portability – You have the right to request that we transfer
                    the data that we have collected to another organization, or directly to you,
                    under certain conditions.
                  </p>
                  <p>
                    If you make a request, we have one month to respond to you. If you would like to
                    exercise any of these rights, please contact us.
                  </p>
                </Grid>
              </Grid>
              <Grid container item xs={12} lg={12} spacing={2} justifyContent="center" mx="auto">
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <h5
                    style={{
                      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                      color: "#FFFFFF",
                      padding: "0.8em 0.8em 0.8em 1em",
                      marginTop: "3em",
                      borderRadius: "5px",
                    }}
                  >
                    Children&apos;s Information
                  </h5>
                </Grid>
                <Grid className="fix-padding-lef" item xs={12} lg={12}>
                  <p>
                    Another part of our priority is adding protection for children while using the
                    internet. We encourage parents and guardians to observe, participate in, and/or
                    monitor and guide their online activity.
                  </p>

                  <p>
                    Se Calculator does not knowingly collect any Personal Identifiable Information
                    from children under the age of 13. If you think that your child provided this
                    kind of information on our website, we strongly encourage you to contact us
                    immediately and we will do our best efforts to promptly remove such information
                    from our records.
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Container className="footer-container">
          <MKBox
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            Copyright © 2022 Oxomus. Space Engineers and associated Space Engineers images are
            copyright of KEEN SOFTWARE HOUSE. Se-calculator.com is not affiliated with Space
            Engineers and KEEN SOFTWARE HOUSE.
          </MKBox>
        </Container>
        <Container className="footer-link-wrapper">
          <MKBox
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="center"
            alignItems="center"
          >
            <NavLink className="footer-link" to="/privacy">
              {t("privacy policy")}
            </NavLink>
            <NavLink className="footer-link" to="/conditions">
              {t("terms & conditions")}
            </NavLink>
          </MKBox>
        </Container>
      </Card>
    </>
  );
}

export default Privacy;
